import { useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import {
  Flex,
  Text,
  Box,
  CircularProgress,
  Badge,
  Container,
  Input,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  IconButton,
  Heading,
} from "@chakra-ui/react";
import { http } from "../../services/http";
import { IRooms } from "../../types/users/interfaces";
import { format, isValid } from "date-fns";
import { useAuth } from "../../contexts/authContext";
import { ArrowBackIcon } from "@chakra-ui/icons";

export function ViewRoom() {
  const location = useLocation();
  const { salaId } = location.state as { salaId: number };
  const [room, setRoom] = useState<IRooms | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { token } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (salaId) {
      http
        .get(`/salas/${salaId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setRoom(response.data.conteudo);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Erro ao buscar detalhes da sala:", error);
          setIsLoading(false);
        });
    }
  }, [salaId, token]);

  if (isLoading) {
    return (
      <Flex flex="1" alignItems="center" justifyContent="center">
        <CircularProgress color="#16A34A" isIndeterminate />
      </Flex>
    );
  }

  if (!room) {
    return (
      <Flex flex="1" alignItems="center" justifyContent="center">
        <Text>Sala não encontrada</Text>
      </Flex>
    );
  }

  const formatNumber = (number: number): string => {
    return number.toString().padStart(4, "0");
  };

  const formatDate = (dateString: string | null) => {
    if (dateString) {
      const date = new Date(dateString);
      return isValid(date) ? format(date, "dd/MM/yyyy") : "Data inválida";
    }
    return "Data não disponível";
  };

  const getStatusColor = (status: string | undefined): string => {
    switch (status) {
      case "Aberta":
        return "green";
      case "Em Andamento":
        return "yellow";
      case "Finalizada":
        return "red";
      default:
        return "gray";
    }
  };

  const getStatusText = (status: string) => {
    if (status === "Aberta") {
      return "Vendas Abertas";
    }
    if (status === "Finalizada") {
      return "Finalizado";
    }
    return status;
  };

  return (
    <Container
      bg="white"
      borderRadius="md"
      boxShadow="xl"
      mx="auto"
      mt={10}
      p={6}
      mb="5%"
    >
      <Flex align="center" mb={4}>
        <IconButton
          bg="#38A169"
          color="white"
          aria-label="Voltar"
          icon={<ArrowBackIcon />}
          variant="ghost"
          onClick={() => navigate(-1)}
        />
        <Heading as="h2" size="lg" ml={4}>
          Detalhes da Sala
        </Heading>
      </Flex>

      <Box mb={4}>
        <Text mb={2}>Bolão:</Text>
        <Input value={formatNumber(room.salaId)} isReadOnly />
      </Box>
      <Box mb={4}>
        <Text mb={2}>Data de Início:</Text>
        <Input value={formatDate(room.dataInicio)} isReadOnly />
      </Box>
      <Box mb={4}>
        <Text mb={2}>Data de Fim:</Text>
        <Input value={formatDate(room.dataFim)} isReadOnly />
      </Box>
      <Box mb={4}>
        <Text mb={2}>Data de Fim das Vendas:</Text>
        <Input value={formatDate(room.dataFimVendas)} isReadOnly />
      </Box>
      <Box mb={4}>
        <Text mb={2}>Valor do Palpite:</Text>
        <Input
          value={`R$ ${room.valorPalpite.toLocaleString("pt-BR", {
            minimumFractionDigits: 2,
          })}`}
          isReadOnly
        />
      </Box>

      <Box mb={4}>
        <Text>
          Status da Sala:
          <Badge
            ml={2}
            colorScheme={getStatusColor(room.statusSala?.descricao)}
          >
            {getStatusText(room.statusSala?.descricao) || "Status não disponível"}
          </Badge>
        </Text>
      </Box>
      <Box mt={6}>
        <Text fontSize="lg" fontWeight="bold" mb={4}>
          Concursos
        </Text>
        {room.concursos && room.concursos.length > 0 ? (
          <Box overflowX="auto">
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th textAlign="center">Número do Concurso</Th>
                  <Th textAlign="center">Data do Concurso</Th>
                  <Th textAlign="center">Números</Th>
                </Tr>
              </Thead>
              <Tbody>
                {room.concursos.map((concurso) => (
                  <Tr key={concurso.concursoId}>
                    <Td textAlign="center">{concurso.numeroConcurso}</Td>
                    <Td textAlign="center">
                      {formatDate(concurso.diaConcurso)}
                    </Td>
                    <Td textAlign="center">Números indisponíveis</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        ) : (
          <Text>Não há concursos para esta sala.</Text>
        )}
      </Box>
    </Container>
  );
}
