export enum ETripStatus {
    ACTIVE = "active",
    PROGESS = "progress",
    INACTIVE = "inactive",
  }
  
  export enum EFullName {
    JOHN_DOE = "John Doe",
    JANE_SMITH = "Jane Smith",
    MICHAEL_JOHNSON = "Michael Johnson",
    EMMA_WILLIAMS = "Emma Williams",
    WILLIAM_BROWN = "William Brown",
    OLIVIA_JONES = "Olivia Jones",
    JAMES_DAVIS = "James Davis",
    SOPHIA_MILLER = "Sophia Miller",
    BENJAMIN_WILSON = "Benjamin Wilson",
    ALEXANDER_TAYLOR = "Alexander Taylor",
  }

export enum EFilter {
  DAY = "day",
  WEEK = "week",
  MONTH = "month",
}

export enum EPaymentMethods {
  PIX = "pix",
  CARD = "card",
  CASH = "cash",
  BANK_SLIP = "bank-slip",
}

export enum EPaymentMethodStatus {
  ACTIVE = "active",
  INACTIVE = "inactive",
}
