import {
  Box,
  Text,
  Button,
  Heading,
  HStack,
  Img,
  AspectRatio,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import trofeu from "../assets/trofeu.png";
import FloatingWhatsAppButton from "../components/floatingWhatsAppButton";
import CustomModalAlert from "../components/signModal";
import { useAuth } from "../contexts/authContext";
import { http } from "../services/http";
import { IRooms } from "../types/users";
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
} from "date-fns";
import valor from "../assets/valor.png";

interface Winner {
  salaId: string;
  premio: string;
  nomeUsuario: string;
  pontos: number;
  cidade: string;
  descricao: string;
  quantidadePalpites: number;
}

export const HomePage = () => {
  const [salas, setSalas] = useState<IRooms[]>([]);
  const [winners, setWinners] = useState<Winner[]>([]);
  const navigate = useNavigate();
  const { token } = useAuth();

  const { isAuthenticated } = useAuth();
  const [modalContent, setModalContent] = useState({
    message: "",
  });

  useEffect(() => {
    const fetchSalas = async () => {
      try {
        const response = await http.get("/salas/");
        const data = response.data;
        const salasAbertas = data.conteudo.filter(
          (sala: IRooms) => sala.statusSala.descricao === "Aberta" && sala.ativo
        );

        setSalas(salasAbertas);
      } catch (error) {
        console.error("Error fetching salas:", error);
      }
    };

    fetchSalas();
  }, []);

  useEffect(() => {
    const fetchWinners = async () => {
      try {
        const response = await http.get("/ganhadores/ultimos-ganhadores", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const data = response.data.conteudo;

        const formattedWinners = data.map((winner: any) => ({
          salaId: winner.salaId,
          quantidadePalpites: winner.quantidadePalpites !== null ? `${winner.quantidadePalpites} Palpites` : "",
          premio: `R$ ${winner.premio.valor.toFixed(2).replace(".", ",")}`,
          nomeUsuario: winner.nomeUsuario,
          pontos:
            winner.totalPontos !== null ? `${winner.totalPontos} Pontos` : "",
          cidade: winner.cidade,
          descricao: winner.premio.descricao,
        }));

        setWinners(formattedWinners);
      } catch (error) {
        console.error("Error fetching winners:", error);
      }
    };

    fetchWinners();
  }, []);

  const handleButtonClick = (
    salaId: number,
    status: string,
    valorPalpite: number
  ) => {
    if (isAuthenticated) {
      const state = { salaId, status, valorPalpite };

      if (status === "Aberta") {
        navigate(`/buyShares`, { state });
      } else if (status === "Em Andamento") {
        navigate(`/rankings`, { state });
      } else if (status === "Finalizado") {
        navigate(`/rankings`, { state });
      }
    } else {
      setModalContent({
        message: "Faça login para prosseguir!",
      });
    }
  };

  const formatNumber = (num: number): string => {
    return num.toString().padStart(4, "0");
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case "Aberta":
        return "#068A1A";
      case "Em Andamento":
        return "#ffc107";
      case "Finalizada":
        return "#DB0101";
      default:
        return "#006391";
    }
  };

  const getFormattedDate = (dataFimVendas: string) => {
    const dataFimVendasDate = new Date(dataFimVendas);
    const agora = new Date();
    const dias = differenceInDays(dataFimVendasDate, agora);
    const horas = differenceInHours(dataFimVendasDate, agora) % 24;
    const minutos = differenceInMinutes(dataFimVendasDate, agora) % 60;

    return (
      <Text>
        <Text as="span" color="#068A1A" fontWeight="bold">
          Começa em:
        </Text>{" "}
        <Text as="span" fontWeight="bold" color="black">
          <Text as="span">{dias}</Text>{" "}
          <Text as="span" color="#068A1A">
            dias
          </Text>{" "}
          <Text as="span">{horas}</Text>{" "}
          <Text as="span" color="#068A1A">
            horas
          </Text>{" "}
          <Text as="span">{minutos}</Text>{" "}
          <Text as="span" color="#068A1A">
            min
          </Text>
        </Text>
      </Text>
    );
  };

  const getStatusText = (status: string) => {
    if (status === "Aberta") {
      return "Vendas Abertas";
    }
    if (status === "Finalizada") {
      return "Finalizado";
    }
    return status;
  };

  const getDateColor = (dataInicio: string) => {
    return (
      <Text as="span" color="black">
        {getFormattedDate(dataInicio)}
      </Text>
    );
  };

  const getPremio = (premio: number) => {
    const formattedPremio = premio.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });

    const [currencySymbol, value] = formattedPremio.split(/\s+/);

    return (
      <Text fontSize="18px" fontWeight="bold">
        <Text as="span" color="#068A1A">
          {currencySymbol}
        </Text>{" "}
        <Text as="span" color="black">
          {value}
        </Text>{" "}
        <Text as="span" color="#006391">
          em Prêmios
        </Text>
      </Text>
    );
  };

  return (
    <Box
      mx="auto"
      maxWidth="1200px"
      width="100%"
      display="flex"
      flexDirection="column"
      alignItems="center"
      paddingX={{ base: "20px", md: "40px" }}
    >
      <Box textAlign="center" width="100%">
        <Heading fontSize="4xl" fontWeight="bold" color="white" mb={8}>
          Concursos
        </Heading>
        {salas.length > 0 ? (
          <Box display="flex" flexWrap="wrap" justifyContent="center">
            {salas.map((sala) => (
              <Box
                key={sala.salaId}
                borderStyle="solid"
                borderWidth="2px"
                borderColor="rgb(1, 108, 48)"
                borderRadius="10px"
                mb={4}
                mx={2}
                maxWidth={{
                  base: "370px",
                  sm: "380px",
                  md: "370px",
                  lg: "380px",
                }}
                minWidth={{
                  base: "370px",
                  sm: "380px",
                  md: "370px",
                  lg: "380px",
                }}
                textAlign="center"
                color="#000000"
                backgroundColor="#FFFFFF"
                position="relative"
              >
                <Box
                  position="absolute"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  padding="8px 16px"
                  borderRadius="12px"
                  zIndex={1}
                  backgroundImage={valor}
                  backgroundSize="cover"
                  backgroundPosition="center"
                  width="110px"
                  height="90px"
                  pb={3}
                >
                  <Text
                    fontSize="12px"
                    fontWeight="bold"
                    color="#004DB2"
                    transform="rotate(-25deg)"
                  >
                    {sala.valorPalpite.toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </Text>
                </Box>
                <Text
                  mb={2}
                  fontSize="2xl"
                  fontWeight="bold"
                  //bgColor={'rgb(1, 108, 48)'}
                  //color={'#fff'}
                >
                  Bolão - {formatNumber(sala.salaId)}
                </Text>
                <Box
                  position="relative"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  mb={2}
                >
                  <Text
                    fontSize="xs"
                    fontWeight="bold"
                    padding={1}
                    paddingX={3}
                    boxShadow=" grey 1px 1px 4px 2px"
                    color={"#fff"}
                    bgColor={getStatusColor(sala.statusSala.descricao)}
                  >
                    {getStatusText(sala.statusSala.descricao)}{" "}
                  </Text>
                </Box>
                <Text
                  position="relative"
                  fontSize="14px"
                  color="#016C30"
                  fontWeight="bold"
                  mb={2}
                >
                  {getDateColor(sala.dataFimVendas)}
                </Text>

                <Text
                  position="relative"
                  left={2}
                  fontSize="20px"
                  color="#000"
                  fontWeight="bold"
                  mb={2}
                >
                  {getPremio(sala.valorPremioTotal)}
                </Text>
                <Button
                  onClick={() =>
                    handleButtonClick(
                      sala.salaId,
                      sala.statusSala.descricao,
                      sala.valorPalpite
                    )
                  }
                  boxShadow=" grey 2px 2px 4px 2px"
                  h="25px"
                  fontSize="18px"
                  fontWeight="bold"
                  fontStyle="italic"
                  borderRadius="20px"
                  bg={
                    sala.statusSala.descricao === "Aberta"
                      ? "#068A1A"
                      : sala.statusSala.descricao === "Em Andamento"
                      ? "#ffc107"
                      : "#DB0101"
                  }
                  color="white"
                  mb={4}
                >
                  {sala.statusSala.descricao === "Aberta" ? (
                    <Text color="#fff">Compre já!</Text>
                  ) : sala.statusSala.descricao === "Em Andamento" ? (
                    "Acompanhar"
                  ) : (
                    "Conferir"
                  )}
                </Button>
              </Box>
            ))}
          </Box>
        ) : (
          <Box
            bg="white"
            borderRadius={5}
            mx="auto"
            width={{ base: "100%", md: "50%", lg: "40%" }}
          >
            <Text fontWeight="bold" p={4}>
              Nenhum bolão disponível no momento.
            </Text>
          </Box>
        )}

        <Box textAlign="center" width="100%" mt={8}>
          <Text fontSize="2xl" fontWeight="bold" color="white" mb={4}>
            Conheça o Bolão dos 10
          </Text>

          <AspectRatio maxW="560px" ratio={16 / 9} mx="auto">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/jFxOGfVMbRU?si=GCr0X_BHHQ3XzdLr&rel=0"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
          </AspectRatio>
        </Box>
      </Box>

      {winners.length > 0 && (
        <Box textAlign="center" width="100%">
          <Text
            fontSize={{ base: "30px", md: "33px", lg: "35px" }}
            bg="#006391"
            maxWidth={{ base: "340px", sm: "350px", md: "350px", lg: "340px" }}
            minWidth={{ base: "340px", sm: "350px", md: "350px", lg: "340px" }}
            borderRadius="10px"
            color="white"
            fontWeight="bold"
            mb={7}
            mt={7}
            borderWidth="2px"
            display="inline-block"
            px={4}
            py={2}
          >
            Ganhadores
          </Text>

          <HStack
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            mb={7}
          >
            <Text
              color="white"
              fontWeight="bold"
              fontSize={{ base: "xl", md: "2xl" }}
              textAlign="center"
              mb={2}
            >
              Confira os últimos ganhadores dos Prêmio Principal e Top Comprador{" "}
            </Text>
            <Img src={trofeu} maxW="70px" />
          </HStack>

          <Box display="flex" flexWrap="wrap" justifyContent="center">
            {winners.map((winner, idx) => (
              <Box
                key={idx}
                borderStyle="solid"
                borderWidth="2px"
                borderColor="rgb(1, 108, 48)"
                // h={winner.pontos   !== null ? "210px" : "200px"}
                h="200px"
                maxWidth={{
                  base: "365px",
                  sm: "380px",
                  md: "370px",
                  lg: "380px",
                }}
                minWidth={{
                  base: "365px",
                  sm: "380px",
                  md: "370px",
                  lg: "380px",
                }}
                mx={2}
                mb={6}
                textAlign="center"
                color="#000000"
                backgroundColor="#FFFFFF"
                borderRadius="10px"
                p={4}
                position="relative"
                boxShadow="0px 4px 6px rgba(0, 0, 0, 0.1)"
                overflow="hidden"
              >
                <Heading
                  fontSize="20px"
                  fontWeight="bold"
                  color="#fff"
                  textAlign="center"
                  maxHeight="50px"
                  overflow="hidden"
                  bgColor="#016C30"
                  position="absolute"
                  top="0"
                  left="0"
                  right="0"
                  pt={2}
                >
                  Bolão - {formatNumber(Number(winner.salaId))}
                </Heading>

                <Box pt="20px">
                  <Text
                    mt={2}
                    fontWeight="bold"
                    fontSize="19px"
                    color="#006391"
                    textAlign="center"
                  >
                    {winner.descricao}
                  </Text>

                  <Text
                    fontSize="18px"
                    fontWeight="bold"
                    color="rgb(1, 108, 48)"
                    textAlign="center"
                    overflow="hidden"
                  >
                    {winner.pontos}
                  </Text>
                  <Text
                    fontSize="18px"
                    fontWeight="bold"
                    color="rgb(1, 108, 48)"
                    textAlign="center"
                    overflow="hidden"
                  >
                    {winner.quantidadePalpites} 
                  </Text>
                  <Text
                    fontSize={{ base: "md", md: "md", lg: "19px" }}
                    fontWeight="bold"
                    color="#000000"
                    textAlign="center"
                    maxHeight="50px"
                    overflow="hidden"
                  >
                    {winner.nomeUsuario}
                  </Text>

                  <Text
                    fontSize={{ base: "md", md: "md", lg: "17px" }}
                    fontWeight="bold"
                    color="#000000"
                    textAlign="center"
                    maxHeight="50px"
                    overflow="hidden"
                  >
                    {winner.cidade}
                  </Text>
                  <Box
                    bg="rgb(0, 99, 145)"
                    color="#FFFFFF"
                    fontWeight="bold"
                    textAlign="center"
                    py={2}
                    position="absolute"
                    bottom="0"
                    left="0"
                    right="0"
                    width="100%"
                    zIndex="0"
                    fontSize={{ base: "sm", md: "md" }}
                  >
                    Prêmio: {winner.premio}
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      )}

      <CustomModalAlert
        isOpen={!!modalContent.message}
        onClose={() => setModalContent({ message: "" })}
        message={modalContent.message}
      />
      <FloatingWhatsAppButton />
    </Box>
  );
};

export default HomePage;
