import axios from "axios";

const http = axios.create({
  //URL TESTE http://dev.api.bolaodos10.com.br/bolao10/api
  //baseURL: "https://bolao10-ccf5288c3f0a.herokuapp.com/bolao10/api",

  //URL ATUAL TESTE
  baseURL: "https://dev.api.bolaodos10.com.br/bolao10/api",

  
  //URL ANTIGA PRODUÇÃO
  // baseURL: "https://bolao-10-6ebdd6816658.herokuapp.com/bolao10/api",

  //URL AWS CERTA PRODUÇÃO
  //baseURL: "https://api.bolaodos10.com.br/bolao10/api",

  headers: {
    "Content-type": "application/json",
  },
});
export { http };
