import {
  CircularProgress,
  Container,
  Flex,
  HStack,
  IconButton,
  Text,
  Box,
  Button,
  Stack,
  Input,
  Select,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";
import { RiPencilLine } from "react-icons/ri";
import { useState, useEffect } from "react";
import { Table } from "../../core/components/table";
import { IColumn } from "../../types/componentes/table";
import { useNavigate } from "react-router-dom";
import { http } from "../../services/http";
import { ArrowLeftIcon, ArrowRightIcon } from "@chakra-ui/icons";
import { IoEyeOutline, IoTrashOutline } from "react-icons/io5";
import { useAuth } from "../../contexts/authContext";

export function RulesDashboard() {
  const [rules, setRules] = useState<
    { regraId: number; descricao: string; legenda: string }[]
  >([]);
  const [filteredRules, setFilteredRules] = useState<
    { regraId: number; descricao: string; legenda: string }[]
  >([]);
  const [filterType, setFilterType] = useState<string>("");
  const [filterValue, setFilterValue] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [expandedRuleId, setExpandedRuleId] = useState<number | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const itemsPerPage = 4;
  const descriptionLimit = 30;
  const navigate = useNavigate();
  const { token } = useAuth();
  const [ruleToDelete, setRuleToDelete] = useState<number | null>(null);

  useEffect(() => {
    http
      .get("/regras/", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setRules(response.data.conteudo);
        setFilteredRules(response.data.conteudo);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Erro ao buscar regras:", error);
        setIsLoading(false);
      });
  }, [token]);

  useEffect(() => {
    if (filterType && filterValue) {
      const filteredData = rules.filter((rule) => {
        switch (filterType) {
          case "titulo":
            return rule.legenda
              .toLowerCase()
              .includes(filterValue.toLowerCase());
          case "descricao":
            return rule.descricao
              .toLowerCase()
              .includes(filterValue.toLowerCase());
          default:
            return true;
        }
      });
      setFilteredRules(filteredData);
    } else {
      setFilteredRules(rules);
    }
  }, [filterType, filterValue, rules]);

  function handleEditClick(regraId: number) {
    const selectedRule = rules.find((rule) => rule.regraId === regraId);
    if (selectedRule) {
      navigate("/editRuleScreen", { state: selectedRule });
    }
  }

  function handleViewClick(regraId: number) {
    navigate("/viewRuleScreen", { state: { regraId } });
  }

  function toggleDescription(regraId: number) {
    setExpandedRuleId(expandedRuleId === regraId ? null : regraId);
  }

  const handleDelete = () => {
    if (ruleToDelete === null) return;

    http
      .delete(`/regras/${ruleToDelete}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        setRules((prevRules) =>
          prevRules.filter((rule) => rule.regraId !== ruleToDelete)
        );
        setFilteredRules((prevFilteredRules) =>
          prevFilteredRules.filter((rule) => rule.regraId !== ruleToDelete)
        );
        setRuleToDelete(null);
        onClose();
      })
      .catch((error) => {
        console.error("Erro ao excluir a regra:", error);
        setRuleToDelete(null);
        onClose();
      });
  };

  function handleDeleteClick(regraId: number) {
    setRuleToDelete(regraId);
    onOpen();
  }

  const paginatedRules = filteredRules.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const columns: IColumn<{
    regraId: number;
    descricao: string;
    legenda: string;
  }>[] = [
    {
      label: <Text fontWeight="bold">Título</Text>,
      render: (rule) => <Text color="black">{rule.legenda}</Text>,
      width: 50,
    },
    {
      label: <Text fontWeight="bold">Descrição</Text>,
      render: (rule) => (
        <Text color="black">
          {rule.descricao.length > descriptionLimit
            ? `${rule.descricao.substring(0, descriptionLimit)}...`
            : rule.descricao}
        </Text>
      ),
      width: 50,
    },
    {
      label: <Text fontWeight="bold">Ações</Text>,
      render: (rule) => (
        <HStack>
          <IconButton
            aria-label="update-ib"
            colorScheme="#16A34A"
            bgColor="#16A34A"
            color="white"
            onClick={() => handleEditClick(rule.regraId)}
          >
            <RiPencilLine size={20} />
          </IconButton>
          <IconButton
            aria-label="view-ib"
            colorScheme="yellow"
            bgColor="#ceb513"
            color="white"
            onClick={() => handleViewClick(rule.regraId)}
          >
            <IoEyeOutline size={20} />
          </IconButton>
          <IconButton
            aria-label="delete-ib"
            colorScheme="#E53E3E"
            bgColor="#E53E3E"
            color="white"
            onClick={() => handleDeleteClick(rule.regraId)}
          >
            <IoTrashOutline size={20} />
          </IconButton>
        </HStack>
      ),
      width: 20,
    },
  ];

  function openModal(): Promise<void> {
    return new Promise<void>((resolve) => {
      navigate("/addRuleScreen");
      resolve();
    });
  }

  return isLoading ? (
    <Flex flex="1" alignItems="center" justifyContent="center">
      <CircularProgress color="#16A34A" isIndeterminate />
    </Flex>
  ) : (
    <Flex flex="1" flexDirection="row" paddingY={5}>
      <Container
        borderRadius={10}
        bg={"gray.200"}
        maxWidth={["100vw", "60vw"]}
        display="flex"
        flexDirection="column"
        gap={10}
        mt={6}
      >
        <Box>
          <Text
            mb={5}
            marginTop={5}
            fontSize={["2xl", "3xl"]}
            textAlign="center"
            fontWeight="bold"
          >
            Regras
          </Text>
          <Text
            fontSize="20px"
            color="black"
            fontWeight="bold"
            textAlign="center"
            mb={3}
          >
            Buscar por:
          </Text>
          <Stack direction="row" spacing={2} justify="center">
            <Select
              border="1px solid #979489b3"
              placeholder="Selecione o Filtro"
              bg="white"
              value={filterType}
              onChange={(e) => {
                setFilterType(e.target.value);
                setFilterValue("");
              }}
              maxW="200px"
            >
              <option value="titulo">Título</option>
              <option value="descricao">Descrição</option>
            </Select>
            {filterType && (
              <Input
                border="1px solid #979489b3"
                placeholder="Digite o valor"
                bg="white"
                value={filterValue}
                onChange={(e) => setFilterValue(e.target.value)}
                maxW="200px"
              />
            )}
          </Stack>
        </Box>
        <Flex flexDirection="column" width="full" alignItems="flex-end">
          <Button
            mb={5}
            backgroundColor="#16A34A"
            colorScheme="#16A34A"
            onClick={openModal}
          >
            <Text color="#fff">Adicionar Regras</Text>
          </Button>
          <Table<{ regraId: number; descricao: string; legenda: string }>
            columns={columns}
            rows={filteredRules}
          />
        </Flex>
      </Container>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirmar Exclusão</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Você tem certeza de que deseja excluir esta regra?</Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="red" onClick={handleDelete}>
              Excluir
            </Button>
            <Button colorScheme="gray" onClick={onClose} ml={3}>
              Cancelar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
}
