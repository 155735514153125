import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { ISortie } from "../types/sortie/interfaces";
import { SortieRepository } from "../domain/repositories/sortie";

interface IContextValue {
  sorties: ISortie[];
  findAll(): Promise<void>;
  filter: (search: string) => Promise<void>;
  create: (modalValues: ISortie) => Promise<void>;
  update: (item: ISortie) => Promise<void>;
  remove: (salaId: number) => Promise<void>;
  isLoading: boolean;
}

export const SortieContext = createContext<IContextValue>({
  sorties: [],
  isLoading: false,
  findAll: async () => {},
  create: async () => {},
  filter: async () => {},
  update: async () => {},
  remove: async () => {},
});

interface IProps {
  children: ReactNode;
}

export function SortieProvider({ children }: IProps) {
  const [sorties, setSorties] = useState<ISortie[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const findAll = async () => {
    // setIsLoading(true);
    // try {
    //   const result = await SortieRepository.findAll();
    //   setSorties(result);
    // } catch (error) {
    //   console.error("Erro ao buscar resultados:", error);
    // } finally {
    //   setIsLoading(false);
    // }
  };

  async function create(modalValues: ISortie) {
    // try {
    //   setIsLoading(true);
    //   const createdSorties = await SortieRepository.create(modalValues);
    //   setSorties([...sorties, createdSorties]);
    //   setIsLoading(false);
    //   findAll();
    // } catch (error) {
    //   console.error("Erro ao adicionar concurso:", error);
    //   setIsLoading(false);
    // }
  }

  async function update(payload: ISortie) {
    // try {
    //   setIsLoading(true);
    //   await SortieRepository.update(payload.resultadoId, payload);
    //   setSorties((prevState) =>
    //     prevState.map((room) =>
    //       room.resultadoId === payload.resultadoId ? { ...room, ...payload } : room
    //     )
    //   );
    //   setIsLoading(false);
    //   findAll();
    // } catch (error) {
    //   console.error("Erro ao atualizar sala:", error);
    //   setIsLoading(false);
    // }
  }

  async function filter(search: string) {
    // if (!search.trim()) {
    //   findAll();
    //   return;
    // }
    // try {
    //   setIsLoading(true);
    //   const filteredResults = sorties.filter(
    //     (sortie) => sortie.resultadoId.toString() === search.trim()
    //   );
    //   setSorties(filteredResults);
    //   setIsLoading(false);
    // } catch (error) {
    //   console.error("Erro ao filtrar resultados:", error);
    //   setIsLoading(false);
    // }
  }

  async function remove(resultadoId: number) {
    // try {
    //   setIsLoading(true);
    //   await SortieRepository.delete(resultadoId);
    //   setSorties((prevSorties) =>
    //     prevSorties.filter((sortie) => sortie.resultadoId !== resultadoId)
    //   );
    //   setIsLoading(false);
    //   findAll();
    // } catch (error) {
    //   console.error("Erro ao excluir sala:", error);
    //   setIsLoading(false);
    // }
  }

  useEffect(() => {
    findAll();
  }, []);

  return (
    <SortieContext.Provider
      value={{
        sorties,
        isLoading,
        findAll,
        create,
        filter,
        update,
        remove,
      }}
    >
      {children}
    </SortieContext.Provider>
  );
}

export function useSortie() {
  return useContext(SortieContext);
}
