import { IconType } from "react-icons";
import { RiCoupon2Line, RiHome3Line } from "react-icons/ri";
import { EPaths } from "./paths";
import { GoGraph } from "react-icons/go";
import { LiaUserAstronautSolid } from "react-icons/lia";
import { LiaWarehouseSolid } from "react-icons/lia";
import { TbClover } from "react-icons/tb";
import { CgProfile } from "react-icons/cg";
import { RiAwardLine } from "react-icons/ri";
import { FaWhatsapp } from "react-icons/fa";
import { MdOutlineMonetizationOn, MdOutlineRule } from "react-icons/md";
import { IoIosAddCircleOutline } from "react-icons/io";
import { IoEyeOutline } from "react-icons/io5";
import { GiPodiumWinner } from "react-icons/gi";
import { FaRankingStar } from "react-icons/fa6";
import { BsAward } from "react-icons/bs";

interface INav {
  label: string;
  path?: string;
  icon: IconType;
  navs?: INav[];
}

export const AdminNavMock: INav[] = [
  {
    label: "Página Inicial",
    path: EPaths.HOME,
    icon: RiHome3Line,
  },
  {
    label: "Concursos",
    path: EPaths.ROOMS,
    icon: BsAward,
  },
  {
    label: "Dashboard",
    icon: GoGraph,
    navs: [
      {
        label: "Cadastro de Clientes",
        icon: LiaUserAstronautSolid,
        path: EPaths.CADASTRO,
      },

      {
        label: "Bolão",
        icon: LiaWarehouseSolid,
        path: EPaths.ROOMSDASHBOARD,
      },
      {
        label: "Cupom",
        path: EPaths.COUPON,
        icon: RiCoupon2Line,
      },

      {
        label: "Regras",
        path: EPaths.RULES,
        icon: MdOutlineRule,
      },
      {
        label: "Prêmio",
        path: EPaths.AWARD,
        icon: RiAwardLine,
      },
      {
        label: "Whatsapp",
        path: EPaths.WHATSAPP,
        icon: FaWhatsapp,
      },
    ],
  },
  {
    label: "Sorteios Mega Sena",
    icon: TbClover,
    navs: [
      {
        label: "Adicionar Sorteios",
        icon: IoIosAddCircleOutline,
        path: EPaths.PRIZEDRAWDASHBOARD,
      },
      {
        label: "Visualizar Sorteios",
        icon: IoEyeOutline,
        path: EPaths.ALLCONCURSOS,
      },
    ].sort(order),
  },
  {
    label: "Ganhadores",
    path: EPaths.WINNERS,
    icon: GiPodiumWinner,
  },

  {
    label: "Ranking ",
    path: EPaths.RANKING,
    icon: FaRankingStar,
  },
  {
    label: "Financeiro",
    path: EPaths.FINANCIAL,
    icon: MdOutlineMonetizationOn,
  },
  {
    label: "Top Compradores",
    path: EPaths.BUYERS,
    icon: CgProfile,
  },
];

function order(a: INav, b: INav) {
  if (a.label > b.label) return 1;
  if (a.label < b.label) return -1;

  return 0;
}
