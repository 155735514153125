import { useState, ChangeEvent } from "react";
import {
  Box,
  Text,
  Input,
  Button,
  Checkbox,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useToast,
  InputGroup,
  InputRightElement,
  HStack,
  IconButton,
  Select,
  Progress,
} from "@chakra-ui/react";
import { IRegisterUser } from "../types/register/interface";
import { FaArrowLeft, FaEye, FaEyeSlash } from "react-icons/fa";
import { useNavigate } from "react-router";
import { useAuth } from "../contexts/authContext";
import { TermosUso } from "../components/termos";
import FloatingWhatsAppButton from "../components/floatingWhatsAppButton";

export const Register = () => {
  const [formData, setFormData] = useState<IRegisterUser>({
    nome: "",
    senha: "",
    telefone: "",
    email: "",
    cpf: "",
    perfil: 2,
    endereco: {
      logradouro: "",
      numero: "",
      complemento: "",
      bairro: "",
      cep: "",
      cidade: "",
      estado: "",
    },
  });

  const [confirmarEmail, setConfirmarEmail] = useState("");
  const [confirmarSenha, setConfirmarSenha] = useState("");
  const [showSenha, setShowSenha] = useState(false);
  const [showConfirmarSenha, setShowConfirmarSenha] = useState(false);
  const [step, setStep] = useState(1);
  const toast = useToast();
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [outsideCheckboxAccepted, setOutsideCheckboxAccepted] = useState(false);
  const [modalCheckboxAccepted, setModalCheckboxAccepted] = useState(false);
  const [formattedCPF, setFormattedCPF] = useState("");
  const [formattedTelefone, setFormattedTelefone] = useState("");
  const navigate = useNavigate();
  const [passwordStrength, setPasswordStrength] = useState(0);
  const { signUp } = useAuth();
  const [forcaSenha, setForcaSenha] = useState(0);
  const [senhaForte, setSenhaForte] = useState(false);
  const handleClickShowSenha = () => setShowSenha(!showSenha);
  const handleClickShowConfirmarSenha = () =>
    setShowConfirmarSenha(!showConfirmarSenha);

  function validaCPF(cpf: string): boolean {
    cpf = cpf.replace(/\D/g, "");
    if (cpf.length !== 11 || !!cpf.match(/(\d)\1{10}/)) {
      return false;
    }

    let soma = 0;
    let resto: number;
    for (let i = 1; i <= 9; i++) {
      soma += parseInt(cpf[i - 1]) * (11 - i);
    }

    resto = (soma * 10) % 11;
    if (resto === 10 || resto === 11) {
      resto = 0;
    }
    if (resto !== parseInt(cpf[9])) {
      return false;
    }

    soma = 0;
    for (let i = 1; i <= 10; i++) {
      soma += parseInt(cpf[i - 1]) * (12 - i);
    }
    resto = (soma * 10) % 11;
    if (resto === 10 || resto === 11) {
      resto = 0;
    }
    if (resto !== parseInt(cpf[10])) {
      return false;
    }

    return true;
  }
  const estadosBrasileiros = [
    { label: "AC" },
    { label: "AL" },
    { label: "AP" },
    { label: "AM" },
    { label: "BA" },
    { label: "CE" },
    { label: "DF" },
    { label: "ES" },
    { label: "GO" },
    { label: "MA" },
    { label: "MT" },
    { label: "MS" },
    { label: "MG" },
    { label: "PA" },
    { label: "PB" },
    { label: "PR" },
    { label: "PE" },
    { label: "PI" },
    { label: "RJ" },
    { label: "RN" },
    { label: "RS" },
    { label: "RO" },
    { label: "RR" },
    { label: "SC" },
    { label: "SP" },
    { label: "SE" },
    { label: "TO" },
  ];
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (name === "cpf") {
      const cleanedValue = value.replace(/\D/g, "").slice(0, 11);
      const formattedValue = cleanedValue.replace(
        /(\d{3})(\d{3})(\d{3})(\d{2})/,
        "$1.$2.$3-$4"
      );

      setFormattedCPF(formattedValue);
      setFormData({ ...formData, cpf: cleanedValue });
    } else if (name === "telefone") {
      const cleanedValue = value.replace(/\D/g, "");
      const formattedValue = cleanedValue
        .slice(0, 11)
        .replace(/(\d{2})(\d{5})(\d{4})/, "($1)$2-$3");

      setFormattedTelefone(formattedValue);
      setFormData({ ...formData, telefone: cleanedValue });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    setOutsideCheckboxAccepted(e.target.checked);
  };

  const handleModalCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    setModalCheckboxAccepted(e.target.checked);
  };

  const handleOpenTermsModal = () => {
    setShowTermsModal(true);
  };

  const handleCloseTermsModal = () => {
    setShowTermsModal(false);
  };

  const handleSaveTerms = async () => {
    if (modalCheckboxAccepted) {
      setTermsAccepted(true);
      setOutsideCheckboxAccepted(true);
    } else {
      setTermsAccepted(false);
      setOutsideCheckboxAccepted(false);
    }
    setShowTermsModal(false);
  };

  const handleSubmit = async () => {
    const { nome, senha, email, telefone, cpf } = formData;

    if (!nome || !senha || !email || !telefone || !cpf) {
      toast({
        title: "Por favor, preencha todos os campos.",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
      return;
    }

    if (!validaCPF(cpf)) {
      toast({
        title: "CPF inválido",
        description: "Por favor, insira um CPF válido.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    if (email !== confirmarEmail) {
      toast({
        title: "Erro",
        description: "Os e-mails não conferem.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    if (senha !== confirmarSenha) {
      toast({
        title: "Erro",
        description: "As senhas não conferem.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    if (!senhaForte) {
      toast({
        title: "Erro",
        description:
          "Senha fraca. A senha deve conter letras, números e caracteres especiais.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    if (!termsAccepted && !modalCheckboxAccepted) {
      setShowTermsModal(true);
      return;
    }

    try {
      const success = await signUp(formData);

      if (success) {
        setShowSuccessModal(true);
        setFormData({
          nome: "",
          email: "",
          senha: "",
          telefone: "",
          cpf: "",
          perfil: 2,
          endereco: {
            logradouro: "",
            numero: "",
            complemento: "",
            bairro: "",
            cep: "",
            cidade: "",
            estado: "",
          },
        });

        setFormattedCPF("");
        setFormattedTelefone("");
        setConfirmarSenha("");
        setTermsAccepted(false);
        setModalCheckboxAccepted(false);
      }
    } catch (error: any) {
      const errorMessage =
        error.response?.data?.erros?.join(", ") || "Erro ao tentar registrar.";
      toast({
        title: "Erro",
        description: errorMessage,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleSenhaChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setFormData({ ...formData, senha: value });
    const forca = verificarForcaSenha(value);
    setForcaSenha(forca);
    setSenhaForte(forca === 3);
    setConfirmarSenha("");
  };

  const handleCloseSuccessModal = () => {
    setShowSuccessModal(false);
    navigate("/sign-in");
  };

  const termsLinkStyles = {
    textDecoration: termsAccepted ? "none" : "underline",
    color: termsAccepted ? "gray.500" : "blue.500",
    cursor: "pointer",
  };

  const verificarForcaSenha = (senha: string) => {
    let forca = 0;

    if (/[a-zA-Z]/.test(senha)) forca++;
    if (/\d/.test(senha)) forca++;
    if (/[\W_]/.test(senha)) forca++;

    return forca;
  };

  return (
    <Box
      bg="#E5E7EB"
      borderRadius="md"
      boxShadow="md"
      maxW={{ base: "90%", md: "md" }}
      mx="auto"
      mt={{ base: 10, md: 7 }}
      p={{ base: 3, md: 5 }}
      w="100%"
      marginTop="6%"
      marginBottom="6%"
    >
      {step === 1 && (
        <>
          <Text
            fontSize={{ base: "2xl", md: "3xl" }}
            fontWeight="bold"
            mb={4}
            textAlign="center"
          >
            Cadastre-se
          </Text>
          <Box mb={4}>
            <Text mb={2}>Nome completo</Text>
            <Input
              borderColor="#333333"
              placeholder="Nome"
              name="nome"
              value={formData?.nome}
              onChange={handleChange}
            />
          </Box>
          <Box mb={4}>
            <Text mb={2}>CPF</Text>
            <Input
              borderColor="#333333"
              placeholder="CPF"
              name="cpf"
              value={formattedCPF}
              onChange={handleChange}
            />
          </Box>
          <Box mb={4}>
            <Text mb={2}>E-Mail</Text>
            <Input
              borderColor="#333333"
              placeholder="E-Mail"
              name="email"
              value={formData?.email}
              onChange={handleChange}
            />
          </Box>
          <Box mb={4}>
            <Text mb={2}>Confirmar E-mail</Text>
            <Input
              borderColor="#333333"
              placeholder="Confirmar E-mail"
              name="confirmarEmail"
              value={confirmarEmail}
              onChange={(e) => setConfirmarEmail(e.target.value)}
            />
            {confirmarEmail && formData.email !== confirmarEmail && (
              <Text color="red.500" mt={2}>
                E-mails não conferem
              </Text>
            )}
          </Box>
          <Box mb={4}>
            <Text mb={2}>Telefone</Text>
            <Input
              borderColor="#333333"
              placeholder="(00)00000-0000"
              name="telefone"
              value={formattedTelefone}
              onChange={handleChange}
            />
          </Box>

          <Button
            colorScheme="green"
            w="100%"
            bg="#16A34A"
            onClick={() => setStep(2)}
          >
            Avançar
          </Button>
        </>
      )}
      {step === 2 && (
        <>
          {" "}
          <IconButton
            aria-label="Voltar"
            icon={<FaArrowLeft />}
            onClick={() => setStep(1)}
            bg="transparent"
            _hover={{ bg: "transparent", color: "#59635e" }}
            size="lg"
            mb={4}
          />{" "}
          <Box mb={4}>
            <Text mb={2}>CEP</Text>
            <Input
              type="number"
              borderColor="#333333"
              placeholder="CEP"
              name="cep"
              value={formData.endereco.cep}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  endereco: { ...formData.endereco, cep: e.target.value },
                })
              }
            />
          </Box>{" "}
          <HStack>
            <Box mb={4}>
              <Text mb={2}>Cidade</Text>
              <Input
                borderColor="#333333"
                placeholder="Cidade"
                name="cidade"
                value={formData.endereco.cidade}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    endereco: { ...formData.endereco, cidade: e.target.value },
                  })
                }
              />
            </Box>

            <Box mb={4}>
              <Text mb={2}>Estado</Text>
              <Select
                borderColor="#333333"
                placeholder="Selecione o Estado"
                name="estado"
                value={formData.endereco.estado}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    endereco: { ...formData.endereco, estado: e.target.value },
                  })
                }
              >
                {estadosBrasileiros.map((estado) => (
                  <option key={estado.label} value={estado.label}>
                    {estado.label}
                  </option>
                ))}
              </Select>
            </Box>
          </HStack>
          <HStack>
            <Box mb={4}>
              <Text mb={2}>Rua</Text>
              <Input
                borderColor="#333333"
                placeholder="Logradouro"
                name="logradouro"
                value={formData.endereco.logradouro}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    endereco: {
                      ...formData.endereco,
                      logradouro: e.target.value,
                    },
                  })
                }
              />
            </Box>
            <Box mb={4}>
              <Text mb={2}>Número</Text>
              <Input
                type="number"
                borderColor="#333333"
                placeholder="Número"
                name="numero"
                value={formData.endereco.numero}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    endereco: { ...formData.endereco, numero: e.target.value },
                  })
                }
              />
            </Box>
          </HStack>
          <HStack>
            <Box mb={4}>
              <Text mb={2}>Bairro</Text>
              <Input
                borderColor="#333333"
                placeholder="Bairro"
                name="bairro"
                value={formData.endereco.bairro}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    endereco: { ...formData.endereco, bairro: e.target.value },
                  })
                }
              />
            </Box>
            <Box mb={4}>
              <Text mb={2}>Complemento</Text>
              <Input
                borderColor="#333333"
                placeholder="Complemento"
                name="complemento"
                value={formData.endereco.complemento}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    endereco: {
                      ...formData.endereco,
                      complemento: e.target.value,
                    },
                  })
                }
              />
            </Box>
          </HStack>
          <Box mb={4}>
            <Text mb={2}>Senha</Text>
            <InputGroup>
              <Input
                borderColor="#333333"
                placeholder="Senha"
                name="senha"
                value={formData.senha}
                onChange={handleSenhaChange}
                type={showSenha ? "text" : "password"}
              />
              <InputRightElement width="4.5rem">
                <IconButton
                  h="1.75rem"
                  size="sm"
                  onClick={handleClickShowSenha}
                  icon={showSenha ? <FaEyeSlash /> : <FaEye />}
                  aria-label={showSenha ? "Ocultar senha" : "Mostrar senha"}
                />
              </InputRightElement>
            </InputGroup>
            <Progress
              value={(forcaSenha / 3) * 100}
              size="xs"
              mt={2}
              colorScheme={forcaSenha === 3 ? "green" : "red"}
            />
            <Text mt={1} color={forcaSenha === 3 ? "green.500" : "red.500"}>
              {forcaSenha === 3 ? "Senha forte" : "Senha fraca"}
            </Text>
          </Box>
          <Box mb={4}>
            <Text mb={2}>Confirmar Senha</Text>
            <InputGroup size="md">
              <Input
                borderColor="#333333"
                placeholder="Confirmar Senha"
                name="confirmarSenha"
                value={confirmarSenha}
                type={showConfirmarSenha ? "text" : "password"}
                onChange={(e) => setConfirmarSenha(e.target.value)}
              />{" "}
              <InputRightElement>
                <Button
                  h="1.75rem"
                  size="sm"
                  onClick={handleClickShowConfirmarSenha}
                >
                  {showConfirmarSenha ? <FaEyeSlash /> : <FaEye />}
                </Button>
              </InputRightElement>
            </InputGroup>
            {confirmarSenha && formData.senha !== confirmarSenha && (
              <Text color="red.500" mt={2}>
                Senhas não conferem
              </Text>
            )}
          </Box>
          <Button
            mt={3}
            colorScheme="green"
            w="100%"
            bg="#16A34A"
            onClick={handleSubmit}
          >
            Registrar
          </Button>
          <Checkbox
            mt={4}
            isChecked={outsideCheckboxAccepted}
            onChange={handleCheckboxChange}
          >
            Aceito os{" "}
            <Text
              as="span"
              onClick={handleOpenTermsModal}
              style={termsLinkStyles}
            >
              Termos de Uso
            </Text>
          </Checkbox>
        </>
      )}
      <Modal isOpen={showTermsModal} onClose={handleCloseTermsModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Termos de Uso</ModalHeader>
          <ModalBody>
            <TermosUso />
            <Checkbox
              mt={4}
              isChecked={modalCheckboxAccepted}
              onChange={handleModalCheckboxChange}
            >
              Eu li e aceito os Termos de Uso
            </Checkbox>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="green" onClick={handleSaveTerms}>
              Salvar
            </Button>
            <Button ml={3} onClick={handleCloseTermsModal}>
              Fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={showSuccessModal} onClose={handleCloseSuccessModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Registro bem-sucedido</ModalHeader>
          <ModalBody>
            <Text>Seu registro foi realizado com sucesso!</Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="green" onClick={handleCloseSuccessModal}>
              OK
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>{" "}
      <FloatingWhatsAppButton />
    </Box>
  );
};
