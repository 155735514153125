import {
  Table as ChakraUITable,
  TableContainer,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { IColumn } from "../types/table";

interface IProps<T> {
  columns: IColumn<T>[];
  rows: T[];
}

export function Table<T>({ columns, rows }: IProps<T>) {
  return (
    <TableContainer marginX="auto" width="100%">
      <ChakraUITable bg="white" colorScheme="yellow">
        <Thead>
          <Tr bg="#006391">
            {columns.map(({ label, width }, index) => (
              <Th
                color="white"
                fontWeight="bold"
                key={index}
                width={`${width}%`}
                textAlign="center"
              >
                {label}
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {rows && rows.length > 0 ? (
            rows.map((row, index) => (
              <Tr
                key={index}
                height="56px"
                bg="#DBEFFF"
                borderBottom="8px solid #fff"
                borderTop="8px solid #fff"
              >
                {columns.map((column, columnIndex) => (
                  <Td
                    key={columnIndex}
                    width={`${column.width}%`}
                    textAlign="center"
                    color="#006391"
                  >
                    {column.render(row)}
                  </Td>
                ))}
              </Tr>
            ))
          ) : (
            <Tr>
              <Td colSpan={columns.length} textAlign="center">
                Sem dados disponíveis.
              </Td>
            </Tr>
          )}
        </Tbody>

        <Tfoot></Tfoot>
      </ChakraUITable>
    </TableContainer>
  );
}
