import { useState, useEffect } from "react";
import {
  Box,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Stack,
  Heading,
  Input,
  Button,
  Select,
  Center,
  useDisclosure,
  IconButton,
  SimpleGrid,
  VStack,
} from "@chakra-ui/react";
import { ViewIcon } from "@chakra-ui/icons";
import { http } from "../../services/http";
import { useAuth } from "../../contexts/authContext";
import { useNavigate } from "react-router-dom";

interface BuyersResponse {
  conteudo: Buyers[]
}

interface Buyer {
  topCompradoresId: number,
  salaId: number,
  userId: number,
  quantidadePalpites: number,
  statusSalas: {
    id: number,
    descricao: string
  },
  ranking: number,
  nomeUsuario: string,
  cidade: string,
  valorTotalCompras: number
}

interface Buyers {
  salaId: number,
  topCompradores: Buyer[]
  statusSala: {
    id: number,
    descricao: string
  },
}


const Buyers = () => {
  const [bets, setBets] = useState<Buyers[]>([]);
  const [filteredBets, setFilteredBets] = useState<(Buyers | null)[]>([]);
  const [filterType, setFilterType] = useState("nome");
  const [search, setSearch] = useState("");
  const [sortType, setSortType] = useState("rankingAsc");
  const { token } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBuyers = async () => {
      try {
        const response = await http.get<BuyersResponse>("/top-compradores/", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.data && response.data.conteudo) {
          const apiBets: Buyers[] = response.data.conteudo
          setBets(apiBets);
          setFilteredBets(apiBets);
        } else {
          setBets([]);
          setFilteredBets([]);
        }
      } catch (error) {
        console.error("Erro ao buscar os top compradores:", error);
      }
    };

    fetchBuyers();
  }, []);

  useEffect(() => {
    let filtered: (Buyers | null)[] = [...bets];
    if (search) {
      filtered = bets.map((bet) => {
        let topBuyers: Buyer[] = []
        switch (filterType) {
          case "nome":
            topBuyers = bet.topCompradores.filter(topBuyer => topBuyer.nomeUsuario.toLowerCase().includes(search.toLowerCase()));
            return { ...bet, topCompradores: topBuyers }
          case "usuario":
            topBuyers = bet.topCompradores.filter(topBuyer => topBuyer.userId.toString().toLowerCase().includes(search.toLowerCase()));
            return { ...bet, topCompradores: topBuyers }
          case "cidade":
            topBuyers = bet.topCompradores.filter(topBuyer => topBuyer.cidade.toLowerCase().includes(search.toLowerCase()));
            return { ...bet, topCompradores: topBuyers }
          case "bolao":
            if (bet.salaId.toString().toLowerCase().includes(search.toLowerCase())) {
              return bet
            }
            return null
          default:
            return bet;
        }
      });
    }

    if (sortType === "az") {
      filtered.forEach((a) => a?.topCompradores.sort((a, b) => a?.nomeUsuario.localeCompare(b.nomeUsuario)));
    } else if (sortType === "za") {
      filtered.forEach((a) => a?.topCompradores.sort((a, b) => b.nomeUsuario.localeCompare(a?.nomeUsuario)));
    } else if (sortType === "rankingAsc") {
      filtered.forEach((a) => a?.topCompradores.sort((a, b) => a?.ranking - b.ranking));
    } else if (sortType === "rankingDesc") {
      filtered.forEach((a) => a?.topCompradores.sort((a, b) => b.ranking - a?.ranking));
    }

    setFilteredBets(filtered);
  }, [search, filterType, sortType, bets]);

  const formatNumber = (number: number): string => {
    return number.toString().padStart(4, "0");
  };

  const getPremio = (premio: number): string => {
    return premio.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
  };

  const getStatusText = (status: string) => {
    if (status === "Aberta") {
      return "Vendas Abertas";
    }
    if (status === "Finalizada") {
      return "Finalizado";
    }
    return status;
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case "Aberta":
        return "#068C1C";
      case "Em Andamento":
        return "#FFDF01";
      case "Finalizada":
        return "#DB0101";
      default:
        return "gray";
    }
  };

  const getStatus = (status: string) => {
    switch (status) {
      case "Aberta":
        return "white";
      case "Em Andamento":
        return "#068D16";
      case "Finalizada":
        return "white";
      default:
        return "black";
    }
  };

  return (
    <Stack spacing={4} p="4" mx="auto" maxW="100%">
      <Heading textAlign="center" color="white">
        Top Compradores
      </Heading>
      <Text fontSize="18px" textAlign="center" color="white" fontWeight="bold">
        Confira Abaixo os Top Compradores
      </Text>
      <Text color="white" fontSize="18px" textAlign="center" fontWeight="bold">
        Filtros de buscas por:
      </Text>

      <Center mb={5}>
        <SimpleGrid
          columns={{ base: 1, md: 3 }}
          spacing={4}
          w={{ base: "90%", sm: "60%", md: "70%", lg: "50%" }}
          alignItems="center"
        >
          <Select
            bg="white"
            value={filterType}
            onChange={(e) => {
              setFilterType(e.target.value);
              setSearch("");
            }}
            width="100%"
          >
            <option value="nome">Nome</option>
            <option value="usuario">Usuário</option>
            <option value="cidade">Cidade</option>
            <option value="bolao">Bolão</option>
          </Select>
          <Input
            bg="white"
            placeholder={`Buscar por ${filterType}`}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            width="100%"
          />
          <Select
            bg="white"
            value={sortType}
            onChange={(e) => setSortType(e.target.value)}
            width="100%"
          >
            <option value="az">Nome A/Z</option>
            <option value="za">Nome Z/A</option>
            <option value="rankingAsc">Mais Pontos</option>
            <option value="rankingDesc">Menos Pontos</option>
          </Select>
        </SimpleGrid>
      </Center>

      {filteredBets.map((bet) => (
        bet &&
        (
          <Box bg="white" borderRadius="5px" overflow="hidden" position="relative">
            <Box bg="#006391" py={2}>
              <Text color="white" textAlign="center" fontWeight="bold">
                Bolão - {formatNumber(bet.salaId)}
              </Text>
            </Box>

            <Box textAlign="center" my={2}>
              <Box
                bg={
                  getStatusColor(bet.statusSala.descricao)
                }
                color={
                  filteredBets.length > 0
                    ? getStatus(bet.statusSala.descricao)
                    : "black"
                }
                fontWeight="bold"
                mx="auto"
                pr={1}
                pl={1}
                width="fit-content"
                mt={3}
                mb={3}
              >
                {getStatusText(
                  filteredBets.length > 0 ? bet.statusSala.descricao : ""
                )}{" "}
              </Box>
            </Box>
            <Box overflowX="auto" whiteSpace="nowrap">
              <Table variant="simple" maxW="100%" bg="#DBEFFF">
                <Thead>
                  <Tr bg="#006391">
                    <Th color="white" textAlign="center">
                      Ranking
                    </Th>
                    <Th color="white" textAlign="center">
                      Usuário
                    </Th>
                    <Th color="white" textAlign="center">
                      Nome
                    </Th>
                    <Th color="white" textAlign="center">
                      Cidade
                    </Th>
                    <Th color="white" textAlign="center">
                      Quantidade de Palpites
                    </Th>{" "}
                    <Th color="white" textAlign="center">
                      Compras
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {bet.topCompradores.length > 0 ? bet.topCompradores.map((buyer, index) => (
                    <Tr key={index}>
                      <Td textAlign="center" color="#006391" fontWeight="bold">
                        {formatNumber(buyer.ranking)}
                      </Td>
                      <Td textAlign="center" color="#006391" fontWeight="bold">
                        <Td textAlign="center" color="#006391" fontWeight="bold">
                          {formatNumber(buyer.userId)}
                        </Td>
                      </Td>
                      <Td textAlign="center" color="#006391" fontWeight="bold">
                        {buyer.nomeUsuario}
                      </Td>
                      <Td textAlign="center" color="#006391" fontWeight="bold">
                        {buyer.cidade}
                      </Td>
                      <Td
                        bg="#006391"
                        textAlign="center"
                        color="white"
                        fontWeight="bold"
                      >
                        {buyer.quantidadePalpites}
                      </Td>

                      <Td align="center">
                        <Box
                          onClick={() =>
                            navigate("/viewShopping", {
                              state: {
                                userId: buyer.userId,
                                salaId: bet.salaId,
                              },
                            })
                          }
                          _hover={{
                            cursor: "pointer",
                            textDecoration: "underline",
                          }}
                        >
                          <VStack>
                            <IconButton
                              size="sm"
                              aria-label="Ver Compras"
                              icon={<ViewIcon />}
                              colorScheme="blue"
                              variant="outline"
                              style={{
                                padding: 0,
                                margin: 0,
                                background: "none",
                                border: "none",
                              }}
                            />
                            <Text
                              color="#006391"
                              fontWeight="bold"
                              fontSize={["10px", "12px", "14px"]}
                              textDecoration="underline"
                            >
                              Ver Compras
                            </Text>{" "}
                          </VStack>
                        </Box>
                      </Td>
                    </Tr>
                  )) : (
                    <Tr>
                      <Td colSpan={7} textAlign="center">
                        Nenhum registro encontrado neste bolão
                      </Td>
                    </Tr>)}
                  {filteredBets.length === 0 && (
                    <Tr>
                      <Td colSpan={7} textAlign="center">
                        Nenhum dado encontrado
                      </Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
            </Box>
          </Box>
        )
      )
      )}
    </Stack>
  );
};

export default Buyers;
