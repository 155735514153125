import { ArrowBackIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  IconButton,
  Input,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/authContext";
import { http } from "../../services/http";

export function EditRuleScreen() {
  const location = useLocation();
  const navigate = useNavigate();
  const toast = useToast();
  const [description, setDescription] = useState("");
  const [legend, setLegend] = useState("");
  const { token } = useAuth();
  const regraId = location.state?.regraId;

  useEffect(() => {
    const { state } = location;
    if (state) {
      setDescription(state.descricao);
      setLegend(state.legenda);
    }
  }, [location]);

  const handleUpdateRule = () => {
    if (!description || !legend) {
      toast({
        title: "Todos os campos são obrigatórios.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    const payload = [
      {
        regraId: regraId,
        descricao: description,
        legenda: legend,
      },
    ];

    http
      .put(`/regras/altera`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then(() => {
        toast({
          title: "Regra atualizada com sucesso.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        navigate("/rulesDashboard");
      })
      .catch((error) => {
        console.error("Erro ao atualizar regra:", error);
        toast({
          title: "Erro ao atualizar regra.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  };

  return (
    <Container
      bg="white"
      borderRadius="md"
      boxShadow="xl"
      mx="auto"
      mt={10}
      p={6}
      mb="5%"
    >
      <Flex align="center" mb={4}>
        <IconButton
          bg="#38A169"
          color="white"
          aria-label="Voltar"
          icon={<ArrowBackIcon />}
          variant="ghost"
          onClick={() => navigate(-1)}
        />
        <Heading as="h2" size="lg" ml={4}>
          Editar Regra
        </Heading>
      </Flex>
      <Box mb={4}>
        {" "}
        <FormControl>
          <FormLabel>Título</FormLabel>
          <Input
            maxLength={500}
            mb={4}
            value={legend}
            onChange={(e) => setLegend(e.target.value)}
          />
        </FormControl>
        <FormControl mb={4}>
          <FormLabel>Descrição</FormLabel>
          <Textarea
            maxLength={500}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Digite a descrição aqui..."
            minHeight="150px"
          />
        </FormControl>
      </Box>
      <Flex justifyContent="center" mt={7}>
        <Button colorScheme="green" w="50%" onClick={handleUpdateRule}>
          Atualizar
        </Button>
      </Flex>
    </Container>
  );
}
