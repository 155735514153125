import { Route, Routes } from "react-router-dom";
import { PrivacidadeDados } from "../../components/privacidadeDados";
import { TermoConsentimento } from "../../components/termoConsentimento";
import { AuthProvider } from "../../contexts/authContext";
import { MainLayout } from "../../layouts/main";
import Bets from "../../pages/bets";
import { ChangePassword } from "../../pages/changePassword";
import { CodeVerify } from "../../pages/codeVerify";
import Buyers from "../../pages/dashboard/buyers";
import { ViewRuleScreen } from "../../pages/dashboard/viewRuleScreen";
import { DrawInformation } from "../../pages/drawInformation";
import { ForgotPassword } from "../../pages/forgotPassword";
import { Help } from "../../pages/help";
import HomePage from "../../pages/home";
import { PrizeDraw } from "../../pages/prizeDraw";
import Ranking from "../../pages/rankings";
import { Register } from "../../pages/register";
import { Regras } from "../../pages/regras";
import SignIn from "../../pages/sign-in";
import Winners from "../../pages/winners";

const Unauthenticated = () => {
  return (
    <Routes>
      <Route path="/" element={<MainLayout />}>
        <Route
          path="/sign-in"
          element={
            <AuthProvider>
              <SignIn />
            </AuthProvider>
          }
        />
        <Route path="/regras" element={<Regras />} />
        <Route path="/bets" element={<Bets />} />
        <Route path="/" element={<HomePage />} />
        <Route path="/help" element={<Help />} />
        <Route path="/prizeDraw" element={<PrizeDraw />} />
        <Route path="/ranking" element={<Ranking />} />
        <Route path="/drawInformation" element={<DrawInformation />} />
        <Route path="/termoConsentimento" element={<TermoConsentimento />} />
        <Route path="/privacidadeDados" element={<PrivacidadeDados />} />
        <Route path="/buyers" element={<Buyers />} />
        <Route path="/forgotPassword" element={<ForgotPassword />} />
        <Route path="/codeVerify" element={<CodeVerify />} />
        <Route path="/changePassword" element={<ChangePassword />} />
        <Route path="/viewRuleScreen" element={<ViewRuleScreen />} />
        <Route path="/winners" element={<Winners />} />

        <Route
          path="/register"
          element={
            <AuthProvider>
              <Register />
            </AuthProvider>
          }
        />
      </Route>
    </Routes>
  );
};

export default Unauthenticated;
