import { Box, Text, Input, Button, useToast, Heading } from "@chakra-ui/react";
import { useState, ChangeEvent } from "react";
import { useNavigate } from "react-router-dom";
import FloatingWhatsAppButton from "../components/floatingWhatsAppButton";
import { http } from "../services/http";

export const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const toast = useToast();
  const navigate = useNavigate();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const isValidEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async () => {
    if (!isValidEmail(email)) {
      toast({
        title: "Email inválido",
        description: "Por favor, informe um email válido.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      const response = await http.post(
        "/codigo-verificacao/email-recuperacao",
        { email }
      );
      if (response.data.conteudo.emailEnviado) {
        toast({
          title: "Sucesso",
          description: "O código de recuperação foi enviado para o seu email.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        navigate("/codeVerify", {
          state: { userId: response.data.conteudo.userId },
        });
      } else {
        toast({
          title: "Erro",
          description:
            "Não foi possível enviar o código de recuperação. Tente novamente.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      const errorResponse = error as {
        response?: { data?: { erros?: string[] } };
      };
      const errorMessage =
        errorResponse.response?.data?.erros?.[0] ||
        "Não foi possível enviar o código de recuperação. Tente novamente mais tarde.";
      toast({
        title: "Erro",
        description: errorMessage,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box
      bg="#E5E7EB"
      borderRadius="md"
      boxShadow="md"
      maxW={{ base: "90%", md: "md" }}
      mx="auto"
      mt={{ base: 10, md: 20 }}
      p={{ base: 3, md: 5 }}
      w="100%"
      marginTop="6%"
      marginBottom="6%"
    >
      <Box mb={4}>
        <Heading mb={2} fontSize="2xl" textAlign="center" fontWeight="bold">
          Informe seu E-Mail
        </Heading>
        <Input
          borderColor="#333333"
          placeholder="E-Mail"
          name="email"
          value={email}
          onChange={handleChange}
        />
      </Box>
      <Text mb={4} fontSize={{ base: "sm", md: "sm" }}>
        E-mail deverá ser o mesmo que foi informado ao cadastrar.
      </Text>
      <Button
        colorScheme="green"
        mb={{ base: 5, md: 0 }}
        w="100%"
        bg="#16A34A"
        onClick={handleSubmit}
      >
        Enviar Código
      </Button>{" "}
      <FloatingWhatsAppButton />
    </Box>
  );
};
