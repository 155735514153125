import {
  Button,
  Flex,
  IconButton,
  Image,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../../assets/logo11.png";
import { GiHamburgerMenu } from "react-icons/gi";
import { FaUserCircle } from "react-icons/fa";
import { AuthContext } from "../../../contexts/authContext";
 
interface IProps {
  onOpen: () => void;
}

export function Header({ onOpen }: IProps) {
  const { isAuthenticated, user, signOut } = useContext(AuthContext);
  const imageSize = useBreakpointValue({
    base: "180px",
    md: "180px",
    lg: "180px",
  });
  
  const menuIconSize = useBreakpointValue({ base: "30px", lg: "40px" });

  const isMobile = useBreakpointValue({ base: true, lg: false });

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleAvatarClick = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleSignOut = () => {
    signOut();
  };

  return (
    <Flex
      h="100px"
      width="full"
      px="5"
      bg="#068C1C"
      alignItems="center"
      justifyContent="space-between"
      position="sticky"
      top="0"
      zIndex="10"
    >
      <IconButton
        aria-label="Open Menu"
        variant="unstyled"
        onClick={onOpen}
        mr="2"
        ml={isMobile ? 0 : 60}
      >
        <GiHamburgerMenu color="white" size={menuIconSize} />
      </IconButton>

      <Flex flex="1" justifyContent="center" alignItems="center">
        <Link to="/">
          <Image
            src={logo}
            alt="Logo"
            maxW={imageSize}
            objectFit="contain"
            objectPosition="center"
          />
        </Link>
      </Flex>

      {isAuthenticated ? (
        <Flex
          flexDirection="column"
          alignItems="center"
          ml={isMobile ? 0 : 2}
          mr={isMobile ? 0 : 60}
        >
          <button
            className="bubble-element Icon baTaIrw"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              background: "transparent",
              border: "none",
              cursor: "pointer",
              outline: "none",
            }}
            onClick={handleAvatarClick}
          >
            <FaUserCircle
              style={{
                fontSize: "40px",
                color: "white",
              }}
            />
          </button>

          <Text color="white" fontSize="sm" mt="1">
            Olá, {user && user.nome ? user.nome.split(" ")[0] : ""}
          </Text>

          {isModalOpen && (
            <div
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                background: "rgba(0, 0, 0, 0.5)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                zIndex: 1000,
              }}
              onClick={closeModal}
            >
              <div
                style={{
                  width: "50%",
                  maxWidth: "250px",
                  background: "white",
                  padding: "20px",
                  borderRadius: "8px",
                }}
                onClick={(e) => e.stopPropagation()}
              >
                <Text
                  textAlign="center"
                  fontSize="1.25em"
                  fontWeight="bold"
                  mb="1rem"
                >
                  Deseja sair?
                </Text>
                <Button
                  onClick={handleSignOut}
                  bg="#E53E3E"
                  color="white"
                  p="8px 16px"
                  borderRadius="4px"
                  border="none"
                  cursor="pointer"
                  fontSize="1em"
                  width="100%"
                >
                  Sair
                </Button>
              </div>
            </div>
          )}
        </Flex>
      ) : (
        <Link to="/sign-in">
          <Flex
            flexDirection="column"
            alignItems="center"
            ml={isMobile ? 0 : 2}
            mr={isMobile ? 0 : 60}
          >
            <button
              className="bubble-element Icon baTaIrw"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "transparent",
                border: "none",
                cursor: "pointer",
                outline: "none",
              }}
            >
              <FaUserCircle
                style={{
                  fontSize: "40px",
                  color: "white",
                }}
              />
            </button>

            <Text color="white" fontSize="sm" mt="1">
              Entre
            </Text>
          </Flex>
        </Link>
      )}
    </Flex>
  );
}
