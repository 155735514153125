import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Text,
  HStack,
  IconButton,
  CircularProgress,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  useDisclosure,
} from "@chakra-ui/react";
import { RiPencilLine } from "react-icons/ri";
import { IColumn } from "../../types/componentes/table";
import { Table } from "../../core/components/table";
import { useAuth } from "../../contexts/authContext";
import { http } from "../../services/http";

interface IWhatsApp {
  midiaSocialId: number;
  descricao: string;
  valor: string;
  ativo: boolean;
}

export function WhatsAppScreen() {
  const [whatsApp, setWhatsApp] = useState<IWhatsApp | null>(null);
  const [newNumber, setNewNumber] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [editMode, setEditMode] = useState<boolean>(false);

  const toast = useToast();
  const {
    isOpen: isEditModalOpen,
    onOpen: onEditModalOpen,
    onClose: onEditModalClose,
  } = useDisclosure();

  const { token } = useAuth();

  useEffect(() => {
    const fetchWhatsApp = async () => {
      try {
        const response = await http.get("/midia-social/", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const whatsappData = response.data.conteudo[0];
        if (whatsappData) {
          setWhatsApp(whatsappData);
          setNewNumber(whatsappData.valor);
        }
      } catch (error) {
        toast({
          title: "Erro ao buscar dados",
          description: "Não foi possível buscar os dados do WhatsApp.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setIsLoading(false);
      }
    };

    fetchWhatsApp();
  }, [toast]);

  const handleEditNumber = async () => {
    if (newNumber && whatsApp) {
      setIsLoading(true);
      try {
        const updatedWhatsApp = { ...whatsApp, valor: newNumber };
        await http.put("/midia-social/altera", updatedWhatsApp, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setWhatsApp(updatedWhatsApp);
        setEditMode(false);
        onEditModalClose();
        toast({
          title: "Número atualizado.",
          description: "O número de WhatsApp foi atualizado com sucesso.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } catch (error) {
        toast({
          title: "Erro ao atualizar",
          description: "Não foi possível atualizar o número de WhatsApp.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setIsLoading(false);
      }
    } else {
      toast({
        title: "Número inválido.",
        description: "Por favor, adicione um número de WhatsApp.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const columns: IColumn<IWhatsApp>[] = [
    {
      label: <Text fontWeight="bold">Link WhatsApp</Text>,
      render: (whatsApp) => <Text>{whatsApp.valor}</Text>,
      width: 70,
    },
    {
      label: <Text fontWeight="bold">Ações</Text>,
      render: (whatsApp) => (
        <HStack justifyContent="center">
          <IconButton
            aria-label="edit"
            colorScheme="green"
            bgColor="green.500"
            color="white"
            onClick={() => {
              setEditMode(true);
              setNewNumber(whatsApp.valor);
              onEditModalOpen();
            }}
          >
            <RiPencilLine size={20} />
          </IconButton>
        </HStack>
      ),
      width: 30,
    },
  ];

  return isLoading ? (
    <Flex flex="1" alignItems="center" justifyContent="center">
      <CircularProgress color="#16A34A" isIndeterminate />
    </Flex>
  ) : (
    <Flex flex="1" flexDirection="column" paddingY={5}>
      <Container
        borderRadius={10}
        bg={"gray.200"}
        maxWidth="100%"
        display="flex"
        flexDirection="column"
        gap={10}
        mt={6}
        w={{ base: "100%", lg: "50%" }}
      >
        <Box>
          <Text
            marginTop={5}
            fontSize={["2xl", "3xl"]}
            textAlign="center"
            fontWeight="bold"
          >
            WhatsApp
          </Text>
        </Box>

        <Flex
          flexDirection="column"
          width="full"
          alignItems="flex-end"
          mb={10}
          p={0}
        >
          <Table<IWhatsApp>
            columns={columns}
            rows={whatsApp ? [whatsApp] : []}
          />
        </Flex>
      </Container>

      <Modal isOpen={isEditModalOpen} onClose={onEditModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Editar Link de WhatsApp</ModalHeader>
          <ModalBody>
            <FormControl>
              <FormLabel>Link</FormLabel>
              <Input
                value={newNumber}
                onChange={(e) => setNewNumber(e.target.value)}
                placeholder="Digite o Link do WhatsApp"
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Flex justifyContent="space-between" width="100%">
              <Button variant="ghost" onClick={onEditModalClose} bg="#e7e1e1">
                Cancelar
              </Button>
              <Button
                colorScheme="green"
                color="white"
                mr={3}
                onClick={handleEditNumber}
              >
                Atualizar
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
}
