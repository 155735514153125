import {
    Stat,
    StatLabel,
    StatNumber,
    StatHelpText,
    StatArrow,
    Flex,
    useMediaQuery,
  } from "@chakra-ui/react";
  
  interface ControlPanelItemProps {
    title: string;
    value: number;
    percentChange: number;
    formatValue: boolean;
  }
  
  export const ControlPanelItem = ({
    title,
    value,
    percentChange,
    formatValue = false,
  }: ControlPanelItemProps) => {
    const [isLargerThan780] = useMediaQuery("(min-width: 780px)");
    const isIncrease = percentChange > 0;
  
    const formattedValue = formatValue
      ? new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
          minimumFractionDigits: 2,
        }).format(value)
      : value;
  
    const formattedPercentChange = parseFloat(percentChange.toFixed(2));
  
    return (
      <Flex backgroundColor="#F5F5F5" minH={isLargerThan780 ? "100%" : "200px"}>
        <Stat padding={4}>
          <StatLabel>{title}</StatLabel>
          <StatNumber my={2}  fontSize={isLargerThan780 ? 32 : 24}>{formattedValue}</StatNumber>
          <StatHelpText>
            {isIncrease ? (
              <>
                <StatArrow type="increase" />
                {Math.abs(formattedPercentChange)}%
              </>
            ) : (
              // Math.abs desconsidera se o número é negativo
              <>
                <StatArrow type="decrease" />
                {Math.abs(formattedPercentChange)}%
              </>
            )}{" "}
            comparado ao mês anterior
          </StatHelpText>
        </Stat>
      </Flex>
    );
  };
  