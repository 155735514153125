import {
  Table as ChakraUITable,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { IColumn } from "../../types/componentes/table/interfaces";

interface IProps<T> {
 columns: IColumn<T>[];
 rows: T[];
}

export function Table<T>({ columns, rows }: IProps<T>) {  
  return (
    <TableContainer marginX="auto" width="full">
      <ChakraUITable variant="striped" >
        <Thead>
          <Tr >
            {columns.map(({ label, width }, index) => (
              <Th key={index} width={`${width}%`} textAlign="center">
                {label}
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {rows && rows.length > 0 ? (
            rows.map((row, index) => (
              <Tr key={index} height="56px">
                {columns.map((column, columnIndex) => (
                  <Td
                    key={columnIndex}
                    width={`${column.width}%`}
                    textAlign="center"
                  >
                    {column.render(row)}
                  </Td>
                ))}
              </Tr>
            ))
          ) : (
            <Tr>
              <Td colSpan={columns.length} textAlign="center">
                Sem dados disponíveis.
              </Td>
            </Tr>
          )}
        </Tbody>
      </ChakraUITable>
    </TableContainer>
  );
}