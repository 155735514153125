import { useState, useEffect } from "react";
import {
  Box,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Stack,
  Heading,
  Input,
  HStack,
  Select,
  Center,
} from "@chakra-ui/react";
import FloatingWhatsAppButton from "../components/floatingWhatsAppButton";
import { useAuth } from "../contexts/authContext";
import { http } from "../services/http";

type NumeroCota = {
  numero: string;
  numeroAcertado: boolean;
};

type CotaConcursoVencedor = {
  diaConcurso: string;
  numeroConcurso: number;
  totalPontosConcurso: number;
  numerosCotas: NumeroCota[];
};

type Cota = {
  cotasId: number;
  rankingCota: number;
  totalPontos: number;
  cotasConcursosVencedores: CotaConcursoVencedor[];
};

type StatusSala = {
  id: number;
  descricao: string;
};

type Bet = {
  salaId: number;
  cotas: Cota[];
  statusSalaResponseDTO: StatusSala;
};

type FormatNumbersProps = {
  numbers: NumeroCota[];
};

const formatNumbers = ({ numbers }: FormatNumbersProps) => {
  if (!numbers) {
    return null;
  }

  return (
    <>
      {numbers.map((numObj, index) => (
        <Box
          key={index}
          bg={numObj.numeroAcertado ? "green" : "white"}
          color={numObj.numeroAcertado ? "white" : "green"}
          border="2px solid green"
          borderRadius="50%"
          width="24px"
          height="24px"
          lineHeight="24px"
          marginRight="5px"
          display="inline-flex"
          justifyContent="center"
          alignItems="center"
        >
          {numObj.numero}
        </Box>
      ))}
    </>
  );
};

const Bets = () => {
  const [bets, setBets] = useState<Bet[]>([]);
  const [filteredBets, setFilteredBets] = useState<Bet[]>([]);
  const [filterType, setFilterType] = useState("bolao");
  const [search, setSearch] = useState("");
  const { user, token } = useAuth();
  const userId = user?.userId;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await http.get(`/cotas/meus-palpites/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = response.data;
        if (data.codigoHTTP === 200) {
          setBets(data.conteudo);
          setFilteredBets(data.conteudo);
        }
      } catch (error) {
        console.error("Erro ao buscar os palpites:", error);
      }
    };

    fetchData();
  }, [userId, token]);

  useEffect(() => {
    if (!bets.length) return;

    const filtered = bets.filter((bet) => {
      if (filterType === "bolao") {
        return bet.salaId.toString().includes(search.trim());
      } else if (filterType === "palpite") {
        return bet.cotas.some((cota) =>
          cota.cotasId.toString().includes(search.trim())
        );
      }
      return false;
    });

    setFilteredBets(filtered);
  }, [search, filterType, bets]);

  const getStatusText = (status: string) => {
    if (status === "Aberta") {
      return "Vendas Abertas";
    }
    if (status === "Finalizada") {
      return "Finalizado";
    }
    return status;
  };
  const getStatusColor = (status: string) => {
    switch (status) {
      case "Aberta":
        return "#068C1C";
      case "Em Andamento":
        return "#FFDF01";
      case "Finalizada":
        return "#DB0101";
      default:
        return "gray";
    }
  };

  const getStatus = (status: string) => {
    switch (status) {
      case "Aberta":
        return "white";
      case "Em Andamento":
        return "#068D16";
      case "Finalizada":
        return "white";
      default:
        return "black";
    }
  };

  const formatNumber = (number: number): string => {
    return number.toString().padStart(4, "0");
  };

  return (
    <Stack spacing={4} p="4" mx="auto" maxW="100%">
      <Heading textAlign="center" color="white">
        Meus Palpites
      </Heading>
      <Text fontSize="18px" textAlign="center" color="white">
        Confira seus palpites abaixo
      </Text>
      <Text fontSize="18px" textAlign="center" color="white">
        Filtros de buscas por:
      </Text>
      <Center>
        <HStack
          spacing={4}
          w={["100%", "80%", "60%"]}
          alignItems="center"
          justifyContent="center"
        >
          <Select
            bg="white"
            value={filterType}
            onChange={(e) => {
              setFilterType(e.target.value);
              setSearch("");
            }}
          >
            <option value="bolao">Nº do Bolão</option>
            <option value="palpite">Nº do Palpite</option>
          </Select>

          <Input
            bg="white"
            placeholder={`Buscar por ${filterType}`}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </HStack>
      </Center>

      {filteredBets.length === 0 ? (
        <Box
          bg="white"
          borderRadius={5}
          mx="auto"
          width={{ base: "90%", md: "60%", lg: "70%" }}
        >
          <Text fontWeight="bold" p={4} textAlign="center">
            Sem dados disponíveis.
          </Text>
        </Box>
      ) : (
        filteredBets.map((bet) => (
          <Box
            key={bet.salaId}
            bg="white"
            borderRadius="5px"
            overflow="hidden"
            mb={4}
            position="relative"
          >
            <Box bg="#006391" py={2}>
              <Text color="white" textAlign="center" fontWeight="bold">
                Bolão - {bet.salaId.toString().padStart(4, "0")}
              </Text>
            </Box>
            <Box textAlign="center" my="3">
              <Box
                bg={getStatusColor(bet.statusSalaResponseDTO.descricao)}
                color={getStatus(bet.statusSalaResponseDTO.descricao)}
                fontWeight="bold"
                mx="auto"
                pr={1}
                pl={1}
                width="fit-content"
              >
                {getStatusText(bet.statusSalaResponseDTO.descricao)}{" "}
              </Box>
            </Box>
            <Box overflowX="auto" whiteSpace="nowrap">
              <Table variant="simple" maxW="100%">
                <Thead>
                  <Tr bg="#006391">
                    <Th textAlign="center" color="white">
                      Ranking
                    </Th>
                    <Th textAlign="center" color="white">
                      Número do Palpite
                    </Th>
                    <Th textAlign="center" color="white">
                      Dia / Número Concurso
                    </Th>
                    <Th textAlign="center" color="white">
                      Palpites
                    </Th>
                    <Th textAlign="center" color="white">
                      Pontuação por Concurso
                    </Th>
                    <Th textAlign="center" color="white">
                      Total de Pontos
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {bet.cotas.map((cota, index) => (
                    <Tr key={cota.cotasId}>
                      <Td textAlign="center" color="#006391" fontWeight="bold">
                        {bet.statusSalaResponseDTO.id === 1
                          ? "-"
                          : formatNumber(cota.rankingCota)}
                      </Td>
                      <Td textAlign="center" color="#006391" fontWeight="bold">
                        {formatNumber(cota.cotasId)}
                      </Td>
                      <Td textAlign="center" color="#006391" fontWeight="bold">
                        {cota.cotasConcursosVencedores.map((concurso, idx) => (
                          <Box key={idx} mb={2}>
                            <Text>
                              {concurso.diaConcurso}{" "}
                              <strong style={{ color: "black" }}>
                                {concurso.numeroConcurso}
                              </strong>
                            </Text>
                          </Box>
                        ))}
                      </Td>
                      <Td
                        textAlign="center"
                        whiteSpace="nowrap"
                        overflow="hidden"
                      >
                        {cota.cotasConcursosVencedores.map((concurso, idx) => (
                          <Box key={idx} mb={2}>
                            {formatNumbers({
                              numbers: concurso.numerosCotas,
                            })}
                          </Box>
                        ))}
                      </Td>
                      <Td textAlign="center" color="#006391" fontWeight="bold">
                        {cota.cotasConcursosVencedores.map((concurso, idx) => (
                          <Box key={idx} mb={2}>
                            {concurso.totalPontosConcurso}
                          </Box>
                        ))}
                      </Td>
                      <Td
                        textAlign="center"
                        bg="#006391"
                        color="white"
                        fontWeight="bold"
                      >
                        {cota.totalPontos}
                      </Td>
                    </Tr>
                  ))}
                  {bet.cotas.length === 0 && (
                    <Tr>
                      <Td colSpan={6} textAlign="center">
                        Nenhuma cota encontrada
                      </Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
            </Box>
          </Box>
        ))
      )}

      <FloatingWhatsAppButton />
    </Stack>
  );
};

export default Bets;
