import { Box, Heading, Text } from "@chakra-ui/react";

export const TermoConsentimento = () => {
  return (
    <Box
      bg="#FFFFFF"
      p={5}
      mt={4}
      mb={6}
      mx={{ base: 2, sm: "auto", md: "auto", lg: "auto" }}
      width={{ base: "95%", sm: "90%", md: "70%", lg: "50%" }}
      borderRadius="lg"
      boxShadow="md"
    >
      <Heading
        mb={5}
        fontSize={{ base: "xl", md: "2xl", lg: "3xl" }}
        textAlign="center"
      >
        Termo de Consentimento de Uso e Armazenamento de Dados e Cookies
      </Heading>
      <Text fontSize={{ base: "sm", md: "md", lg: "lg" }}>
        A LL Tech Soluções Digitais LTDA respeita e protege a privacidade de
        pessoas que utilizam nossos serviços;
      </Text>
      <Text fontSize={{ base: "sm", md: "md", lg: "lg" }}>
        Este documento estabelece os termos e condições para o uso e o
        armazenamento de dados pessoais e cookies (pequenos arquivos de texto)
        coletados durante a sua interação com o nosso site.{" "}
      </Text>
      <Text fontSize={{ base: "sm", md: "md", lg: "lg" }}>
        Por favor, leia atentamente e só continue a usar o nosso site se você
        concordar com os seguintes termos.
      </Text>
      <Text mb={2} mt={3} fontSize={{ base: "md", lg: "lg", xl: "xl" }}>
        <strong>1.</strong> Dados Pessoais Coletados
      </Text>
      <Text fontSize={{ base: "sm", md: "md", lg: "lg" }}>
        Durante a sua interação com o nosso site, podemos coletar e armazenar as
        seguintes informações, através da utilização de cookies, um suporte de
        dados que o identifica com usuário único:
      </Text>
      <Text fontSize={{ base: "sm", md: "md", lg: "lg" }}>
        Informações de identificação pessoal, como nome, endereço de e-mail,
        telefone, preferencias do usuário e outras informações que você nos
        forneça voluntariamente.
      </Text>
      <Text fontSize={{ base: "sm", md: "md", lg: "lg" }}>
        Informações de uso, como endereço IP, histórico de navegação, dados de
        localização e outros dados coletados automaticamente durante a sua
        visita.
      </Text>
      <Text mb={2} mt={3} fontSize={{ base: "md", lg: "lg", xl: "xl" }}>
        <strong>2.</strong> Finalidades da Coleta de Dados
      </Text>
      <Text fontSize={{ base: "sm", md: "md", lg: "lg" }}>
        Os dados pessoais e as informações coletadas são utilizados para as
        seguintes finalidades:
      </Text>
      <Text fontSize={{ base: "sm", md: "md", lg: "lg" }}>
        Melhorar a experiência do usuário no site.
      </Text>
      <Text fontSize={{ base: "sm", md: "md", lg: "lg" }}>
        Personalizar conteúdo e recomendações com base nas suas preferências.
      </Text>
      <Text fontSize={{ base: "sm", md: "md", lg: "lg" }}>
        Realizar análises estatísticas e pesquisas de mercado.{" "}
      </Text>
      <Text fontSize={{ base: "sm", md: "md", lg: "lg" }}>
        Enviar comunicações relacionadas aos nossos produtos e serviços, desde
        que você tenha dado o seu consentimento explícito para isso.{" "}
      </Text>
      <Text mb={2} mt={3} fontSize={{ base: "md", lg: "lg", xl: "xl" }}>
        <strong>3. </strong>Uso de Cookies
      </Text>

      <Text fontSize={{ base: "sm", md: "md", lg: "lg" }}>
        Nós usamos cookies para melhorar a sua experiência de navegação em nosso
        site.
      </Text>
      <Text fontSize={{ base: "sm", md: "md", lg: "lg" }}>
        Cookies são pequenos arquivos de texto que são armazenados no seu
        dispositivo e nos ajudam a entender como você utiliza o site.
      </Text>
      <Text fontSize={{ base: "sm", md: "md", lg: "lg" }}>
        Você pode gerenciar as configurações de cookies no seu navegador,
        recusando ou aceitando cookies conforme sua preferência.
      </Text>
      <Text fontSize={{ base: "sm", md: "md", lg: "lg" }}>
        A maioria dos navegadores estão previamente configurados para aceitar
        cookies.
      </Text>
      <Text fontSize={{ base: "sm", md: "md", lg: "lg" }}>
        Caso prefira, você pode reconfigurar seu navegador para rejeitar todos
        os cookies ou avisar quando um cookie est sendo enviado.{" "}
      </Text>

      <Text fontSize={{ base: "sm", md: "md", lg: "lg" }}>
        No entanto, que algumas partes do Serviço podem não funcionar
        corretamente, caso haja a rejeição pela utilização dos cookies.{" "}
      </Text>

      <Text mb={2} mt={3} fontSize={{ base: "md", lg: "lg", xl: "xl" }}>
        <strong>4.</strong> Compartilhamento de Dados
      </Text>
      <Text fontSize={{ base: "sm", md: "md", lg: "lg" }}>
        Nós não compartilharemos suas informações pessoais com terceiros, exceto
        quando exigido por lei ou com o seu consentimento expresso.
      </Text>

      <Text mb={2} mt={3} fontSize={{ base: "md", lg: "lg", xl: "xl" }}>
        <strong>5.</strong> Armazenamento e Segurança de Dados
      </Text>
      <Text fontSize={{ base: "sm", md: "md", lg: "lg" }}>
        Nós tomamos medidas razoáveis para proteger os seus dados pessoais
        contra acesso não autorizado, alteração, divulgação ou destruição, nos
        moldes da LGPD.
      </Text>

      <Text mb={2} mt={3} fontSize={{ base: "md", lg: "lg", xl: "xl" }}>
        <strong>6.</strong> Seu Consentimento
      </Text>
      <Text fontSize={{ base: "sm", md: "md", lg: "lg" }}>
        Ao continuar a usar nosso site, você consente com a coleta e o uso de
        seus dados pessoais de acordo com este termo.
      </Text>

      <Text mb={2} mt={3} fontSize={{ base: "md", lg: "lg", xl: "xl" }}>
        <strong>7.</strong> Direitos do Titular dos Dados
      </Text>
      <Text fontSize={{ base: "sm", md: "md", lg: "lg" }}>
        Você tem o direito de acessar, corrigir ou excluir seus dados pessoais a
        qualquer momento. Entre em contato conosco para exercer esses direitos.
      </Text>

      <Text mb={2} mt={3} fontSize={{ base: "md", lg: "lg", xl: "xl" }}>
        <strong>8.</strong> Alterações a este Termo de Consentimento
      </Text>
      <Text fontSize={{ base: "sm", md: "md", lg: "lg" }}>
        Reservamo-nos o direito de modificar este termo a qualquer momento.
        Quaisquer alterações serão comunicadas no site.
      </Text>

      <Text fontSize={{ base: "sm", md: "md", lg: "lg" }}>
        Ao usar o nosso site, você concorda com os termos deste Termo de
        Consentimento de Uso e Armazenamento de Dados e Cookies.
      </Text>
    </Box>
  );
};
