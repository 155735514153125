import { useEffect, useState } from "react";
import { Box, Text, Button, Heading } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import CustomModalAlert from "../components/signModal";
import { useAuth } from "../contexts/authContext";
import FloatingWhatsAppButton from "../components/floatingWhatsAppButton";
import { IRooms } from "../types/users";
import { http } from "../services/http";
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  format,
} from "date-fns";
import { ptBR } from "date-fns/locale";
import valor from "../assets/valor.png";

const prizeOrders = ["Aberta", "Em Andamento", "Finalizada"]

export const PrizeDraw = () => {
  const [filter, setFilter] = useState("all");
  const [salas, setSalas] = useState<IRooms[]>([]);
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();
  const [modalContent, setModalContent] = useState({
    message: "",
  });

  useEffect(() => {
    const fetchSalas = async () => {
      try {
        const response = await http.get("/salas/");
        const data = response.data;
        setSalas(data.conteudo);
      } catch (error) {
        console.error("Error fetching salas:", error);
      }
    };

    fetchSalas();
  }, []);

  const filteredSalas = salas.filter((sala) => {
    if (filter === "all") return sala.ativo === true;
    if (filter === "1")
      return sala.statusSala.descricao === "Aberta" && sala.ativo === true;
    if (filter === "2")
      return (
        sala.statusSala.descricao === "Em Andamento" && sala.ativo === true
      );
    if (filter === "3")
      return sala.statusSala.descricao === "Finalizada" && sala.ativo === true;
    return false;
  }).sort((sala, proxSala) => {
    return prizeOrders.indexOf(sala.statusSala.descricao) - prizeOrders.indexOf(proxSala.statusSala.descricao)
  });

  const handleButtonClick = (
    salaId: number,
    status: string,
    valorPalpite: number
  ) => {
    if (isAuthenticated) {
      const state = { salaId, status, valorPalpite };

      if (status === "Aberta") {
        navigate(`/buyShares`, { state });
      } else if (status === "Em Andamento" || status === "Finalizada") {
        navigate(`/rankings`, { state });
      }
    } else {
      setModalContent({
        message: "Faça login para prosseguir!",
      });
    }
  };

  const getStatusText = (status: string) => {
    if (status === "Aberta") {
      return "Vendas Abertas";
    }
    if (status === "Finalizada") {
      return "Finalizado";
    }
    return status;
  };

  const formatNumber = (num: number): string => {
    return num.toString().padStart(4, "0");
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case "Aberta":
        return "#068A1A";
      case "Em Andamento":
        return "#FFDF01";
      case "Finalizada":
        return "#DB0101";
      default:
        return "#006391";
    }
  };

  const getStatus = (status: string) => {
    switch (status) {
      case "Aberta":
        return "white";
      case "Em Andamento":
        return "#068C1C";
      case "Finalizada":
        return "white";
      default:
        return "black";
    }
  };

  const getFormattedDate = (
    dataInicio: string,
    dataFimVendas: string,
    dataFim: string | null,
    status: string
  ) => {
    const capitalizeFirstLetter = (str: string) =>
      str.charAt(0).toUpperCase() + str.slice(1);

    const dataInicioDate = new Date(dataInicio);
    const agora = new Date();
    const dataFimVendasDate = new Date(dataFimVendas);

    if (status === "Aberta") {
      const dias = differenceInDays(dataFimVendasDate, agora);
      const horas = differenceInHours(dataFimVendasDate, agora) % 24;
      const minutos = differenceInMinutes(dataFimVendasDate, agora) % 60;

      return (
        <Text>
          <Text as="span" color="#068A1A" fontWeight="bold">
            Começa em:
          </Text>{" "}
          <Text as="span" fontWeight="bold" color="black">
            <Text as="span">{dias}</Text>{" "}
            <Text as="span" color="#068A1A">
              dias
            </Text>{" "}
            <Text as="span">{horas}</Text>{" "}
            <Text as="span" color="#068A1A">
              horas
            </Text>{" "}
            <Text as="span">{minutos}</Text>{" "}
            <Text as="span" color="#068A1A">
              min
            </Text>
          </Text>
        </Text>
      );
    } else if (status === "Em Andamento") {
      return (
        <Text>
          <Text as="span" color="#068A1A" fontWeight="bold">
            Começou
          </Text>{" "}
          <Text fontWeight="bold" as="span" color="black">
            {capitalizeFirstLetter(
              format(dataFimVendasDate, "EEEE dd/MM/yyyy", {
                locale: ptBR,
              })
            )}
          </Text>
        </Text>
      );
    } else if (status === "Finalizada") {
      if (dataFim) {
        const dataFimDate = new Date(dataFim);
        return (
          <Text>
            <Text fontWeight="bold" as="span" color="#068A1A">
              Finalizou
            </Text>{" "}
            <Text fontWeight="bold" as="span" color="black">
              {capitalizeFirstLetter(
                format(dataFimDate, "EEEE dd/MM/yyyy", {
                  locale: ptBR,
                })
              )}
            </Text>
          </Text>
        );
      } else {
        return (
          <Text>
            <Text fontWeight="bold" as="span" color="#068A1A">
              Data de fim não disponível
            </Text>
          </Text>
        );
      }
    } else {
      return (
        <Text>
          <Text fontWeight="bold" as="span" color="#068A1A">
            Data não disponível
          </Text>
        </Text>
      );
    }
  };

  const getDateColor = (
    dataInicio: string,
    dataFimVendas: string,
    dataFim: string | null,
    status: string
  ) => {
    return (
      <Text as="span" color="black">
        {getFormattedDate(dataInicio, dataFimVendas, dataFim, status)}
      </Text>
    );
  };

  const getPremio = (premio: number) => {
    const formattedPremio = premio.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });

    const [currencySymbol, value] = formattedPremio.split(/\s+/);

    return (
      <Text fontSize="18px" fontWeight="bold">
        <Text as="span" color="#068A1A">
          {currencySymbol}
        </Text>{" "}
        <Text as="span" color="black">
          {value}
        </Text>{" "}
        <Text as="span" color="#006391">
          em Prêmios
        </Text>
      </Text>
    );
  };

  return (
    <Box
      mx="auto"
      maxWidth="1200px"
      width="100%"
      display="flex"
      flexDirection="column"
      alignItems="center"
      paddingX={{ base: "20px", md: "40px" }}
    >
      <Box mt={4}>
        <Heading
          mb={4}
          fontSize="4xl"
          fontWeight="bold"
          color="white"
          textAlign="center"
        >
          Concursos
        </Heading>
        <Text mb={7} textAlign="center" color="white">
          Seu sonho está mais próximo do que você imagina!
        </Text>

        <Box
          mb={10}
          textAlign="center"
          display={{ base: "block", md: "flex" }}
          justifyContent="center"
        >
          <Button
            variant={filter === "1" ? "solid" : "outline"}
            bg="white"
            colorScheme="gray"
            color="green"
            onClick={() => setFilter("1")}
            mr={2}
            mb={{ base: 2, md: 0 }}
            h={8}
          >
            Vendas Abertas
          </Button>
          <Button
            variant={filter === "2" ? "solid" : "outline"}
            bg="white"
            colorScheme="gray"
            color="green"
            onClick={() => setFilter("2")}
            mr={2}
            mb={{ base: 2, md: 0 }}
            h={8}
          >
            Em Andamento
          </Button>
          <Button
            variant={filter === "3" ? "solid" : "outline"}
            bg="white"
            colorScheme="gray"
            color="green"
            onClick={() => setFilter("3")}
            h={8}
            mb={{ base: 2, md: 0 }}
          >
            Finalizado
          </Button>
        </Box>

        <Box display="flex" flexWrap="wrap" justifyContent="center">
          {filteredSalas.length > 0 ? (
            filteredSalas.map((sala) => (
              <Box
                key={sala.salaId}
                borderStyle="solid"
                borderWidth="2px"
                borderColor="#068A1A"
                borderRadius="10px"
                mb={4}
                mx={2}
                maxWidth={{
                  base: "370px",
                  sm: "380px",
                  md: "370px",
                  lg: "380px",
                }}
                minWidth={{
                  base: "370px",
                  sm: "380px",
                  md: "370px",
                  lg: "380px",
                }}
                textAlign="center"
                color="#000000"
                backgroundColor="#FFFFFF"
                position="relative"
              >
                <Box
                  position="absolute"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  padding="8px 16px"
                  borderRadius="12px"
                  zIndex={1}
                  backgroundImage={valor}
                  backgroundSize="cover"
                  backgroundPosition="center"
                  width="110px"
                  height="90px"
                  pb={3}
                >
                  <Text
                    fontSize="12px"
                    fontWeight="bold"
                    color="#004DB2"
                    transform="rotate(-25deg)"
                  >
                    {sala.valorPalpite.toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </Text>
                </Box>

                <Text mb={2} fontSize="2xl" fontWeight="bold">
                  Bolão - {formatNumber(sala.salaId)}
                </Text>
                <Box
                  position="relative"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  mb={2}
                >
                  <Text
                    fontSize="xs"
                    fontWeight="bold"
                    py="1px"
                    px={2}
                    boxShadow=" grey 1px 1px 4px 2px"
                    color={getStatus(sala.statusSala.descricao)}
                    bgColor={getStatusColor(sala.statusSala.descricao)}
                  >
                    {getStatusText(sala.statusSala.descricao)}{" "}
                  </Text>
                </Box>

                <Text
                  position="relative"
                  fontSize="14px"
                  color="#016C30"
                  fontWeight="bold"
                  mb={2}
                >
                  {getDateColor(
                    sala.dataInicio,
                    sala.dataFimVendas,
                    sala.dataFim,
                    sala.statusSala.descricao
                  )}
                </Text>

                <Text
                  position="relative"
                  left={2}
                  fontSize="20px"
                  color="#000"
                  fontWeight="bold"
                  mb={2}
                >
                  {getPremio(sala.valorPremioTotal)}
                </Text>

                <Button
                  onClick={() =>
                    handleButtonClick(
                      sala.salaId,
                      sala.statusSala.descricao,
                      sala.valorPalpite
                    )
                  }
                  boxShadow=" grey 2px 2px 4px 2px"
                  h="25px"
                  fontSize="18px"
                  fontWeight="bold"
                  fontStyle="italic"
                  borderRadius="20px"
                  bg={
                    sala.statusSala.descricao === "Aberta"
                      ? "#068A1A"
                      : sala.statusSala.descricao === "Em Andamento"
                      ? "#006391"
                      : sala.statusSala.descricao === "Finalizada"
                      ? "#006391"
                      : "#A9A9A9"
                  }
                  color="white"
                  mb={2}
                >
                  {sala.statusSala.descricao === "Aberta"
                    ? "Compre já"
                    : sala.statusSala.descricao === "Em Andamento"
                    ? "Acompanhar"
                    : "Conferir"}
                </Button>
              </Box>
            ))
          ) : (
            <Text color="white">Nenhum concurso encontrado.</Text>
          )}
        </Box>
      </Box>
      <CustomModalAlert
        isOpen={!!modalContent.message}
        onClose={() => setModalContent({ message: "" })}
        message={modalContent.message}
      />
      <FloatingWhatsAppButton />
    </Box>
  );
};
