import { EPaymentMethodStatus } from "../../../types/invoicing/enum";

export const statusNormalizer = (status: string) => {
  switch (status) {
    case EPaymentMethodStatus.ACTIVE:
      return { colorScheme: "green", label: "Ativo" };
    case EPaymentMethodStatus.INACTIVE:
      return { colorScheme: "red", label: "Inativo" };
    default:
      return { colorScheme: "red", label: "Inativo" };
  }
};

