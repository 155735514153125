import {
  Box,
  CircularProgress,
  Container,
  Flex,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { http } from "../../services/http";
import { useAuth } from "../../contexts/authContext";

interface ResultadoMegaConcurso {
  concursoId: number;
  diaConcurso: string;
  numeroConcurso: number;
  numerosSorteados: string;
  ativo: boolean;
}

interface SalaResultadoMega {
  salaId: number;
  resultadoMegaConcurso: ResultadoMegaConcurso[];
}

interface ApiResponse {
  codigoHTTP: number;
  mensagens: any;
  conteudo: {
    salaResultadoMega: SalaResultadoMega[];
  }[];
}

export function AllConcursos() {
  const { token } = useAuth();
  const [resultados, setResultados] = useState<SalaResultadoMega[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    async function fetchResult() {
      try {
        const response = await http.get<ApiResponse>(
          "/resultado-mega/por-salas",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setResultados(response.data.conteudo[0].salaResultadoMega || []);
        setIsLoading(false);
      } catch (error) {
        console.error("Erro ao buscar concursos:", error);
        setError("Não foi possível carregar os resultados.");
        setIsLoading(false);
      }
    }
    fetchResult();
  }, [token]);

  const NumberBalls = ({ numbers }: { numbers: string }) => {
    const numbersArray = numbers.split(",");
    return (
      <Box display="flex" whiteSpace="nowrap" overflowX="auto">
        {numbersArray.map((number, index) => (
          <Box
            key={index}
            bg="#008000"
            color="white"
            borderRadius="50%"
            width="30px"
            height="30px"
            lineHeight="30px"
            marginRight="8px"
            display="inline-flex"
            justifyContent="center"
            alignItems="center"
          >
            {number}
          </Box>
        ))}
      </Box>
    );
  };

  return isLoading ? (
    <Flex flex="1" alignItems="center" justifyContent="center">
      <CircularProgress color="#16A34A" isIndeterminate />
    </Flex>
  ) : (
    <Container
      bg="white"
      borderRadius="md"
      boxShadow="xl"
      mx="auto"
      mt={10}
      p={6}
      mb="5%"
    >
      <Text fontSize="lg" fontWeight="bold" mb={4} textAlign="center">
        Concursos
      </Text>
      {error ? (
        <Text color="red.500">{error}</Text>
      ) : (
        <Box overflowX="auto">
          <Table variant="simple" margin="auto" width="fit-content">
            <Thead>
              <Tr>
                <Th textAlign="center">Bolão</Th>
                <Th textAlign="center">Número Concurso</Th>
                <Th textAlign="center">Números Sorteados</Th>
              </Tr>
            </Thead>
            <Tbody>
              {resultados.flatMap((sala) =>
                sala.resultadoMegaConcurso.map((resultado) => (
                  <Tr key={resultado.concursoId}>
                    <Td textAlign="center">{sala.salaId}</Td>
                    <Td textAlign="center">{resultado.numeroConcurso}</Td>
                    <Td textAlign="center">
                      <NumberBalls numbers={resultado.numerosSorteados} />
                    </Td>
                  </Tr>
                ))
              )}
            </Tbody>
          </Table>
        </Box>
      )}
    </Container>
  );
}
