import {
  Box,
  Button,
  Container,
  Input,
  Text,
  IconButton,
  Flex,
  Heading,
  Badge,
  useToast,
} from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { http } from "../../services/http";
import { useAuth } from "../../contexts/authContext";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { AxiosError } from "axios";

interface Concurso {
  numeroConcurso: number;
  diaConcurso: string;
  concursoId: number;
}

interface Award {
  numeroConcurso: number;
  concursoId: number;
  diaConcurso: string;
  premio: string;
  descricao: string;
}

export function AddAward() {
  const toast = useToast();
  const location = useLocation();
  const { salaId, concursos, statusSala } = location.state;
  const [awards, setAwards] = useState<Award[]>(
    concursos.map((concurso: Concurso) => ({
      numeroConcurso: concurso.numeroConcurso,
      diaConcurso: concurso.diaConcurso,
      concursoId: concurso.concursoId,
      premio: "",
      descricao: "",
    }))
  );

  const [premioPrincipal, setPremioPrincipal] = useState("");
  const [descricaoPrincipal, setDescricaoPrincipal] = useState("");
  const [premio0Acertos, setPremio0Acertos] = useState("");
  const [descricao0Acertos, setDescricao0Acertos] = useState("");
  const [topCompradores, setTopCompradores] = useState("");
  const [descricaoTopCompradores, setDescricaoTopCompradores] = useState("");
  const { token } = useAuth();
  const navigate = useNavigate();

  const handleInputChange = (
    index: number,
    field: keyof Award,
    value: string
  ) => {
    setAwards((prevAwards) =>
      prevAwards.map((award, i) =>
        i === index ? { ...award, [field]: value } : award
      )
    );
  };
  const formatCurrency = (value: string): string => {
    const numericValue = parseFloat(value.replace(/\D/g, "")) / 100;
    return numericValue.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
  };
  const handlePremioChange = (
    setter: React.Dispatch<React.SetStateAction<string>>,
    value: string
  ) => {
    const formattedValue = formatCurrency(value);
    setter(formattedValue);
  };
  const parseCurrencyToNumber = (value: string): number => {
    return parseFloat(value.replace(/[^\d]/g, "")) / 100;
  };
  const handleSave = async () => {
    try {
      const premios = [
        {
          descricao: descricaoPrincipal,
          valor: parseCurrencyToNumber(premioPrincipal),
          salaId,
          tipoPremio: 1,
        },
        {
          descricao: descricao0Acertos,
          valor: parseCurrencyToNumber(premio0Acertos),
          salaId,
          tipoPremio: 2,
        },
        {
          descricao: descricaoTopCompradores,
          valor: parseCurrencyToNumber(topCompradores),
          salaId,
          tipoPremio: 3,
        },
        ...awards.map((award) => ({
          descricao: award.descricao,
          valor: parseCurrencyToNumber(award.premio),
          salaId,
          concursoId: award.concursoId,
          tipoPremio: 4,
        })),
      ];

      await http.post("/premios", premios, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      toast({
        title: "Prêmio adicionado com sucesso.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      navigate("/award");
    } catch (error) {
      if (error instanceof AxiosError) {
        toast({
          title: "Erro ao salvar prêmio",
          description: error.response?.data.erros[0] || "Erro desconhecido.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Erro ao salvar prêmio",
          description: "Erro desconhecido.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
      console.error("Erro ao salvar prêmio:", error);
    }
  };

  const getStatusColor = (status: string): string => {
    switch (status) {
      case "Aberta":
        return "green";
      case "Em Andamento":
        return "yellow";
      case "Finalizada":
        return "red";
      default:
        return "gray";
    }
  };

  return (
    <Container
      bg="white"
      borderRadius="md"
      boxShadow="xl"
      mx="auto"
      mt={10}
      p={6}
      mb="5%"
    >
      <Box mb={4}>
        <Flex alignItems="center" mb={5}>
          <IconButton
            bg="#38A169"
            color="white"
            aria-label="Voltar"
            icon={<ArrowBackIcon />}
            variant="ghost"
            onClick={() => navigate(-1)}
          />
          <Heading as="h2" size="lg" ml={4}>
            Adicionar Prêmios
          </Heading>
        </Flex>
        <Box mb={4}>
          <Text mb={2}>Número do Bolão:</Text>
          <Input value={salaId} isReadOnly />
        </Box>

        <Box mb={4}>
          <Text>
            Status do Bolão:
            <Badge ml={2} colorScheme={getStatusColor(statusSala)}>
              {statusSala || "Status não disponível"}
            </Badge>
          </Text>
        </Box>

        <Box mb={6}>
          <Heading as="h3" size="md" mb={5} textAlign="center">
            Prêmio Principal
          </Heading>
          <Flex mb={4}>
            <Box flex="1" mr={2}>
              <Input
                placeholder="Valor do Prêmio"
                borderColor="gray.300"
                type="text"
                value={premioPrincipal}
                onChange={(e) =>
                  handlePremioChange(setPremioPrincipal, e.target.value)
                }
              />
            </Box>
            <Box flex="1" ml={2}>
              <Input
                placeholder="Descrição do Prêmio"
                borderColor="gray.300"
                type="text"
                value={descricaoPrincipal}
                onChange={(e) => setDescricaoPrincipal(e.target.value)}
              />
            </Box>
          </Flex>
        </Box>

        <Box mb={6}>
          <Heading as="h3" size="md" mb={5} textAlign="center">
            Prêmio Zero Acertos
          </Heading>
          <Flex mb={4}>
            <Box flex="1" mr={2}>
              <Input
                placeholder="Valor do Prêmio"
                borderColor="gray.300"
                type="text"
                value={premio0Acertos}
                onChange={(e) =>
                  handlePremioChange(setPremio0Acertos, e.target.value)
                }
              />
            </Box>
            <Box flex="1" ml={2}>
              <Input
                placeholder="Descrição do Prêmio"
                borderColor="gray.300"
                type="text"
                value={descricao0Acertos}
                onChange={(e) => setDescricao0Acertos(e.target.value)}
              />
            </Box>
          </Flex>
        </Box>

        <Box mb={6}>
          <Heading as="h3" size="md" mb={5} textAlign="center">
            Top Compradores
          </Heading>
          <Flex mb={4}>
            <Box flex="1" mr={2}>
              <Input
                placeholder="Valor do Prêmio"
                borderColor="gray.300"
                type="text"
                value={topCompradores}
                onChange={(e) =>
                  handlePremioChange(setTopCompradores, e.target.value)
                }
              />
            </Box>
            <Box flex="1" ml={2}>
              <Input
                placeholder="Descrição do Prêmio"
                borderColor="gray.300"
                type="text"
                value={descricaoTopCompradores}
                onChange={(e) => setDescricaoTopCompradores(e.target.value)}
              />
            </Box>
          </Flex>
        </Box>

        <Box mb={6}>
          <Heading as="h3" size="md" mb={5} textAlign="center">
            Prêmios por Concurso
          </Heading>
          {awards.map((award, index) => (
            <Box key={index} mb={4}>
              <Text mb={2} textAlign="center">
                Número do Concurso: {award.numeroConcurso}
              </Text>
              <Flex mb={4}>
                <Box flex="1" mr={2}>
                  <Input
                    placeholder="Valor do Prêmio"
                    borderColor="gray.300"
                    type="text"
                    value={award.premio}
                    onChange={(e) => {
                      const formattedValue = formatCurrency(e.target.value);
                      handleInputChange(index, "premio", formattedValue);
                    }}
                  />
                </Box>
                <Box flex="1" ml={2}>
                  <Input
                    placeholder="Descrição do Prêmio"
                    borderColor="gray.300"
                    type="text"
                    value={award.descricao}
                    onChange={(e) =>
                      handleInputChange(index, "descricao", e.target.value)
                    }
                  />
                </Box>
              </Flex>
            </Box>
          ))}
        </Box>

        <Flex justifyContent="center" mt={8}>
          <Button colorScheme="green" w="50%" onClick={handleSave}>
            Salvar
          </Button>
        </Flex>
      </Box>
    </Container>
  );
}
