import {
  CircularProgress,
  Container,
  Flex,
  Text,
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  IconButton,
  Heading,
} from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { http } from "../../services/http";
import { useAuth } from "../../contexts/authContext";

interface ResultadoMega {
  concursoId: number;
  numeros: string;
  salaId: number;
  ativo: boolean;
}

export function ViewSortie() {
  const location = useLocation();
  const navigate = useNavigate();
  const { token } = useAuth();

  const { numeroConcurso, concursoId } = location.state as {
    numeroConcurso: number;
    concursoId: number;
  };

  const [resultado, setResultado] = useState<ResultadoMega | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchResultados = async () => {
      try {
        const response = await http.get(`/resultado-mega/${concursoId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const resultadoEncontrado = response.data.conteudo;

        if (resultadoEncontrado.concursoId === concursoId) {
          setResultado(resultadoEncontrado);
        } else {
          setResultado(null);
        }

        setIsLoading(false);
      } catch (error) {
        console.error("Erro ao buscar resultados:", error);
        setError("Não foi possível carregar os números sorteados.");
        setIsLoading(false);
      }
    };

    fetchResultados();
  }, [numeroConcurso, concursoId, token]);

  return isLoading ? (
    <Flex flex="1" alignItems="center" justifyContent="center">
      <CircularProgress color="#16A34A" isIndeterminate />
    </Flex>
  ) : (
    <Container
      bg="white"
      borderRadius="md"
      boxShadow="xl"
      mx="auto"
      mt={10}
      p={6}
      mb="5%"
    >
      <Flex align="center" mb={4}>
        <IconButton
          bg="#38A169"
          color="white"
          aria-label="Voltar"
          icon={<ArrowBackIcon />}
          variant="ghost"
          onClick={() => navigate(-1)}
        />
        <Heading as="h2" size="lg" ml={4}>
          Números Sorteados
        </Heading>
      </Flex>

      <Box textAlign="center">
        <Text fontSize="lg" fontWeight="bold" mb={4}>
          Concurso: {numeroConcurso}
        </Text>
        {error ? (
          <Text color="red.500">{error}</Text>
        ) : resultado ? (
          <Box overflowX="auto">
            <Table variant="simple" margin="auto" width="fit-content">
              <Thead>
                <Tr>
                  <Th textAlign="center">Números Sorteados</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>
                    <Flex justify="center" wrap="wrap">
                      {resultado.numeros.split(",").map((numero, index) => (
                        <Box
                          key={index}
                          p={2}
                          m={1}
                          textAlign="center"
                          minWidth="fit-content"
                        >
                          {numero}
                        </Box>
                      ))}
                    </Flex>
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </Box>
        ) : (
          <Text>Não há números cadastrados para este concurso.</Text>
        )}
      </Box>
    </Container>
  );
}
