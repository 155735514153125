import {
  Box,
  Text,
  Input,
  Button,
  IconButton,
  InputGroup,
  InputRightElement,
  Heading,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { useNavigate, useLocation } from "react-router-dom";
import { http } from "../services/http";
import FloatingWhatsAppButton from "../components/floatingWhatsAppButton";

export const ChangePassword = () => {
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isConfirming, setIsConfirming] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();
  const userId = location.state?.userId;
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (isConfirming && password !== confirmPassword) {
      setErrorMessage("As senhas não correspondem.");
    } else {
      setErrorMessage("");
    }
  }, [password, confirmPassword, isConfirming]);

  const handleSubmit = async () => {
    if (password !== confirmPassword) {
      setErrorMessage("As senhas não correspondem.");
      return;
    }

    try {
      await http.put("/auth/recuperar-senha", { userId, senha: password });
      onOpen();
      setPassword("");
      setConfirmPassword("");
    } catch (error) {
      setErrorMessage("Erro ao alterar a senha. Tente novamente.");
    }
  };

  const handleSuccessClose = () => {
    onClose();
    navigate("/sign-in");
  };

  return (
    <Box
      bg="#E5E7EB"
      borderRadius="md"
      boxShadow="md"
      maxW={{ base: "90%", md: "md" }}
      mx="auto"
      mt={{ base: 10, md: 20 }}
      p={{ base: 3, md: 5 }}
      w="100%"
      marginTop="6%"
      marginBottom="6%"
    >
      <Heading mb={2} textAlign="center" fontWeight="bold" fontSize="2xl">
        Insira sua nova senha
      </Heading>
      <InputGroup size="md" mb={4}>
        <Input
          borderColor="#333333"
          pr="4.5rem"
          type={showPassword ? "text" : "password"}
          placeholder="Senha"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <InputRightElement width="4.5rem">
          <IconButton
            bg="transparent"
            h="1.75rem"
            size="sm"
            onClick={() => setShowPassword(!showPassword)}
            icon={showPassword ? <ViewOffIcon /> : <ViewIcon />}
            aria-label={showPassword ? "Hide password" : "Show password"}
          />
        </InputRightElement>
      </InputGroup>
      <InputGroup size="md" mb={4}>
        <Input
          borderColor="#333333"
          pr="4.5rem"
          type={showPassword ? "text" : "password"}
          placeholder="Confirmar Senha"
          value={confirmPassword}
          onChange={(e) => {
            setConfirmPassword(e.target.value);
            setIsConfirming(true);
          }}
        />
        <InputRightElement width="4.5rem">
          <IconButton
            bg="transparent"
            h="1.75rem"
            size="sm"
            onClick={() => setShowPassword(!showPassword)}
            icon={showPassword ? <ViewOffIcon /> : <ViewIcon />}
            aria-label={showPassword ? "Hide password" : "Show password"}
          />
        </InputRightElement>
      </InputGroup>
      {errorMessage && (
        <Text color="red.500" mb={4} fontSize="sm">
          {errorMessage}
        </Text>
      )}
      <Button
        colorScheme="green"
        mb={{ base: 5, md: 0 }}
        w="100%"
        bg="#16A34A"
        onClick={handleSubmit}
      >
        Alterar Senha
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Sucesso</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Senha alterada com sucesso!</Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="green" onClick={handleSuccessClose}>
              OK
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>{" "}
      <FloatingWhatsAppButton />
    </Box>
  );
};
