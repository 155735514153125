import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerOverlay,
  Flex,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { IconType } from "react-icons";
import { AuthenticatedNavMock } from "../../../navs/authAuthenticated";
import { UnauthenticatedNavMock } from "../../../navs/authUnauthenticated";
import { useAuth } from "../../../contexts/authContext";
import { AdminNavMock } from "../../../navs/adminAuthenticated";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

interface INav {
  label: string;
  icon?: IconType;
  path?: string;
  navs?: INav[];
}

const Sidebar: React.FC<IProps> = ({ isOpen, onClose }) => {
  const { isAuthenticated, signOut, user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {}, [isAuthenticated]);

  const handleLogout = async () => {
    await signOut();
    navigate("/");
  };

  const isAdmin = user && user.perfil && user.perfil.idPerfil === 1;

  const navItems = isAuthenticated
    ? isAdmin
      ? AdminNavMock
      : AuthenticatedNavMock
    : UnauthenticatedNavMock;

  return (
    <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerBody>
          <Flex
            width="full"
            height="full"
            alignItems="center"
            justifyContent="center"
          >
            <VStack width="full" spacing={0}>
              {navItems.map((navItem, index) => {
                if (navItem.navs) {
                  return (
                    <Accordion key={index} width="full" allowToggle>
                      <AccordionItem width="full" border="none">
                        <AccordionButton
                          paddingInline={5}
                          height="10"
                          _hover={{
                            backgroundColor: "blackAlpha.50",
                            color: "body.1",
                            cursor: "pointer",
                          }}
                        >
                          <HStack
                            key={index}
                            width="full"
                            height="10"
                            alignItems="center"
                            justifyContent="start"
                          >
                            {navItem.icon && <navItem.icon size={20} />}
                            <Text fontWeight="medium">{navItem.label}</Text>
                          </HStack>
                          <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4}>
                          {navItem.navs.map(
                            (subNavItem: INav, subIndex: number) => (
                              <HStack
                                key={subIndex}
                                width="full"
                                height="10"
                                paddingInline={5}
                                alignItems="center"
                                justifyContent="start"
                                _hover={{
                                  backgroundColor: "blackAlpha.50",
                                  color: "body.1",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  if (!subNavItem.path) return;
                                  navigate(subNavItem.path);
                                  onClose();
                                }}
                              >
                                {subNavItem.icon && (
                                  <subNavItem.icon size={20} />
                                )}
                                <Text fontWeight="medium">
                                  {subNavItem.label}
                                </Text>
                              </HStack>
                            )
                          )}
                        </AccordionPanel>
                      </AccordionItem>
                    </Accordion>
                  );
                } else {
                  return (
                    <HStack
                      key={index}
                      width="full"
                      height="10"
                      paddingInline={5}
                      alignItems="center"
                      justifyContent="start"
                      _hover={{
                        backgroundColor: "blackAlpha.50",
                        color: "body.1",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        if (!navItem.path) return;
                        navigate(navItem.path);
                        onClose();
                      }}
                    >
                      {navItem.icon && <navItem.icon size={20} />}
                      <Text fontWeight="medium">{navItem.label}</Text>
                    </HStack>
                  );
                }
              })}
            </VStack>
          </Flex>
        </DrawerBody>
        <DrawerFooter>
          <Button
            width="full"
            padding={5}
            border="1px dotted #DDD"
            variant="outline"
            onClick={handleLogout}
          >
            Sair
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default Sidebar;
