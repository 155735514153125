import { Input } from '@chakra-ui/react';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
interface PercentageInputProps {
  value: string;
  onChange: (value: string) => void;
  [key: string]: any; 
}
export const PercentageInput = ({ value, onChange, ...props }: PercentageInputProps) => {
  const [inputValue, setInputValue] = useState(value || '');
  const inputRef = useRef<HTMLInputElement>(null);
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!inputRef.current) return;

    const { value: newValue, selectionStart } = event.target;

    const numericValue = newValue.replace(/[^0-9]/g, '');

    const cursorPosition = selectionStart ?? 0;
    let adjustedPosition = cursorPosition;

    if (numericValue.length > 0 && cursorPosition > numericValue.length) {
      adjustedPosition = numericValue.length;
    }

    const numericNumber = parseFloat(numericValue);
    const clampedValue = Math.min(Math.max(numericNumber, 0), 100).toString()

    setInputValue(clampedValue);
    onChange(clampedValue);

    const formattedValue = numericValue ? `${clampedValue}%` : '';

    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.value = formattedValue;
        inputRef.current.setSelectionRange(adjustedPosition, adjustedPosition);
      }
    }, 0);
  };

  useEffect(() => {
    if(!value) return
    const numericValue = value.replace(/[^0-9]/g, '');

    const numericNumber = parseFloat(numericValue);
    const clampedValue = Math.min(Math.max(numericNumber, 0), 100).toString()

    setInputValue(clampedValue);
    onChange(clampedValue);

    const formattedValue = numericValue ? `${clampedValue}%` : '';

    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.value = formattedValue;
      }
    }, 0);
  }, [])

  const formattedValue = inputValue ? `${parseFloat(inputValue)}%` : '';

  return (
    <Input
      ref={inputRef}
      type="text"
      value={formattedValue}
      onChange={handleChange}
      {...props}
    />
  );
};
