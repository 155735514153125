export enum EPaths {
  HOME = "/",
  ROOMS = "/prizeDraw",
  WINNERS = "/winners",
  NUMBERS = "/numbersPage",
  CADASTRO = "/cadastroClientes",
  HELP = "/help",
  RANKING = "/rankings",
  RANKINGPERSONAL = "/rankingPersonal",
  DRAWINFORMATION = "/drawInformation",
  INVOICING = "/invoicing",
  ROOMSDASHBOARD = "/roomsDashboard",
  PRIZEDRAWDASHBOARD = "/sortie",
  CHECKOUTFORM = "/checkoutForm",
  MYNUMBERS = "/myNumbersPage",
  REGRAS = "/regras",
  BUYERS = "/buyers",
  AWARD = "/award",
  WHATSAPP = "/whatsapp",
  RULES = "/rulesDashboard",
  COUPON = "/cupom",
  ALLCONCURSOS = "/allConcursos",
  FINANCIAL = "/financeiro",
}
