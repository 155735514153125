import { CONTROL_PANEL } from "../../mocks/database/invoicing";
import { IInvoicing } from "../../types/invoicing/interfaces";

export const ControlPanelRepository = {
  findAll: async (): Promise<IInvoicing[]> => {
     return new Promise<IInvoicing[]>((resolve) => {
      setTimeout(() => {
        resolve(CONTROL_PANEL);
       }, 2000);
    });
  },
};