import { useState, useEffect } from "react";
import { Box, Text, Heading, Flex } from "@chakra-ui/react";
import { FaRegCircleDot } from "react-icons/fa6";
import FloatingWhatsAppButton from "../components/floatingWhatsAppButton";
import { http } from "../services/http";

interface Regra {
  regraId: number;
  descricao: string;
  legenda: string;
  ativo: boolean;
}

export const Regras = () => {
  const [regras, setRegras] = useState<Regra[]>([]);

  useEffect(() => {
    const fetchRegras = async () => {
      try {
        const response = await http.get("/regras/");
        setRegras(response.data.conteudo);
      } catch (error) {
        console.error("Erro ao buscar as regras:", error);
      }
    };

    fetchRegras();
  }, []);

  return (
    <Box p={{ base: 4, md: 8 }} maxW="container.md" mx="auto" textAlign="left">
      <Heading color="white" mb={5} textAlign="center">
        Regras
      </Heading>
      {regras.map((regra) => (
        <Flex key={regra.regraId} mb={4} align="center">
          <FaRegCircleDot size={24} color="white" style={{ flexShrink: 0 }} />
          <Text
            ml={2}
            color="white"
            fontWeight="bold"
            whiteSpace="normal"
            wordBreak="break-word"
            lineHeight="1.2"
          >
            {regra.legenda}: {regra.descricao}
          </Text>
        </Flex>
      ))}
      <FloatingWhatsAppButton />
    </Box>
  );
};
