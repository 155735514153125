import React from "react";
import { Route, Routes } from "react-router-dom";
import { AuthProvider, useAuth } from "../../contexts/authContext";
import { InvoicingProvider } from "../../contexts/invoicing";
import { RoomsProvider } from "../../contexts/rooms";
import { SortieProvider } from "../../contexts/sortie";
import { MainLayout } from "../../layouts/main";
import Buy from "../../pages/buyShares";
import { Invoicing } from "../../pages/dashboard/invoicing";
import { DrawInformation } from "../../pages/drawInformation";
import { Help } from "../../pages/help";
import PaymentScreen from "../../pages/payment";
import { PrizeDraw } from "../../pages/prizeDraw";
import Ranking from "../../pages/rankings";
import SignIn from "../../pages/sign-in";
import Winners from "../../pages/winners";
import { Sortie } from "../../pages/dashboard/sortie";
import { Rooms } from "../../pages/dashboard/roomsDashboard";
import { TermoConsentimento } from "../../components/termoConsentimento";
import { PrivacidadeDados } from "../../components/privacidadeDados";
import MyNumbersPage from "../../pages/myNumbersPage";
import Bets from "../../pages/bets";
import { Regras } from "../../pages/regras";
import CadastroClientes from "../../pages/dashboard/cadastroClientes";
import Buyers from "../../pages/dashboard/buyers";
import ViewShopping from "../../pages/dashboard/viewShopping";
import HistoricoCompras from "../../pages/dashboard/historicoCompras";
import { ForgotPassword } from "../../pages/forgotPassword";
import { CodeVerify } from "../../pages/codeVerify";
import { Register } from "../../pages/register";
import { ChangePassword } from "../../pages/changePassword";
import { RegisterScreen } from "../../pages/dashboard/register-rooms";
import { EditScreen } from "../../pages/dashboard/editScreen";
import { ViewRoom } from "../../pages/dashboard/viewRoom";
import HomePage from "../../pages/home";
import { Award } from "../../pages/dashboard/award";
import { WhatsAppScreen } from "../../pages/dashboard/whatsapp";
import { AddAward } from "../../pages/dashboard/addAward";
import { EditAward } from "../../pages/dashboard/editAward";
import { RulesDashboard } from "../../pages/dashboard/rulesDashboard";
import { AddRuleScreen } from "../../pages/dashboard/addRuleScreen";
import { ViewRuleScreen } from "../../pages/dashboard/viewRuleScreen";
import { EditRuleScreen } from "../../pages/dashboard/editRuleScreen";
import { Coupons } from "../../pages/dashboard/cupom";
import { AddCoupon } from "../../pages/dashboard/addCupom";
import { EditCoupon } from "../../pages/dashboard/editCupom";
import { ViewSortie } from "../../pages/dashboard/viewSortie";
import { ViewAwards } from "../../pages/dashboard/viewAward";
import { AllConcursos } from "../../pages/dashboard/allConcursos";
import ProtectedRoute from "../../privacy/routes";
import Financeiro from "../../pages/dashboard/financeiro";

const Authenticated: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<MainLayout />}>
        <Route
          path="/sign-in"
          element={
            <AuthProvider>
              <SignIn />
            </AuthProvider>
          }
        />
        <Route path="/bets" element={<Bets />} />
        <Route path="/termoConsentimento" element={<TermoConsentimento />} />
        <Route path="/privacidadeDados" element={<PrivacidadeDados />} />
        <Route path="/" element={<HomePage />} />
        <Route path="/help" element={<Help />} />
        <Route path="/prizeDraw" element={<PrizeDraw />} />
        <Route path="/regras" element={<Regras />} />
        <Route path="/drawInformation" element={<DrawInformation />} />
        <Route path="/winners" element={<Winners />} />
        <Route path="/rankings" element={<Ranking />} />
        <Route
          path="/register"
          element={
            <AuthProvider>
              <Register />
            </AuthProvider>
          }
        />
        <Route path="/myNumbersPage" element={<MyNumbersPage />} />
        <Route
          path="/invoicing"
          element={
            <InvoicingProvider>
              <Invoicing />
            </InvoicingProvider>
          }
        />
        <Route path="/forgotPassword" element={<ForgotPassword />} />
        <Route path="/codeVerify" element={<CodeVerify />} />
        <Route path="/changePassword" element={<ChangePassword />} />
        <Route
          path="/winners"
          element={
            <ProtectedRoute
              element={<Winners />}
              requiredPermission="Cliente"
            />
          }
        />
        {/* ROTAS ADMIN */}
        <Route
          path="/cupom"
          element={
            <ProtectedRoute element={<Coupons />} requiredPermission="Admin" />
          }
        />
        <Route
          path="/roomsDashboard"
          element={
            <ProtectedRoute
              element={
                <RoomsProvider>
                  <Rooms />
                </RoomsProvider>
              }
              requiredPermission="Admin"
            />
          }
        />
        <Route
          path="/addCupom"
          element={
            <ProtectedRoute
              element={<AddCoupon />}
              requiredPermission="Admin"
            />
          }
        />
        <Route
          path="/editCupom"
          element={
            <ProtectedRoute
              element={<EditCoupon />}
              requiredPermission="Admin"
            />
          }
        />
        <Route
          path="/viewSortie"
          element={
            <ProtectedRoute
              element={<ViewSortie />}
              requiredPermission="Admin"
            />
          }
        />
        <Route
          path="/viewAward"
          element={
            <ProtectedRoute
              element={<ViewAwards />}
              requiredPermission="Admin"
            />
          }
        />
        <Route
          path="/allConcursos"
          element={
            <ProtectedRoute
              element={<AllConcursos />}
              requiredPermission="Admin"
            />
          }
        />
        <Route
          path="/cadastroClientes"
          element={
            <ProtectedRoute
              element={<CadastroClientes />}
              requiredPermission="Admin"
            />
          }
        />
        <Route
          path="/historicoCompras"
          element={
            <ProtectedRoute
              element={<HistoricoCompras />}
              requiredPermission="Admin"
            />
          }
        />
        <Route
          path="/rulesDashboard"
          element={
            <ProtectedRoute
              element={<RulesDashboard />}
              requiredPermission="Admin"
            />
          }
        />{" "}
        <Route
          path="/addRuleScreen"
          element={
            <ProtectedRoute
              element={<AddRuleScreen />}
              requiredPermission="Admin"
            />
          }
        />
        <Route
          path="/editRuleScreen"
          element={
            <ProtectedRoute
              element={<EditRuleScreen />}
              requiredPermission="Admin"
            />
          }
        />
        <Route
          path="/viewRuleScreen"
          element={
            <ProtectedRoute
              element={<ViewRuleScreen />}
              requiredPermission="Admin"
            />
          }
        />{" "}
        <Route
          path="/register-rooms"
          element={
            <ProtectedRoute
              element={<RegisterScreen />}
              requiredPermission="Admin"
            />
          }
        />
        <Route
          path="/editScreen"
          element={
            <ProtectedRoute
              element={<EditScreen />}
              requiredPermission="Admin"
            />
          }
        />
        <Route
          path="/viewRoom"
          element={
            <ProtectedRoute element={<ViewRoom />} requiredPermission="Admin" />
          }
        />{" "}
        <Route
          path="/award"
          element={
            <ProtectedRoute element={<Award />} requiredPermission="Admin" />
          }
        />
        <Route
          path="/whatsapp"
          element={
            <ProtectedRoute
              element={<WhatsAppScreen />}
              requiredPermission="Admin"
            />
          }
        />
        <Route
          path="/addAward"
          element={
            <ProtectedRoute element={<AddAward />} requiredPermission="Admin" />
          }
        />
        <Route
          path="/editAward"
          element={
            <ProtectedRoute
              element={<EditAward />}
              requiredPermission="Admin"
            />
          }
        />
        <Route
          path="/buyers"
          element={
            <ProtectedRoute element={<Buyers />} requiredPermission="Admin" />
          }
        />
        <Route
          path="/viewShopping"
          element={
            <ProtectedRoute
              element={<ViewShopping />}
              requiredPermission="Admin"
            />
          }
        />
        <Route
          path="/sortie"
          element={
            <ProtectedRoute
              element={
                <SortieProvider>
                  <Sortie />
                </SortieProvider>
              }
              requiredPermission="Admin"
            />
          }
        />
        <Route
          path="/financeiro"
          element={
            <ProtectedRoute
              element={<Financeiro />}
              requiredPermission="Admin"
            />
          }
        />
        {/* ROTAS ADMIN / TERMINA AQUI */}
        <Route
          path="/buyShares"
          element={
            <ProtectedRoute element={<Buy />} requiredPermission="Cliente" />
          }
        />
        <Route
          path="/rankings"
          element={
            <ProtectedRoute
              element={<Ranking />}
              requiredPermission="Cliente"
            />
          }
        />
      </Route>

      <Route
        path="/payment"
        element={
          <ProtectedRoute
            element={<PaymentScreen />}
            requiredPermission="Cliente"
          />
        }
      />

      <Route path="/payment/:selectedQuotas" element={<PaymentScreen />} />
    </Routes>
  );
};
export default Authenticated;
