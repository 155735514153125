import { http } from "../../services/http";
import { IRegisterUser } from "../../types/register/interface";

export const UserRepository = {
  signIn: async (cpf: string, senha: string) => {
    try {
      const response = await http.post("/auth/login", { cpf, senha });
      return response.data.conteudo; // resposta campo conteudo
    } catch (error) {
      console.error("Erro ao fazer login:", error);
      throw error;
    }
  },

  signUp: async (userData: IRegisterUser) => {
    try {
      const response = await http.post("/auth/registrar", userData);
      return response.data; // resposta do campo token
    } catch (error) {
      console.error("Erro ao registrar usuário:", error);
      throw error;
    }
  },

  getUserByCpf: async (cpf: string, token: string) => {
    try {
      const response = await http.get(`/usuario/cpf/${cpf}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data.conteudo; // resposta campo conteudo
    } catch (error) {
      console.error("Erro ao buscar usuário por CPF:", error);
      throw error;
    }
  },
};

//   // logout: async () => {
//   //   try {
//   //     const response = await http.post("/logout");
//   //     return response.data;
//   //   } catch (error) {
//   //     console.error("Erro ao fazer logout:", error);
//   //     throw new Error("Erro ao fazer logout");
//   //   }
//   // },
// };
