import {
  Box,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Stack,
  IconButton,
} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { useState, useEffect } from "react";
import { http } from "../services/http";
import { useLocation } from "react-router-dom";
import { useAuth } from "../contexts/authContext";


interface RankingInfo {
  diaConcurso: string;
  numeroConcurso: number;
  totalPontosConcurso: number;
  numerosCotas: NumeroCota[];
}

type Bet = {
  dataCompra: string;
  premio: string;
  cotasConcursosVencedores: RankingInfo[];
  totalPontosPalpites: number;
  palpite: number;
  totalPontosConcurso: number;
};

interface NumeroCota {
  numero: number;
  numeroAcertado: boolean;
}

type ApiResponse = {
  salaId: number;
  cidade: string;
  nomeUsuario: string;
  palpiteVencedores: {
    cotasId: number;
    totalPontosPalpites: number;
    dataCompra: string;
    cotasConcursosVencedores: RankingInfo[];
  }[];
  premio: {
    premioId: number;
    descricao: string;
    valor: number;
    ativo: boolean;
    concursoId: number | null;
    tipoPremio: string | null;
  };
};

type FormatNumbersProps = {
  numbers: { numero: number; numeroAcertado: boolean }[];
};

const formatNumbers = ({ numbers }: FormatNumbersProps) => {
  if (!numbers || numbers.length === 0) {
    return <Text>Nenhum número encontrado</Text>;
  }

  return (
    <>
      {numbers.map((item, index) => (
        <Box
          key={index}
          bg={item.numeroAcertado ? "green" : "white"}
          color={item.numeroAcertado ? "white" : "green"}
          border="2px solid green"
          borderRadius="50%"
          width="28px"
          height="28px"
          lineHeight="28px"
          marginRight="5px"
          display="inline-flex"
          justifyContent="center"
          alignItems="center"
        >
          {item.numero}
        </Box>
      ))}
    </>
  );
};

const formatDate = (dateString: string): string => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

const Bets = () => {
  const [bets, setBets] = useState<Bet[]>([]);
  const [userInfo, setUserInfo] = useState<{
    nomeUsuario: string;
    cidade: string;
    salaId: number;
    premio: {
      descricao: string;
      valor: number;
    };
  } | null>(null);

  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const { userId, salaId, premioId, cotasId } = location.state;
  const { token } = useAuth();

  const formatPalpite = (palpite: number): string => {
    return palpite.toString().padStart(4, "0");
  };

  useEffect(() => {
    setIsLoading(true);
    http
      .get(`/ganhadores/${salaId}/${userId}/${premioId}/${cotasId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const data: ApiResponse = response.data.conteudo;
        if (
          !data ||
          !data.palpiteVencedores ||
          data.palpiteVencedores.length === 0
        ) {
          console.warn("Nenhum dado de palpite encontrado");
          setIsLoading(false);
          return;
        }

        setUserInfo({
          nomeUsuario: data.nomeUsuario,
          cidade: data.cidade,
          salaId: data.salaId,
          premio: data.premio,
        });

        const formattedBets: Bet[] = data.palpiteVencedores.map((item) => ({
          dataCompra: item.dataCompra,
          premio: data.premio.descricao,
          cotasConcursosVencedores: item.cotasConcursosVencedores || [],
          totalPontosPalpites: item.totalPontosPalpites,
          palpite: item.cotasId,
          totalPontosConcurso:
            item.cotasConcursosVencedores[0]?.totalPontosConcurso || 0,
        }));

        setBets(formattedBets);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Erro ao buscar os palpites:", error);
        setIsLoading(false);
      });
  }, [userId, salaId, premioId, token]);

  return (
    <Stack spacing={4} p="4" mx="auto" maxW="100%">
      <Box bg="white" borderRadius="5px" overflow="hidden" position="relative">
        <IconButton
          ml={2}
          icon={<ArrowBackIcon />}
          aria-label="Voltar"
          position="absolute"
          top={2}
          colorScheme="blue"
          onClick={() => window.history.back()}
        />

        <Box textAlign="center" mb="4" mt={2} pt={2}>
          {userInfo ? (
            <>
              <Text fontWeight="bold">Usuário: {formatPalpite(userId)}</Text>
              <Text fontWeight="bold">Nome: {userInfo.nomeUsuario}</Text>
              <Text fontWeight="bold">Cidade: {userInfo.cidade}</Text>
            </>
          ) : (
            <Text>Carregando informações do usuário...</Text>
          )}
        </Box>
        <Text
          color="#068C1C"
          textAlign="center"
          fontSize="lg"
          fontWeight="bold"
          mb={4}
        >
          Palpite Ganhador
        </Text>
        <Box
          bg="#006391"
          textAlign="center"
          color="white"
          fontWeight="bold"
          mb={2}
        >
          <Text mb={2}>Bolão: {formatPalpite(userInfo?.salaId ?? 0)}</Text>
        </Box>
        <Text textAlign="center" fontWeight="bold" color="#006391">
          {userInfo?.premio?.descricao ?? "Descrição indisponível"}
        </Text>

        <Box overflowX="scroll">
          <Table variant="simple" mt="4" maxW="100%" whiteSpace="nowrap">
            <Thead>
              <Tr>
                <Th textAlign="center">Palpite</Th>
                {/* <Th>Data</Th> */}
                <Th textAlign="center">
                  Dia / Número Concurso
                </Th>
                <Th textAlign="center">Números</Th>
                <Th textAlign="center">
                  Pontuação por Concurso
                </Th>
                <Th textAlign="center">Total</Th>
              </Tr>
            </Thead>
            <Tbody>
              {bets.map((bet, index) => (
                <Tr key={index}>
                  <Td textAlign="center" color="#068C1C" fontWeight="bold">
                    {formatPalpite(bet.palpite)}
                  </Td>
                  {/* <Td>{bet.dataCompra}</Td> */}
                  <Td
                    textAlign="center"
                    color="#006391"
                    fontWeight="bold"
                  >
                    {bet.cotasConcursosVencedores.map(
                      (detalhe, idx) => (
                        <Box
                          key={idx}
                          mb={2}>
                          {detalhe.diaConcurso}{" "}
                          <strong style={{ color: "black" }}>
                            {detalhe.numeroConcurso}
                          </strong>
                        </Box>
                      )
                    )}
                  </Td>
                  <Td
                    textAlign="center"
                    color="#006391"
                    fontWeight="bold"
                  >
                    {bet.cotasConcursosVencedores.map(
                      (detalhe, idx) => (
                        <Box key={idx} mb={2}>
                          {formatNumbers({
                            numbers: detalhe.numerosCotas,
                          })}
                        </Box>
                      )
                    )}
                  </Td>
                  <Td
                    textAlign="center"
                    color="#006391"
                    fontWeight="bold"
                  >
                    {bet.cotasConcursosVencedores.map(
                      (detalhe, idx) => (
                        <Box key={idx} mb={2}>
                          {detalhe.totalPontosConcurso}
                        </Box>
                      )
                    )}
                  </Td>
                  <Td
                    textAlign="center"
                    bg="#006391"
                    color="white"
                    fontWeight="bold"
                  >
                    {bet.cotasConcursosVencedores.reduce((prev, {totalPontosConcurso}) => prev + totalPontosConcurso, 0)}
                  </Td>
                </Tr>
              ))}
              {bets.length === 0 && (
                <Tr>
                  <Td colSpan={5} textAlign="center">
                    Nenhum dado encontrado
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </Box>
        <Box textAlign="center" mt="4">
          {bets.length > 0 && (
            <>
              <Text fontWeight="bold">
                Data da Compra: {formatDate(bets[0].dataCompra)}
              </Text>
              <Text color="#006391" fontWeight="bold" pb={5}>
                Prêmio: R${" "}
                {userInfo?.premio.valor.toLocaleString("pt-BR", {
                  minimumFractionDigits: 2,
                })}
              </Text>
            </>
          )}
        </Box>
      </Box>
    </Stack>
  );
};

export default Bets;
