import { IconType } from "react-icons";
import { RiHome3Line } from "react-icons/ri";
import { EPaths } from "./paths";
import { GiPodiumWinner } from "react-icons/gi";
import { IoMdHelpCircleOutline } from "react-icons/io";
import { BsAward } from "react-icons/bs";
import { FaClover  } from "react-icons/fa6";
import { FaCheck } from "react-icons/fa6";

interface INav {
  label: string;
  path?: string;
  icon: IconType;
  navs?: INav[];
}

export const UnauthenticatedNavMock: INav[] = [
  {
    label: "Página Inicial",
    path: EPaths.HOME,
    icon: RiHome3Line,
  },

  {
    label: "Concursos",
    path: EPaths.ROOMS,
    icon: BsAward,
  },
  {
    label: "Ganhadores",
    path: EPaths.WINNERS,
    icon: GiPodiumWinner,
  },
  {
    label: "Regras",
    path: EPaths.REGRAS,
    icon: FaCheck,
  },
  {
    label: "Sorteios da Mega Sena",
    path: EPaths.DRAWINFORMATION,
    icon: FaClover,
  },

  {
    label: "Ajuda",
    path: EPaths.HELP,
    icon: IoMdHelpCircleOutline,
  },
];
