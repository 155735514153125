import { useState, useEffect } from "react";
import {
  Box,
  Text,
  Flex,
  HStack,
  Container,
  CircularProgress,
  Input,
  Select,
  Stack,
  IconButton,
  VStack,
} from "@chakra-ui/react";
import { IColumn } from "../../types/table";
import { Table } from "../../components/table";
import { ArrowLeftIcon, ArrowRightIcon, ViewIcon } from "@chakra-ui/icons";
import { http } from "../../services/http";
import { useAuth } from "../../contexts/authContext";
import { useNavigate } from "react-router-dom";

interface Cadastro {
  id: number;
  nome: string;
  cpf: string;
  telefone: string;
  email: string;
  endereco?: {
    logradouro: string;
    numero: string;
    complemento?: string;
    bairro: string;
    cep: string;
    cidade: string;
    estado: string;
  };
}

const CadastroClientes = () => {
  const [cadastro, setCadastro] = useState<Cadastro[]>([]);
  const [filteredCadastro, setFilteredCadastro] = useState<Cadastro[]>([]);
  const [filterType, setFilterType] = useState<string>("");
  const [filterValue, setFilterValue] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 4;
  const { token } = useAuth();
  const navigate = useNavigate();

  const totalPages = Math.ceil(filteredCadastro.length / itemsPerPage);

  useEffect(() => {
    const fetchCadastro = async () => {
      setIsLoading(true);
      try {
        const response = await http.get("/usuario/", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = response.data.conteudo.map((user: any) => ({
          id: user.userId,
          nome: user.nome,
          cpf: user.cpf,
          telefone: user.telefone,
          email: user.email,
          endereco: user.endereco,
        }));

        setCadastro(data);
        setFilteredCadastro(data);
      } catch (error) {
        console.error("Erro ao buscar os dados:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCadastro();
  }, [token]);

  useEffect(() => {
    if (filterType && filterValue) {
      const filteredData = cadastro.filter((user) => {
        switch (filterType) {
          case "id":
            return user.id.toString().includes(filterValue);
          case "nome":
            return user.nome.toLowerCase().includes(filterValue.toLowerCase());
          case "cpf":
            return user.cpf.includes(filterValue);
          case "telefone":
            return user.telefone.includes(filterValue);
          default:
            return true;
        }
      });
      setFilteredCadastro(filteredData);
    } else {
      setFilteredCadastro(cadastro);
    }
  }, [filterType, filterValue, cadastro]);

  const columns: IColumn<Cadastro>[] = [
    {
      label: " Id",
      render: (cadastro) => {
        const formattedSalaId = cadastro.id.toString().padStart(4, "0");
        return (
          <Text color="#006391" fontWeight="bold">
            {formattedSalaId}
          </Text>
        );
      },
      width: 15,
    },

    {
      label: "Nome",
      render: (cadastro) => (
        <Text color="#006391" fontWeight="bold">
          {cadastro.nome}
        </Text>
      ),
      width: 20,
    },
    {
      label: "CPF",
      render: (cadastro) => (
        <Text color="#006391" fontWeight="bold">
          {cadastro.cpf}
        </Text>
      ),
      width: 20,
    },
    {
      label: "E-Mail",
      render: (cadastro) => (
        <Text color="#006391" fontWeight="bold">
          {cadastro.email}
        </Text>
      ),
      width: 20,
    },
    {
      label: "Telefone",
      render: (cadastro) => (
        <Text color="#006391" fontWeight="bold">
          {cadastro.telefone}
        </Text>
      ),
      width: 20,
    },
    {
      label: "Cidade",
      render: (cadastro) => (
        <Text color="#006391" fontWeight="bold">
          {cadastro.endereco?.cidade || "Indisponível"}
        </Text>
      ),
      width: 20,
    },

    {
      label: "Histórico Compras",
      render: (cadastro) => (
        <Box
          onClick={() =>
            navigate("/historicoCompras", {
              state: { userId: cadastro.id },
            })
          }
          _hover={{ cursor: "pointer", textDecoration: "underline" }}
        >
          <VStack>
            <IconButton
              size="10"
              aria-label="Ver Compras"
              icon={<ViewIcon />}
              colorScheme="blue"
              variant="outline"
              style={{
                padding: 0,
                margin: 0,
                background: "none",
                border: "none",
              }}
            />
            <Text textDecoration="underline">Ver Histórico de Compras</Text>
          </VStack>
        </Box>
      ),
      width: 25,
    },
  ];

  return isLoading ? (
    <Flex flex="1" alignItems="center" justifyContent="center">
      <CircularProgress color="yellow" isIndeterminate />
    </Flex>
  ) : (
    <Flex flex="1" flexDirection="column" paddingY={5}>
      <Container
        maxWidth={["100vw", "90vw"]}
        display="flex"
        flexDirection="column"
        gap={4}
        fontSize="12px"
        borderRadius="5px"
      >
        <HStack justifyContent="center" alignItems="center">
          <Text
            fontSize="5xl"
            fontWeight="bold"
            color="white"
            textAlign="center"
            mb={4}
          >
            Cadastro de Clientes <br />
          </Text>
        </HStack>

        <Box>
          <Text
            fontSize="20px"
            color="white"
            fontWeight="bold"
            textAlign="center"
            mb={3}
          >
            Buscar por:
          </Text>
          <Stack direction="row" spacing={2} justify="center">
            <Select
              placeholder="Selecione o Filtro"
              bg="white"
              value={filterType}
              onChange={(e) => {
                setFilterType(e.target.value);
                setFilterValue("");
              }}
              maxW="200px"
            >
              <option value="id">ID Usuário</option>
              <option value="nome">Nome</option>
              <option value="cpf">CPF</option>
              <option value="telefone">Telefone</option>
            </Select>
            {filterType && (
              <Input
                placeholder="Digite o valor"
                bg="white"
                value={filterValue}
                onChange={(e) => setFilterValue(e.target.value)}
                maxW="200px"
              />
            )}
          </Stack>
        </Box>

        <Flex borderRadius="5px" overflow="hidden" width="full" mt={4} flexDirection="column">
          <Table<Cadastro>  columns={columns} rows={filteredCadastro} />
        </Flex>
      </Container>
    </Flex>
  );
};

export default CadastroClientes;
