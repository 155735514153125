export const methodNormalizer = (method: string) => {
    switch (method) {
        case "pix": 
            return "PIX";
        case "card": 
            return "CARTÃO";
        case "cash": 
            return "DINHEIRO";
        case "bank-slip":  
            return "BOLETO BANCÁRIO";
        default: 
            return "PIX";
    }

}
