import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  Text,
  ModalBody,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

interface CustomModalProps {
  isOpen: boolean;
  onClose: () => void;
  message: string;
}

const CustomModalAlert: React.FC<CustomModalProps> = ({
  isOpen,
  onClose,
  message,
}) => {
  const navigate = useNavigate();

  const handleClose = () => {
    onClose();
    navigate("/sign-in");
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent
        maxW={{ base: "80%", md: "30%" }}
        borderRadius="md"
        boxShadow="lg"
      >
        <ModalBody textAlign="center" py={8}>
          <Text>{message}</Text>
          <Button onClick={handleClose} mt={4} colorScheme="green">
            Ok
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CustomModalAlert;
