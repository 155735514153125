import { useState, useEffect } from "react";
import {
  Box,
  Text,
  Flex,
  HStack,
  IconButton,
  Container,
  CircularProgress,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Input,
  Select,
  Stack,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { ViewIcon } from "@chakra-ui/icons";
import trofeu from "../assets/trofeu.png";
import { IColumn } from "../types/table";
import { Table } from "../components/table";
import FloatingWhatsAppButton from "../components/floatingWhatsAppButton";
import { useAuth } from "../contexts/authContext";
import { http } from "../services/http";
import { useNavigate } from "react-router-dom";

const filterTypeAlias = {
  premiacao: "premiação",
  nome: "nome",
  bolao: "bolão",
}

interface Premio {
  premioId: number;
  descricao: string;
  valor: number;
  salaId: number | null;
  concursoId: number | null;
  tipoPremio: string | null;
  ativo: boolean;
}

interface Winner {
  ganhadoresId: number | null;
  cotasId: number;
  salaId: number;
  userId: number;
  premio: Premio;
  totalPontos: number;
  nomeUsuario: string;
  cidade: string;
  ativo: boolean;
}

const Winners = () => {
  const [winners, setWinners] = useState<Winner[]>([]);
  const [filteredWinners, setFilteredWinners] = useState<Winner[]>([]);
  const [selectedWinner, setSelectedWinner] = useState<Winner | null>(null);
  const [search, setSearch] = useState("");
  const [filterType, setFilterType] = useState("nome");
  const [sortConfig, setSortConfig] = useState({
    field: "",
    direction: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { token } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);

    http
      .get("/ganhadores/", {
        headers: {
          Authorization: `${token}`,
        },
      })
      .then((response) => {
        const data: Winner[] = response.data.conteudo;
        const filteredData = data.filter((winner) => winner.ativo);

        setWinners(filteredData);
        setFilteredWinners(filteredData);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching winners:", error);
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    if (!search) {
      setFilteredWinners(winners);
      return;
    }

    const filtered = winners.filter((winner) => {
      if (filterType === "bolao") {
        return winner.salaId?.toString().includes(search);
      } else if (filterType === "nome") {
        return winner.nomeUsuario.toLowerCase().includes(search.toLowerCase());
      }
      else if (filterType === "premiacao") {
        return winner.premio.descricao.toLowerCase().includes(search.toLowerCase());
      }
      return false;
    });

    setFilteredWinners(filtered);
  }, [search, filterType, winners]);

  const handleSort = (field: keyof Winner, direction: "asc" | "desc") => {
    if (Array.isArray(filteredWinners)) {
      const sorted = [...filteredWinners].sort((a, b) => {
        const valueA = a[field];
        const valueB = b[field];

        if (typeof valueA === "string" && typeof valueB === "string") {
          return direction === "asc"
            ? valueA.localeCompare(valueB)
            : valueB.localeCompare(valueA);
        } else if (typeof valueA === "number" && typeof valueB === "number") {
          return direction === "asc" ? valueA - valueB : valueB - valueA;
        } else if (valueA === null || valueB === null) {
          return valueA === null ? -1 : 1;
        } else {
          return 0;
        }
      });
      setFilteredWinners(sorted);
      setSortConfig({ field, direction });
    }
  };

  const handleViewPalpite = (winner: Winner) => {
    setSelectedWinner(winner);
    onOpen();
  };

  const columns: IColumn<Winner>[] = [
    {
      label: "Bolão",
      render: (rooms) => {
        const formattedSalaId = rooms.salaId.toString().padStart(4, "0");
        return (
          <Text color="#006391" fontWeight="bold">
            {formattedSalaId}
          </Text>
        );
      },
      width: 15,
    },

    {
      label: "Nome",
      render: (winner) => (
        <Text color="#006391" fontWeight="bold">
          {winner.nomeUsuario}
        </Text>
      ),
      width: 25,
    },
    {
      label: "Cidade",
      render: (winner) => (
        <Text color="#006391" fontWeight="bold">
          {winner.cidade}
        </Text>
      ),
      width: 25,
    },
    {
      label: "Premiação",
      render: (winner) => (
        <Text color="#006391" fontWeight="bold">
          {winner.premio.descricao}
        </Text>
      ),
      width: 25,
    },
    {
      label: "Prêmio",
      render: (winner) => (
        <Text color="#006391" fontWeight="bold">
          {winner.premio.valor.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          })}
        </Text>
      ),
      width: 25,
    },
    {
      label: "Total de Pontos",
      render: (winner) => (
        <Text bg="#006391" color="white" fontWeight="bold">
          {winner.totalPontos}
        </Text>
      ),
      width: 25,
    },

    {
      label: "Palpite",
      render: (cadastro) => (
        <Box
          onClick={() =>
            navigate("/bets", {
              state: {
                userId: cadastro.userId,
                salaId: cadastro.salaId,
                premioId: cadastro.premio.premioId,
                cotasId: cadastro.cotasId,
              },
            })
          }
          _hover={{ cursor: "pointer", textDecoration: "underline" }}
        >
          <VStack>
            <IconButton
              size="10"
              aria-label="Ver Palpite"
              icon={<ViewIcon />}
              colorScheme="blue"
              variant="outline"
              style={{
                padding: 0,
                margin: 0,
                background: "none",
                border: "none",
              }}
            />
            <Text
              color="#006391"
              fontWeight="bold"
              fontSize="12px"
              textDecoration="underline"
            >
              Ver Palpite
            </Text>
          </VStack>
        </Box>
      ),
      width: 25,
    },
  ];

  return isLoading ? (
    <Flex flex="1" alignItems="center" justifyContent="center">
      <CircularProgress color="yellow" isIndeterminate />
    </Flex>
  ) : (
    <Flex flex="1" flexDirection="column" paddingY={5}>
      <Container
        maxWidth={["100vw", "80vw"]}
        display="flex"
        flexDirection="column"
        gap={10}
      >
        <HStack justifyContent="center" alignItems="center">
          <Text
            fontSize="4xl"
            fontWeight="bold"
            color="white"
            textAlign="center"
          >
            Ganhadores
          </Text>

          <img src={trofeu} alt="Trofeu" style={{ maxWidth: "50px" }} />
        </HStack>

        <Box>
          <Text color="white" fontWeight="bold" textAlign="center" mb={3}>
            Filtros de Busca por:
          </Text>
          <HStack spacing={4} justify="center" mb={4}>
            <Select
              bg="white"
              value={filterType}
              onChange={(e) => {
                setFilterType(e.target.value);
                setSearch("");
              }}
              maxW="200px"
            >
              <option value="bolao">Nº do Bolão</option>
              <option value="nome">Nome</option>
              <option value="premiacao">Premiação</option>
            </Select>

            {filterType === 'premiacao' ? (
              <Select
                bg="white"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                maxW="200px"
              >
                <option value="">Todos</option>
                <option value="Prêmio Principal">Prêmio Principal</option>
                <option value="Zero Acertos no Bolão">Zero Acertos no Bolão</option>
                <option value="Mais Acertos na Terça">Mais Acertos na Terça</option>
                <option value="Mais Acertos na Quinta">Mais Acertos na Quinta</option>
                <option value="Mais Acertos no Sábado">Mais Acertos no Sábado</option>
              </Select>) : (
              <Input
                placeholder={`Buscar por ${filterTypeAlias[filterType as keyof typeof filterTypeAlias]}`}
                bg="white"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                maxW="200px"
              />)}


          </HStack>

          <Stack direction="row" spacing={4} justify="center">
            <Select
              bg="white"
              color="gray"
              placeholder="Organizar Nome"
              onChange={(e) =>
                handleSort("nomeUsuario", e.target.value as "asc" | "desc")
              }
              maxW="200px"
            >
              <option value="asc">A/Z</option>
              <option value="desc">Z/A</option>
            </Select>
            <Select
              bg="white"
              color="gray"
              placeholder="Total de Pontos"
              onChange={(e) =>
                handleSort("totalPontos", e.target.value as "asc" | "desc")
              }
              maxW="200px"
            >
              <option value="desc">Maiores Pontuações</option>
              <option value="asc">Menores Pontuações</option>
            </Select>
          </Stack>
        </Box>

        <Flex width="full" borderRadius="5px" overflow="hidden">
          <Table<Winner> columns={columns} rows={filteredWinners} />
        </Flex>
      </Container>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Detalhes do Ganhador</ModalHeader>
          <ModalCloseButton />
          <ModalContent p={5}>
            {selectedWinner && (
              <>
                <Text fontSize="lg" fontWeight="bold">
                  Nome: {selectedWinner.nomeUsuario}
                </Text>
                <Text fontSize="lg">Cidade: {selectedWinner.cidade}</Text>
                <Text fontSize="lg" fontWeight="bold">
                  Sala ID: {selectedWinner.salaId}
                </Text>
                <Text fontSize="lg">
                  Total de Pontos: {selectedWinner.totalPontos}
                </Text>
                <Text fontSize="lg" fontWeight="bold" mt={4}>
                  Prêmio:
                </Text>
                <Text fontSize="lg">
                  Descrição: {selectedWinner.premio.descricao}
                </Text>
                <Text fontSize="lg">Valor: {selectedWinner.premio.valor}</Text>
              </>
            )}
          </ModalContent>
        </ModalContent>
      </Modal>

      <FloatingWhatsAppButton />
    </Flex>
  );
};

export default Winners;
