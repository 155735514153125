import { ArrowBackIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  IconButton,
  Input,
 
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/authContext";
import { http } from "../../services/http";

export function AddRuleScreen() {
  const [description, setDescription] = useState("");
  const [legend, setLegend] = useState("");
  const navigate = useNavigate();
  const toast = useToast();
  const { token } = useAuth();

  const handleAddRule = () => {
    const payload = [
      {
        descricao: description,
        legenda: legend,
      },
    ];

    http
      .post(`/regras`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then(() => {
        toast({
          title: "Regra adicionada com sucesso.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        navigate("/rulesDashboard");
      })
      .catch((error) => {
        console.error("Erro ao adicionar regra:", error);
        toast({
          title: "Erro ao adicionar regra.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  };

  return (
    <Container
      bg="white"
      borderRadius="md"
      boxShadow="xl"
      mx="auto"
      mt={10}
      p={6}
      mb="5%"
    >
      <Flex align="center" mb={4}>
        <IconButton
          bg="#38A169"
          color="white"
          aria-label="Voltar"
          icon={<ArrowBackIcon />}
          variant="ghost"
          onClick={() => navigate(-1)}
        />
        <Heading as="h2" size="lg" ml={4}>
          Adicionar Nova Regra
        </Heading>
      </Flex>
      <Box mb={4}>
        <FormControl>
          <FormLabel>Título</FormLabel>
          <Input
            maxLength={500}
            value={legend}
            onChange={(e) => setLegend(e.target.value)}
          />
        </FormControl>
        <FormControl mb={4}>
          <FormLabel mt={4}>Descrição</FormLabel>
          <Textarea
            maxLength={500}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Digite a descrição aqui..."
            minHeight="150px"
          />
        </FormControl>
      </Box>
      <Flex justifyContent="center" mt={7}>
        <Button colorScheme="green" w="50%" onClick={handleAddRule}>
          Salvar
        </Button>
      </Flex>
    </Container>
  );
}
