import { Flex, Text } from "@chakra-ui/react";

export function Footer() {
  return (
    <Flex
      bg="white"
      alignItems="center"
      justifyContent="center"
      width="full"
      height="80px"
      flexDirection="column"
      padding={5}
    >
      <Text fontWeight="semibold" fontSize={["sm", "xs"]} textAlign="center">
        © 2024 Bolão dos 10. Todos os direitos reservados.
      </Text>

      <Text fontWeight="semibold" fontSize={["sm", "xs"]} textAlign="center">
        LL Tech Soluções Digitais LTDA
      </Text>

      <Text fontWeight="semibold" fontSize={["sm", "xs"]} textAlign="center">
        CNPJ: 54.739.728/0001-69
      </Text>
    </Flex>
  );
}
