import { useEffect, useState } from "react";
import {
  Box,
  Text,
  Grid,
  GridItem,
  Flex,
  Stack,
  Divider,
  Badge,
} from "@chakra-ui/react";
import { http } from "../services/http";
import FloatingWhatsAppButton from "../components/floatingWhatsAppButton";

export interface MegaSenaResult {
  concursoId: number;
  numeroConcurso: number;
  numerosSorteados: string;
  diaConcurso: string;
  ativo: boolean;
  salaId: number;
}

export const DrawInformation = () => {
  const [draws, setDraws] = useState<MegaSenaResult[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await http.get("/resultado-mega/por-salas");

        const activeDraws = response.data.conteudo.flatMap((item: any) =>
          item.salaResultadoMega.flatMap((sala: any) =>
            sala.resultadoMegaConcurso
              .filter((draw: MegaSenaResult) => draw.ativo)
              .map((draw: MegaSenaResult) => ({
                ...draw,
                salaId: sala.salaId,
              }))
          )
        );

        setDraws(activeDraws);
      } catch (error) {
        console.error("Error fetching draw information:", error);
      }
    };

    fetchData();
  }, []);

  const formatDate = (dateString: string) => {
    const [year, month, day] = dateString.split("-");
    return `${day}-${month}-${year}`;
  };

  const formatNumber = (number: number): string => {
    return number.toString().padStart(4, "0");
  };

  return (
    <Box
      mb="5%"
      bg="white"
      borderRadius="md"
      boxShadow="md"
      maxW={{ base: "90%", lg: "lg" }}
      mx="auto"
      mt={{ base: 10, md: 5 }}
      p={{ base: 5, md: 8 }}
      w="100%"
    >
      <Text
        fontWeight="bold"
        textAlign="center"
        fontSize={{ xl: "2xl", lg: "xl" }}
        color="teal.600"
        mb={4}
      >
        🍀 Números sorteados pela Mega Sena
      </Text>

      {draws.length > 0 ? (
        <Grid templateColumns="repeat(  minmax(250px, 1fr))" gap={6}>
          {draws.map((draw, index) => (
            <GridItem
              key={index}
              borderRadius="md"
              borderWidth="1px"
              borderColor="gray.200"
              p={4}
              bg="gray.100"
              transition="all 0.3s"
              _hover={{ transform: "scale(1.05)", boxShadow: "lg" }}
            >
              <Stack spacing={3}>
                <Flex justifyContent="space-between">
                  <Text fontWeight="bold" fontSize="lg" color="teal.700">
                    Sala: {formatNumber(draw.salaId)}
                  </Text>
                  <Badge bg="#006391" variant="solid" mt={2}>
                    Concurso {draw.numeroConcurso}
                  </Badge>
                </Flex>
                <Text fontSize="md" color="gray.600">
                  Data: {formatDate(draw.diaConcurso)}
                </Text>
                <Divider />
                <Flex flexWrap="wrap" justifyContent="center" mt={2}>
                  {draw.numerosSorteados
                    .split(",")
                    .map((number, numberIndex) => (
                      <Box
                        key={numberIndex}
                        m={1}
                        p={3}
                        bg="#20873E"
                        borderRadius="50%"
                        color="white"
                        width={{ base: "30px", md: "40px", lg: "40px" }}
                        height={{ base: "30px", md: "40px", lg: "40px" }}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Text fontSize="lg" fontWeight="bold">
                          {number.trim().padStart(2, "0")}
                        </Text>
                      </Box>
                    ))}
                </Flex>
              </Stack>
            </GridItem>
          ))}
        </Grid>
      ) : (
        <Text mt={4} textAlign="center" color="gray.500">
          Ainda não há números sorteados.
        </Text>
      )}

      <FloatingWhatsAppButton />
    </Box>
  );
};
