import { useEffect, useState } from "react";
import { Button, Box, Image } from "@chakra-ui/react";
import { FaWhatsapp } from "react-icons/fa";
import { motion } from "framer-motion";
import { http } from "../services/http";
import logo from "../assets/grupo.png";

const FloatingWhatsAppButton = () => {
  const [whatsAppLink, setWhatsAppLink] = useState<string>("");

  const fetchWhatsAppLink = async () => {
    try {
      const response = await http.get("/midia-social/");
      const link = response.data.conteudo.find(
        (item: any) => item.descricao === "Whatsapp"
      );
      if (link) {
        setWhatsAppLink(link.valor);
      }
    } catch (error) {
      console.error("Erro ao buscar o link do WhatsApp:", error);
    }
  };

  useEffect(() => {
    fetchWhatsAppLink();
  }, []);

  return (
    <motion.div
      style={{
        position: "fixed",
        bottom: "-10px",
        right: "20px",
        zIndex: 1000,
      }}
      animate={{ y: [0, -10, 0] }}
      transition={{
        duration: 1,
        repeat: Infinity,
        repeatType: "loop",
      }}
    >
      <Box display="flex" flexDirection="column" alignItems="center">
        <Button
          w="70px"
          h="70px"
          as="a"
          href={whatsAppLink}
          target="_blank"
          rel="noopener noreferrer"
          bg="green.500"
          color="white"
          borderRadius="50%"
          padding="10px"
          boxShadow="0px 4px 6px rgba(0, 0, 0, 0.1)"
          _hover={{
            transform: "scale(1.1)",
            boxShadow: "0px 8px 10px rgba(0, 0, 0, 0.2)",
          }}
          _active={{ transform: "scale(0.9)" }}
        >
          <FaWhatsapp size="35px" />
        </Button>

        <a href={whatsAppLink} target="_blank" rel="noopener noreferrer">
          <Image
            src={logo}
            alt="Acompanhe nosso grupo"
            boxSize="90px"
            objectFit="contain"
            mt="-20px"
            _hover={{ cursor: "pointer" }}
          />
        </a>
      </Box>
    </motion.div>
  );
};

export default FloatingWhatsAppButton;
