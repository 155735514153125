import {
  Badge,
  Box,
  CircularProgress,
  Flex,
  Grid,
  Heading,
  Select,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import { ControlPanelItem } from "../../core/components/control-panel";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
} from "chart.js";
import moment from "moment";
import { Line, Pie } from "react-chartjs-2";
import { faker } from "@faker-js/faker";
import { Table } from "../../core/components/table";
import { useCallback, useEffect, useState } from "react";
import { IColumn } from "../../types/componentes/table";
import { IInvoicing } from "../../types/invoicing/interfaces";
import { useInvoicing } from "../../contexts/invoicing";
import { methodNormalizer } from "../../utils/normalizers/payment-methods/methods";
import { statusNormalizer } from "../../utils/normalizers/payment-methods/status";

export function Invoicing() {
  const { panelControl, isLoading, findAll } = useInvoicing();
  const [isLargerThan1280] = useMediaQuery("(min-width: 1280px)");
  const [isLargerThan680] = useMediaQuery("(min-width: 680px)");
  const [isLargerThan480] = useMediaQuery("(min-width: 480px)");
  const [findAllCompleted, setFindAllCompleted] = useState(false);

  const [filterRaces, setFilterRaces] = useState<number>(
    panelControl[0]?.races.month | 0
  );
  const [filterPassengers, setFilterPassengers] = useState<number>(
    panelControl[0]?.passengers.month | 0
  );
  const [filterAlbleDrivers, setFilterAlbleDrivers] = useState<number>(
    panelControl[0]?.ableDrivers.month | 0
  );
  const [filterDisalbleDrivers, setFilterDisalbleDrivers] = useState<number>(
    panelControl[0]?.disableDrivers.month | 0
  );
  const [filterIvoices, setFilterIvoices] = useState<number>(
    panelControl[0]?.Invoices.month | 0
  );

  const handleFilterChange = useCallback((newFilter: string) => {
    if (newFilter === "day") {
      setFilterRaces(panelControl[0]?.races.day);
      setFilterPassengers(panelControl[0]?.passengers.day);
      setFilterAlbleDrivers(panelControl[0]?.ableDrivers.day);
      setFilterDisalbleDrivers(panelControl[0]?.disableDrivers.day);
      setFilterIvoices(panelControl[0]?.Invoices.day);
    } else if (newFilter === "week") {
      setFilterRaces(panelControl[0]?.races.week);
      setFilterPassengers(panelControl[0]?.passengers.week);
      setFilterAlbleDrivers(panelControl[0]?.ableDrivers.week);
      setFilterDisalbleDrivers(panelControl[0]?.disableDrivers.week);
      setFilterIvoices(panelControl[0]?.Invoices.week);
    } else if (newFilter === "month") {
      setFilterRaces(panelControl[0]?.races.month);
      setFilterPassengers(panelControl[0]?.passengers.month);
      setFilterAlbleDrivers(panelControl[0]?.ableDrivers.month);
      setFilterDisalbleDrivers(panelControl[0]?.disableDrivers.month);
      setFilterIvoices(panelControl[0]?.Invoices.month);
    }
  }, [panelControl, setFilterRaces, setFilterPassengers, setFilterAlbleDrivers, setFilterDisalbleDrivers, setFilterIvoices]);

  useEffect(() => {
  const fetchData = async () => {
    await findAll();   
    setFindAllCompleted(true);
  };

  fetchData();
}, []);  

  useEffect(() => {
    if (findAllCompleted) {
      handleFilterChange("month");   
    }
  }, [findAllCompleted, handleFilterChange]);    
  

  const data = {
    labels: ["Cotistas Inativos", "Cotistas Ativos"],
    datasets: [
      {
        label: "Quantidade",
        data: [filterDisalbleDrivers, filterAlbleDrivers],
        backgroundColor: ["rgba(211, 211, 211, 0.685)", "#ffff01ce"],
        borderColor: ["rgba(211, 211, 211, 1)", "rgba(255, 255, 0, 1)"],
        borderWidth: 1,
      },
    ],
  };

 
  const lineOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: true,
        text: "Custo x Faturamento",
      },
    },
  };

  const pieOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
        labels: {
          color: "white",
          fontWeight: "bold",
        },
      },
    },
  };

  const labels = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
  ];

  const lineData = {
    labels,
    datasets: [
      {
        label: "Custo",
        data: labels.map(() => faker.number.int({ min: 0, max: 2000 })),
        borderColor: "rgba(211, 211, 211, 1)",
        backgroundColor: "rgba(211, 211, 211, 1)",
      },
      {
        label: "Faturamento",
        data: labels.map(() => faker.number.int({ min: 0, max: 5000 })),
        borderColor: "rgba(255, 255, 0, 1)",
        backgroundColor: "rgba(255, 255, 1)",
      },
    ],
  };

  ChartJS.register(ArcElement, Tooltip, Legend);

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  const columns: IColumn<IInvoicing>[] = [
    {
      label: (
        <Text color="white" fontWeight="bold">
          Bolão
        </Text>
      ),
      render: (statistics) => <Text>{statistics.destiny}</Text>,
      width: 0,
    },

    {
      label: (
        <Text color="white" fontWeight="bold">
          Cotas
        </Text>
      ),
      render: (statistics) => <Text> {statistics.destiny}</Text>,
      width: 0,
    },
    {
      label: (
        <Text color="white" fontWeight="bold">
          Preço
        </Text>
      ),
      render: (statistics) => <Text>R$ {statistics.price}</Text>,
      width: 0,
    },
    {
      label: (
        <Text color="white" fontWeight="bold">
          Forma de Pagamento
        </Text>
      ),
      render: (statistics) => (
        <Text>{methodNormalizer(statistics.paymentMethod)}</Text>
      ),
      width: 0,
    },
    {
      label: (
        <Text color="white" fontWeight="bold">
          Status
        </Text>
      ),
      render: (statistics) => (
        <Badge colorScheme={statusNormalizer(statistics.status).colorScheme}>
          <Text>{statusNormalizer(statistics.status).label}</Text>
        </Badge>
      ),
      width: 0,
    },
    {
      label: (
        <Text color="white" fontWeight="bold">
          Data do Pagamento
        </Text>
      ),
      render: (statistics) => (
        <Text>{moment(statistics.paymentDate).format("DD/MM/YYYY")}</Text>
      ),
      width: 0,
    },
    {
      label: (
        <Text color="white" fontWeight="bold">
          Data do evento
        </Text>
      ),
      render: (statistics) => (
        <Text>{moment(statistics.createdAt).format("DD/MM/YYYY")}</Text>
      ),
      width: 0,
    },
  ];

  const OrderColumns: IColumn<IInvoicing>[] = [
    {
      label: (
        <Text color="white" fontWeight="bold">
          Bolão
        </Text>
      ),
      render: (statistics) => <Text>{statistics.clientName}</Text>,
      width: 0,
    },
    {
      label: (
        <Text color="white" fontWeight="bold">
          Cotas
        </Text>
      ),
      render: (statistics) => <Text>{statistics.driverName}</Text>,
      width: 0,
    },
    {
      label: (
        <Text color="white" fontWeight="bold">
          Preço
        </Text>
      ),
      render: (statistics) => <Text>R$ {statistics.price}</Text>,
      width: 0,
    },

    {
      label: (
        <Text color="white" fontWeight="bold">
          Data do evento
        </Text>
      ),
      render: (statistics) => (
        <Text>{moment(statistics.createdAt).format("DD/MM/YYYY")}</Text>
      ),
      width: 0,
    },
  ];

  return isLoading ? (
    <Flex flex="1" alignItems="center" justifyContent="center">
      <CircularProgress color="yellow" isIndeterminate />
    </Flex>
  ) : (
    <Grid
      maxWidth={1280}
      marginTop={12}
      templateColumns={isLargerThan1280 ? "repeat(4, 1fr)" : "repeat(2, 1fr)"}
      gap={8}
      marginX={isLargerThan680 ? "auto" : 4}
    >
      <Box
        gridColumn={isLargerThan1280 ? "span 4" : "span 2"}
        w={isLargerThan480 ? 32 : "full"}
      >
        <Text color="white" fontWeight="bold">
          Filtrar por:
        </Text>
        <Select onChange={(e) => handleFilterChange(e.target.value)} bg="white">
          <option value="month">Mês</option>
          <option value="week">Semana</option>
          <option value="day">Dia</option>
        </Select>
      </Box>

      <Box gridColumn={isLargerThan480 ? "span 1" : "span 2"}>
        <ControlPanelItem
          title="Total de Salas"
          value={filterPassengers}
          percentChange={14.98}
          formatValue={false}
        />
      </Box>
      <Box gridColumn={isLargerThan480 ? "span 1" : "span 2"}>
        <ControlPanelItem
          title="Total de Cotistas"
          value={filterAlbleDrivers + filterAlbleDrivers}
          percentChange={-1.03}
          formatValue={false}
        />
      </Box>
      <Box gridColumn={isLargerThan480 ? "span 1" : "span 2"}>
        <ControlPanelItem
          title="Total de Sorteios Realizados"
          value={filterRaces}
          percentChange={23.36}
          formatValue={false}
        />
      </Box>
      <Box gridColumn={isLargerThan480 ? "span 1" : "span 2"}>
        <ControlPanelItem
          title="Total em Faturamento"
          value={filterIvoices}
          percentChange={-19.05}
          formatValue={true}
        />
      </Box>

      <Box marginTop={isLargerThan1280 ? 12 : 6} gridColumn="span 2">
        <Flex
          bg="#F5F5F5"
          justifyContent="center"
          w="100%"
          maxH={isLargerThan680 ? 400 : 600}
        >
          <Line data={lineData} options={lineOptions} />
        </Flex>
      </Box>
      <Box
        marginTop={isLargerThan1280 ? 12 : 6}
        marginBottom={isLargerThan680 ? 0 : 6}
        gridColumn="span 2"
      >
        <Flex justifyContent="center" w="100%" maxH={400}>
          <Pie data={data} options={pieOptions} />
        </Flex>
      </Box>

      <Box
        marginBottom={isLargerThan680 ? 16 : 0}
        gridColumn={isLargerThan1280 ? "span 4" : "span 2"}
      >
        <Heading color="#EDF2F7">Últimos cotistas</Heading>
        <Flex>
          <Table<IInvoicing> columns={columns} rows={panelControl} />
        </Flex>
      </Box>

      <Box
        marginBottom={12}
        gridColumn={isLargerThan1280 ? "span 4" : "span 2"}
      >
        <Heading color="#EDF2F7">Últimos sorteios</Heading>
        <Flex>
          <Table<IInvoicing> columns={OrderColumns} rows={panelControl} />
        </Flex>
      </Box>
    </Grid>
  );
}
