import { useState } from "react";
import {
  Box,
  Button,
  Container,
  Flex,
  HStack,
  IconButton,
  Input,
  Stack,
  Text,
  VStack,
  Heading,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";
import { ArrowBackIcon, CloseIcon } from "@chakra-ui/icons";
import { useNavigate, useLocation } from "react-router-dom";
import { IRooms } from "../../types/users";
import { useAuth } from "../../contexts/authContext";
import { http } from "../../services/http";

export function EditScreen() {
  const location = useLocation();
  const room = location.state as IRooms;
  const [valorPalpite, setValorPalpite] = useState("R$ 0,00");
  const [salaId, setSalaId] = useState(room.salaId?.toString() || "");
  const [dataFimVendas, setDataFimVendas] = useState(room.dataFimVendas || "");
  const [concursos, setConcursos] = useState<
    Array<{ concursoId: number; diaConcurso: string; numeroConcurso: string }>
  >(
    room.concursos?.map((concurso) => ({
      salaId: concurso.salaId,
      concursoId: concurso.concursoId,
      diaConcurso: concurso.diaConcurso || "",
      numeroConcurso: concurso.numeroConcurso?.toString() || "",
    })) || []
  );

  const [diaConcurso, setDiaConcurso] = useState("");
  const [numeroConcurso, setNumeroConcurso] = useState("");
  const navigate = useNavigate();
  const { token } = useAuth();
  const toast = useToast();
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  function formatarMoeda(valor: string) {
    const numero = valor.replace(/\D/g, "");
    const valorFormatado = (Number(numero) / 100).toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
    return valorFormatado;
  }

  function removerFormatacao(valor: string) {
    return valor.replace(/[\D]+/g, "");
  }

  const adicionarConcurso = () => {
    if (diaConcurso && numeroConcurso) {
      setConcursos([
        ...concursos,
        {
          concursoId: Math.max(...concursos.map((c) => c.concursoId), 0) + 1,
          diaConcurso,
          numeroConcurso,
        },
      ]);
      setDiaConcurso("");
      setNumeroConcurso("");
    }
  };

  const editarConcurso = (index: number, field: string, value: string) => {
    const updatedConcursos = concursos.map((concurso, idx) =>
      idx === index ? { ...concurso, [field]: value } : concurso
    );
    setConcursos(updatedConcursos);
  };

  const removerConcurso = (index: number) => {
    setConcursos(concursos.filter((_, idx) => idx !== index));
  };
  const salvarEdicao = async () => {
    const valorBruto = removerFormatacao(valorPalpite);
    const updatedRoom = {
      salaId: room.salaId,
      dataFimVendas,
      valorPalpite: parseFloat(valorBruto) / 100,
      statusSalas: room.statusSala.id,
      concursos: concursos.map((concurso) => ({
        concursoId: concurso.concursoId,
        salaId: room.salaId,
        diaConcurso: concurso.diaConcurso,
        numeroConcurso: Number(concurso.numeroConcurso),
      })),
    };

    try {
      const response = await http.put("/salas/altera", updatedRoom, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setShowSuccessModal(true);
    } catch (error) {
      console.error("Erro ao atualizar sala:", error);
      toast({
        title: "Erro",
        description: "Erro ao tentar atualizar a sala.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleCloseSuccessModal = () => {
    setShowSuccessModal(false);
    navigate("/roomsDashboard");
  };
  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value.length <= 10) {
      setDiaConcurso(value);
    }
  };
  return (
    <Container
      bg="white"
      borderRadius="md"
      boxShadow="xl"
      maxW={{ base: "sm", md: "lg" }}
      mx="auto"
      mt={{ base: 10, md: 20 }}
      mb={{ base: 10, md: 20 }}
      p={6}
    >
      <VStack spacing={6} align="stretch">
        <Flex align="center" mb={4}>
          <IconButton
            bg="#38A169"
            color="white"
            aria-label="Voltar"
            icon={<ArrowBackIcon />}
            variant="ghost"
            onClick={() => navigate(-1)}
          />
          <Heading as="h2" size="lg" ml={4}>
            Editar Bolão
          </Heading>
        </Flex>
        <Box>
          <Text mb={2}>Número Bolão</Text>
          <Input
            isReadOnly
            borderColor="gray.300"
            type="text"
            value={salaId}
            onChange={(e) => setValorPalpite(e.target.value)}
          />
        </Box>
        <Box>
          <Text mb={2}>Data e Hora Fim Vendas</Text>
          <Input
            isReadOnly
            borderColor="gray.300"
            type="datetime-local"
            value={dataFimVendas}
            onChange={(e) => setDataFimVendas(e.target.value)}
          />
        </Box>
        <Box>
          <Text mb={2}>Valor Palpite</Text>
          <Input
            borderColor="gray.300"
            type="text"
            value={valorPalpite}
            onChange={(e) => setValorPalpite(formatarMoeda(e.target.value))}
          />
        </Box>
        {/* <Box>
          <Text mb={2} textAlign="center" fontWeight="bold">
            Adicionar Concurso
          </Text>
          <Flex direction={{ base: "column", md: "row" }} align="center">
            <Box flex="1" mr={{ base: 0, md: 1 }} mb={{ base: 2, md: 0 }}>
              <Text mb={2} textAlign="center">
                Dia do Concurso
              </Text>
              <Input
                borderColor="gray.300"
                type="date"
                value={diaConcurso}
                onChange={handleDateChange}
              />
            </Box>
            <Box flex="1" ml={{ base: 0, md: 1 }} mb={{ base: 2, md: 0 }}>
              <Text mb={2} textAlign="center">
                Número do Concurso
              </Text>
              <Input
                borderColor="gray.300"
                type="number"
                value={numeroConcurso}
                onChange={(e) => setNumeroConcurso(e.target.value)}
              />
            </Box>
            <Box mt={{ base: 2, md: 30 }} ml={{ base: 0, md: 2 }}>
              <Button
                onClick={adicionarConcurso}
                bg="#38A169"
                color="white"
                _hover={{ bg: "teal.500" }}
              >
                +
              </Button>
            </Box>
          </Flex>
        </Box> */}
        {/* <Box>
          <HStack justify="space-around">
            <Text fontWeight="bold" textAlign="center">
              Dia do Concurso
            </Text>
            <Text fontWeight="bold" textAlign="center">
              Número do Concurso
            </Text>
          </HStack>
          <Stack spacing={2} mt={2}>
            {concursos.map((concurso, index) => (
              <Flex key={index} align="center">
                <HStack spacing={{ base: 2, md: 5 }} flex="1">
                  <Input
                    borderColor="gray.300"
                    type="date"
                    value={concurso.diaConcurso}
                    onChange={(e) =>
                      editarConcurso(index, "diaConcurso", e.target.value)
                    }
                  />
                  <Input
                    borderColor="gray.300"
                    type="text"
                    value={concurso.numeroConcurso}
                    onChange={(e) =>
                      editarConcurso(index, "numeroConcurso", e.target.value)
                    }
                  />
                </HStack>
                <IconButton
                  ml={1}
                  aria-label="Remover concurso"
                  icon={<CloseIcon />}
                  size="sm"
                  colorScheme="red"
                  onClick={() => removerConcurso(index)}
                />
              </Flex>
            ))}
          </Stack>
        </Box> */}
        <Button colorScheme="green" onClick={salvarEdicao} mt={4}>
          Salvar
        </Button>
      </VStack>

      <Modal isOpen={showSuccessModal} onClose={handleCloseSuccessModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Sucesso!</ModalHeader>
          <ModalBody>
            <Text>Sua alteração foi realizada com sucesso!</Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="green" onClick={handleCloseSuccessModal}>
              OK
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Container>
  );
}
