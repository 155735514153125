import {
  CircularProgress,
  Container,
  Flex,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Box,
  IconButton,
  Badge,
  Input,
  HStack,
  useToast,
  Select,
  Stack,
} from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { IoEyeOutline } from "react-icons/io5";
import { Table } from "../../core/components/table";
import { useAuth } from "../../contexts/authContext";
import { http } from "../../services/http";
import { IColumn } from "../../types/componentes/table";
import { IRooms } from "../../types/users";
import { ArrowLeftIcon, ArrowRightIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { RiPencilLine } from "react-icons/ri";
import { IoIosAdd } from "react-icons/io";

export function Award() {
  const [salas, setSalas] = useState<IRooms[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);
  const [filterType, setFilterType] = useState<string>("");
  const [filterValue, setFilterValue] = useState<string>("");
  const [filteredSalas, setFilteredSalas] = useState<IRooms[]>([]);
  const [modalValues, setModalValues] = useState<IRooms>({
    salaId: 0,
    dataInicio: "",
    dataFimVendas: "",
    dataFim: "",
    valorPalpite: 0,
    statusSala: { id: 0, descricao: "" },
    concursos: [],
    ativo: true,
    valorPremioTotal: 0,
  });

  const { token } = useAuth();
  const [premio, setPremio] = useState<string>("");
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchSalas() {
      try {
        const response = await http.get("/salas/", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const activeSalas = response.data.conteudo.filter(
          (sala: IRooms) => sala.ativo === true
        );

        setSalas(activeSalas);
        setIsLoading(false);
      } catch (error) {
        console.error("Erro ao buscar salas:", error);
        setIsLoading(false);
      }
    }
    fetchSalas();
  }, [token]);

  function closeModal() {
    setIsModalOpen(false);
    setIsConfirmModalOpen(false);
    setModalValues({
      salaId: 0,
      dataInicio: "",
      dataFimVendas: "",
      dataFim: "",
      valorPalpite: 0,
      statusSala: { id: 0, descricao: "" },
      concursos: [],
      ativo: true,
      valorPremioTotal: 0,
    });
    setPremio("");
  }
  function handleAddClick(sala: IRooms) {
    navigate("/addAward", {
      state: {
        salaId: sala.salaId,
        concursos: sala.concursos,
        statusSala: sala.statusSala.descricao,
      },
    });
  }

  function handleEditClick(sala: IRooms) {
    navigate("/editAward", {
      state: {
        salaId: sala.salaId,
        concursos: sala.concursos,
        statusSala: sala.statusSala.descricao,
        premios: sala.concursos.map((conc) => ({
          premio: conc.numeroConcurso,
          descricao: conc.diaConcurso,
        })),
      },
    });
  }
  function handleViewClick(sala: IRooms) {
    navigate("/viewAward", {
      state: {
        salaId: sala.salaId,

        numeroConcurso:
          sala.concursos.length > 0 ? sala.concursos[0]?.numeroConcurso : null,
      },
    });
  }
  useEffect(() => {
    if (filterType && filterValue) {
      const filteredData = salas.filter((sala) => {
        switch (filterType) {
          case "salaId":
            return sala.salaId.toString().includes(filterValue);

          default:
            return false;
        }
      });
      setFilteredSalas(filteredData);
    } else {
      setFilteredSalas(salas);
    }
  }, [filterType, filterValue, salas]);

  function getStatusColor(status: string): string {
    switch (status) {
      case "Aberta":
        return "green";
      case "Em Andamento":
        return "yellow";
      case "Finalizada":
        return "red";
      default:
        return "gray";
    }
  }

  const getStatusText = (status: string) => {
    if (status === "Aberta") {
      return "Vendas Abertas";
    }
    if (status === "Finalizada") {
      return "Finalizado";
    }
    return status;
  };

  const columns: IColumn<IRooms>[] = [
    {
      label: <Text fontWeight="bold">Número Bolão</Text>,
      render: (rooms) => {
        const formattedSalaId = rooms.salaId.toString().padStart(4, "0");
        return <Text color="black">{formattedSalaId}</Text>;
      },
      width: 15,
    },

    {
      label: "Status do Bolão",
      render: (sala) => (
        <Badge colorScheme={getStatusColor(sala.statusSala.descricao)}>
          {getStatusText(sala.statusSala.descricao)}
        </Badge>
      ),
      width: 20,
    },
    {
      label: (
        <Text fontWeight="bold" textAlign="center">
          Ações
        </Text>
      ),
      render: (sala) => (
        <HStack justifyContent="center">
          <IconButton
            aria-label="add"
            colorScheme="green"
            icon={<IoIosAdd size={20} />}
            onClick={() => handleAddClick(sala)}
          />
          <IconButton
            aria-label="edit"
            colorScheme="green"
            icon={<RiPencilLine size={20} />}
            onClick={() => handleEditClick(sala)}
          />
          <IconButton
            colorScheme="green"
            color="white"
            aria-label="view-details"
            icon={<IoEyeOutline size={20} />}
            onClick={() => handleViewClick(sala)}
          />
        </HStack>
      ),
      width: 25,
    },
  ];

  function handleSave() {
    setIsConfirmModalOpen(true);
    setIsModalOpen(false);
  }

  function confirmSave() {
    const numerosFormatados = premio.split("-").join(",");
    closeModal();
  }
  return isLoading ? (
    <Flex flex="1" alignItems="center" justifyContent="center">
      <CircularProgress color="#16A34A" isIndeterminate />
    </Flex>
  ) : (
    <Flex flex="1" flexDirection="row" paddingY={5}>
      <Container
        borderRadius={10}
        bg={"gray.200"}
        maxWidth={["100vw", "60vw"]}
        display="flex"
        flexDirection="column"
        gap={10}
        mt={6}
      >
        <Box>
          <Text
            mb={5}
            marginTop={5}
            fontSize={["2xl", "3xl"]}
            textAlign="center"
            fontWeight="bold"
          >
            Prêmio
          </Text>
          <Text fontSize="20px" fontWeight="bold" textAlign="center" mb={3}>
            Buscar por:
          </Text>
          <Stack direction="row" spacing={2} justify="center">
            <Select
              border="1px solid #979489b3"
              placeholder="Selecione o Filtro"
              bg="white"
              value={filterType}
              onChange={(e) => {
                setFilterType(e.target.value);
                setFilterValue("");
              }}
              maxW="200px"
            >
              <option value="salaId">Nº do Bolão</option>
            </Select>
            {filterType && (
              <Input
                border="1px solid #979489b3"
                placeholder="Digite o valor"
                bg="white"
                value={filterValue}
                onChange={(e) => setFilterValue(e.target.value)}
                maxW="200px"
              />
            )}
          </Stack>
        </Box>

        <Flex width="full">
          <Table<IRooms> columns={columns} rows={filteredSalas} />
        </Flex>
      </Container>

      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign="center">Insira o valor do prêmio</ModalHeader>
          <ModalBody>
            <Text mb={4}>Número Bolão: {modalValues.salaId}</Text>
            <Text mb={4}>
              Número do Concurso:{" "}
              {modalValues.concursos.length > 0
                ? modalValues.concursos[0]?.numeroConcurso
                : "N/A"}
            </Text>
            <Text mb={4}>
              Data do Concurso:{" "}
              {modalValues.concursos.length > 0
                ? modalValues.concursos[0]?.diaConcurso
                : "N/A"}
            </Text>

            <Input
              style={{
                border: "1px solid #ccc",
                borderRadius: "4px",
                padding: "8px",
                width: "100%",
              }}
              type="number"
              name="premio"
              placeholder="Insira o valor do prêmio"
              value={premio}
              onChange={(e) => setPremio(e.target.value)}
            />
          </ModalBody>
          <ModalFooter>
            <Flex justifyContent="space-between" width="100%">
              <Button variant="ghost" onClick={closeModal} bg="#e7e1e1">
                Não
              </Button>
              <Button
                colorScheme="#16A34A"
                bgColor="#16A34A"
                color="white"
                mr={3}
                onClick={handleSave}
              >
                Confirmar
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isConfirmModalOpen} onClose={closeModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirmar Números</ModalHeader>
          <ModalBody>
            <Text mb={4}>
              Números Digitados: <strong>{premio}</strong>
            </Text>
            <Text mb={4}>
              Data do Concurso:{" "}
              {modalValues.concursos.length > 0
                ? modalValues.concursos[0]?.diaConcurso
                : "N/A"}
            </Text>
            <Text mb={4}>
              Número do Concurso:{" "}
              {modalValues.concursos.length > 0
                ? modalValues.concursos[0]?.numeroConcurso
                : "N/A"}
            </Text>

            <Text fontWeight="bold" color="#EB3941">
              Confirme se os números digitados estão corretos.
            </Text>
          </ModalBody>
          <ModalFooter>
            <Flex justifyContent="space-between" width="100%">
              <Button variant="ghost" onClick={closeModal} bg="#e7e1e1">
                Não
              </Button>
              <Button
                colorScheme="#16A34A"
                bgColor="#16A34A"
                color="white"
                mr={3}
                onClick={confirmSave}
              >
                Confirmar
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
}
