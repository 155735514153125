import { Box, Heading, Text } from "@chakra-ui/react";

export const PrivacidadeDados = () => {
  return (
    <Box
      bg="#FFFFFF"
      p={5}
      mt={4}
      mb={6}
      mx={{ base: 2, sm: "auto", md: "auto", lg: "auto" }}
      width={{ base: "95%", sm: "90%", md: "70%", lg: "50%" }}
      borderRadius="lg"
      boxShadow="md"
    >
      <Heading
        mb={5}
        fontSize={{ base: "xl", md: "2xl", lg: "3xl" }}
        textAlign="center"
      >
        Política Privacidade de Dados Pessoais{" "}
      </Heading>
      <Text fontSize={{ base: "sm", md: "md", lg: "lg" }} mb={3}>
        A sua privacidade é importante para nós.
      </Text>
      <Text fontSize={{ base: "sm", md: "md", lg: "lg" }} mb={3}>
        Respeitar a sua privacidade em relação a qualquer informação sua que
        possamos coletar no site e outros sites que possuímos e operamos é nossa
        prioridade.
      </Text>
      <Text fontSize={{ base: "sm", md: "md", lg: "lg" }} mb={3}>
        Em observância às Boas Práticas de Tratamento de Dados Pessoais, esta
        Política Privacidade para demonstrar seu compromisso em proteger a sua
        privacidade e seus dados pessoais com segurança e transparência, nos
        termos da Lei Geral de Proteção de Dados (LGPD) – Lei Federal nº
        13.709/2018.
      </Text>

      <Text fontSize={{ base: "sm", md: "md", lg: "lg" }} mb={3}>
        Solicitamos informações pessoais apenas quando realmente precisamos
        delas para lhe fornecer um serviço.
      </Text>
      <Text fontSize={{ base: "sm", md: "md", lg: "lg" }} mb={3}>
        Tal ato, realizado por meios justos e legais, com o seu conhecimento e
        consentimento. Também informamos por que estamos coletando e como será
        usado.
      </Text>
      <Text fontSize={{ base: "sm", md: "md", lg: "lg" }} mb={3}>
        Apenas retemos as informações coletadas pelo tempo necessário para
        fornecer o serviço solicitado, com o intuito principal de fornecer
        publicidade e credibilidade em todos os atos e cadastros praticados no
        presente site.
      </Text>

      <Text fontSize={{ base: "sm", md: "md", lg: "lg" }} mb={3}>
        Quando armazenamos dados, protegemos dentro de meios comercialmente
        aceitáveis ​​para evitar perdas e roubos, bem como acesso, divulgação,
        cópia, uso ou modificação não autorizados, ressalte-se, não há qualquer
        publicidade de dados pessoais senão aquelas descritas na politica de uso
        constante no site bolaodos10.com.br.
      </Text>
      <Text fontSize={{ base: "sm", md: "md", lg: "lg" }} mb={3}>
        Em conformidade com as regras e direitos prescritos nos Arts. 9º e 18 da
        LGPD, você tem garantido livre acesso aos seus dados pessoais, mediante
        adequada identificação pessoal, para que não haja prejuízo ao princípio
        da segurança.{" "}
      </Text>
      <Text fontSize={{ base: "sm", md: "md", lg: "lg" }} mb={3}>
        Não haverá compartilhamento de informações de identificação pessoal
        publicamente ou com terceiros, exceto quando exigido por lei.{" "}
      </Text>
      <Text fontSize={{ base: "sm", md: "md", lg: "lg" }} mb={3}>
        O nosso site pode ter links para sites externos que não são operados por
        nós. Caso em que não há o controle sobre os conteúdos ali exigidos e
        práticas desses sites e não podemos aceitar responsabilidade por suas
        respectivas políticas de privacidade.{" "}
      </Text>
      <Text fontSize={{ base: "sm", md: "md", lg: "lg" }} mb={3}>
        Você é livre para recusar a nossa solicitação de informações pessoais,
        entendendo que talvez não possamos fornecer alguns dos serviços
        desejados.
      </Text>

      <Text fontSize={{ base: "sm", md: "md", lg: "lg" }} mb={3}>
        O uso continuado de nosso site será considerado como aceitação de nossas
        práticas em torno de privacidade e informações pessoais.
      </Text>
      <Text fontSize={{ base: "sm", md: "md", lg: "lg" }} mb={3}>
        Se você tiver alguma dúvida sobre como lidamos com dados do usuário e
        informações pessoais, estamos disponíveis pelo contato
        suporte@bolaodos10.com.br.
      </Text>
      <Text fontSize={{ base: "sm", md: "md", lg: "lg" }} mb={3}>
        A LL TECH SOLUÇÕES DIGITAIS LTDA se compromete a proteger os dados que
        são coletados e utilizados, zelando pelo tratamento ético, transparente
        e responsável dos seus dados pessoais, em todo período necessário de
        armazenamento.
      </Text>
      <Text fontSize={{ base: "sm", md: "md", lg: "lg" }} mb={3}>
        O usuário se compromete a fazer uso adequado dos conteúdos e da
        informação que o fornecedor LL TECH SOLUÇÕES DIGITAIS LTDA oferece no
        site.
      </Text>
      <Text fontSize={{ base: "sm", md: "md", lg: "lg" }} mb={3}>
        O cadastro por parte de crianças e adolescentes é proibido e serão
        tratados conforme os termos desta Política, observando, adicionalmente,
        as seguintes especificidades:
      </Text>

      <Text fontSize={{ base: "sm", md: "md", lg: "lg" }} mb={3}>
        No que se refere aos dados baseados em consentimento o acesso a crianças
        e adolescentes é limitado à navegação não podendo adquirir qualquer
        produto ou participar de quaisquer promoções.
      </Text>

      <Text fontSize={{ base: "sm", md: "md", lg: "lg" }} mb={3}>
        Mais informações
      </Text>

      <Text fontSize={{ base: "sm", md: "md", lg: "lg" }} mb={3}>
        Tendo esclarecido os termos e políticas, caso exista algo que você não
        tem certeza se precisa ou não, geralmente é mais seguro deixar os
        cookies ativados, caso interaja com um dos recursos que você usa em
        nosso site.
      </Text>
    </Box>
  );
};
