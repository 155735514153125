import { useState, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useAuth } from "../contexts/authContext";
import Authenticated from "./routers/authenticated";
import Unauthenticated from "./routers/unauthenticated";
import "../styles.css";

export function RoutesApp() {
  const { isAuthenticated } = useAuth();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return (
      <div className="loading-spinner">
        <div className="spinner" />
      </div>
    );
  }

  return (
    <Routes>
      <Route
        path="/*"
        element={isAuthenticated ? <Authenticated /> : <Unauthenticated />}
      />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}
