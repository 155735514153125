import { ArrowBackIcon, CloseIcon } from "@chakra-ui/icons";
import {
  Container,
  Flex,
  Text,
  Box,
  Button,
  Input,
  IconButton,
  Heading,
  Badge,
  Stack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { http } from "../../services/http";
import { useAuth } from "../../contexts/authContext";

interface TipoPremio {
  idTipoPremio: number;
  descricao: string;
}

interface Premio {
  premioId: number;
  descricao: string;
  valor: number;
  concursoId: number;
  numeroConcurso: number;
  tipoPremio: TipoPremio;
}

export function EditAward() {
  const location = useLocation();
  const navigate = useNavigate();
  const { token } = useAuth();
  const { salaId, statusSala } = location.state as {
    salaId: number;
    statusSala: string;
  };

  const [premios, setPremios] = useState<Premio[]>([]);
  const [loading, setLoading] = useState(true);

  const formatCurrency = (value: number): string => {
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(value);
  };

  const parseCurrency = (value: string): number => {
    return parseFloat(value.replace(/\D/g, "")) / 100;
  };

  useEffect(() => {
    const fetchPremios = async () => {
      try {
        const response = await http.get(`/premios/sala/${salaId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const premiosComNumeroConcurso = response.data.conteudo.map(
          (premio: Premio) => ({
            ...premio,
            numeroConcurso: premio.numeroConcurso || 0,
            tipoPremio: premio.tipoPremio.idTipoPremio,
          })
        );

        setPremios(premiosComNumeroConcurso);
        setLoading(false);
      } catch (error) {
        console.error("Erro ao buscar prêmios:", error);
        setLoading(false);
      }
    };

    fetchPremios();
  }, [salaId, token]);

  const handleInputChange = (
    index: number,
    field: keyof Premio,
    value: string | number
  ) => {
    setPremios((prevPremios) =>
      prevPremios.map((premio, i) =>
        i === index ? { ...premio, [field]: value } : premio
      )
    );
  };

  const handleSave = async () => {
    try {
      const premiosParaEnviar = premios.map((premio) => ({
        premioId: premio.premioId,
        descricao: premio.descricao,
        valor: premio.valor,
        salaId: salaId,
        concursoId: premio.concursoId,
        tipoPremio: premio.tipoPremio,
      }));

      await http.put("/premios/altera", premiosParaEnviar, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      navigate("/award");
    } catch (error) {
      console.error("Erro ao salvar prêmios:", error);
    }
  };

  const getStatusColor = (status: string): string => {
    switch (status) {
      case "Aberta":
        return "green";
      case "Em Andamento":
        return "yellow";
      case "Finalizada":
        return "red";
      default:
        return "gray";
    }
  };

  const getStatusText = (status: string) => {
    if (status === "Aberta") {
      return "Vendas Abertas";
    }
    if (status === "Finalizada") {
      return "Finalizado";
    }
    return status;
  };

  return (
    <Container
      bg="white"
      borderRadius="md"
      boxShadow="xl"
      mx="auto"
      mt={10}
      p={6}
      mb="5%"
    >
      <Flex align="center" mb={4}>
        <IconButton
          bg="#38A169"
          color="white"
          aria-label="Voltar"
          icon={<ArrowBackIcon />}
          variant="ghost"
          onClick={() => navigate(-1)}
        />
        <Heading as="h2" size="lg" ml={4}>
          Editar Prêmios
        </Heading>
      </Flex>
      <Box mb={4}>
        <Text mb={2}>Número do Bolão:</Text>
        <Input value={salaId} isReadOnly />
      </Box>

      <Box mb={4}>
        <Text>
          Status da Sala:
          <Badge ml={2} colorScheme={getStatusColor(statusSala)}>
            {getStatusText(statusSala) || "Status não disponível"}
          </Badge>
        </Text>
      </Box>

      <Stack spacing={6} mt={7}>
        {premios.length === 0 ? (
          <Text textAlign="center" color="gray.500" fontSize="lg">
            Prêmios ainda não cadastrados
          </Text>
        ) : (
          premios.map((premio, index) => (
            <Box key={premio.premioId} mb={6}>
              <Heading as="h3" size="md" textAlign="center" mb={5}>
                Prêmio {premio.tipoPremio.descricao}
                {premio.concursoId ? ` - Concurso ${premio.concursoId}` : ""}
              </Heading>
              <Flex mb={4}>
                <Box flex="1" mr={2}>
                  <Input
                    placeholder="Valor do Prêmio"
                    borderColor="gray.300"
                    type="text"
                    value={formatCurrency(premio.valor)}
                    onChange={(e) =>
                      handleInputChange(
                        index,
                        "valor",
                        parseCurrency(e.target.value)
                      )
                    }
                  />
                </Box>
                <Box flex="1" ml={2}>
                  <Input
                    placeholder="Descrição do Prêmio"
                    borderColor="gray.300"
                    type="text"
                    value={premio.descricao}
                    onChange={(e) =>
                      handleInputChange(index, "descricao", e.target.value)
                    }
                  />
                </Box>
                <IconButton
                  ml={2}
                  mt={1}
                  aria-label="Remover prêmio"
                  icon={<CloseIcon />}
                  size="sm"
                  colorScheme="red"
                  onClick={() => {
                    setPremios((prevPremios) =>
                      prevPremios.filter((_, i) => i !== index)
                    );
                  }}
                />
              </Flex>
            </Box>
          ))
        )}
      </Stack>

      <Flex justifyContent="center" mt={7}>
        <Button colorScheme="green" onClick={handleSave}>
          Salvar
        </Button>
      </Flex>
    </Container>
  );
}
