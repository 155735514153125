import {
  CircularProgress,
  Container,
  Flex,
  HStack,
  IconButton,
  Text,
  Badge,
  Box,
  Button,
  Stack,
  Select,
  Input,
} from "@chakra-ui/react";
import { RiPencilLine } from "react-icons/ri";
import { useState, useEffect } from "react";
import { IColumn } from "../../types/componentes/table";
import { Table } from "../../core/components/table";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/authContext";
import { http } from "../../services/http";
import { IoEyeOutline } from "react-icons/io5";

interface ICoupon {
  cupomId: number;
  descricao: string;
  quantidadeLimitada: number;
  quantidadeUtilizada: number;
  valor: number;
}

export function Coupons() {
  const [coupons, setCoupons] = useState<ICoupon[]>([]);
  const [filteredCoupons, setFilteredCoupons] = useState<ICoupon[]>([]);
  const [filterType, setFilterType] = useState<string>("");
  const [filterValue, setFilterValue] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { token } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    http
      .get("/cupom/", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setCoupons(response.data.conteudo);
        setFilteredCoupons(response.data.conteudo);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Erro ao buscar cupons:", error);
        setIsLoading(false);
      });
  }, [token]);

  useEffect(() => {
    if (filterType && filterValue) {
      const filteredData = coupons.filter((coupon) => {
        switch (filterType) {
          case "descricao":
            return coupon.descricao
              .toLowerCase()
              .includes(filterValue.toLowerCase());
          case "valor":
            return coupon.valor.toString().includes(filterValue);
          default:
            return true;
        }
      });
      setFilteredCoupons(filteredData);
    } else {
      setFilteredCoupons(coupons);
    }
  }, [filterType, filterValue, coupons]);

  function handleEditClick(cupomId: number) {
    const selectedCoupon = coupons.find((coupon) => coupon.cupomId === cupomId);
    if (selectedCoupon) {
      navigate("/editCupom", { state: selectedCoupon });
    }
  }

  function handleAddClick() {
    navigate("/addCupom");
  }

  const columns: IColumn<ICoupon>[] = [
    {
      label: "Descrição",
      render: (coupon) => <Text>{coupon.descricao}</Text>,
      width: 35,
    },
    {
      label: "Quantidade Limitada",
      render: (coupon) => <Text>{coupon.quantidadeLimitada}</Text>,
      width: 25,
    },
    {
      label: "Quantidade Utilizada",
      render: (coupon) => (
        <Text>{coupon.quantidadeUtilizada ?? 0}</Text>
      ),
      width: 25,
    },
    
    {
      label: "Percentual",
      render: (coupon) => <Text>{coupon.valor} %</Text>,
      width: 20,
    },
    {
      label: <Text fontWeight="bold">Ações</Text>,
      render: (coupon) => (
        <HStack justifyContent="center">
          <IconButton
            aria-label="edit-coupon"
            backgroundColor="#16A34A"
            colorScheme="#16A34A"
            color="white"
            onClick={() => handleEditClick(coupon.cupomId)}
          >
            <RiPencilLine size={20} />
          </IconButton>
        </HStack>
      ),
      width: 20,
    },
  ];

  return isLoading ? (
    <Flex flex="1" alignItems="center" justifyContent="center">
      <CircularProgress color="green" isIndeterminate />
    </Flex>
  ) : (
    <Flex flex="1" flexDirection="column" paddingY={5}>
      <Container
        borderRadius={10}
        bg={"gray.200"}
        maxWidth={["100vw", "60vw"]}
        display="flex"
        flexDirection="column"
        gap={10}
        mt={6}
      >
        <Box>
          <Text
            mb={5}
            marginTop={5}
            fontSize={["2xl", "3xl"]}
            textAlign="center"
            fontWeight="bold"
          >
            Cupom Desconto
          </Text>
          <Text fontSize="20px" fontWeight="bold" textAlign="center" mb={3}>
            Buscar por:
          </Text>
          <Stack direction="row" spacing={2} justify="center">
            <Select
              border="1px solid #979489b3"
              bg="white"
              placeholder="Selecione o Filtro"
              value={filterType}
              onChange={(e) => {
                setFilterType(e.target.value);
                setFilterValue("");
              }}
              maxW="200px"
            >
              <option value="descricao"> Descrição</option>
              <option value="valor">Valor</option>
            </Select>
            {filterType && (
              <Input
                border="1px solid #979489b3"
                bg="white"
                placeholder="Digite o valor"
                value={filterValue}
                onChange={(e) => setFilterValue(e.target.value)}
                maxW="200px"
              />
            )}
          </Stack>
        </Box>

        <Flex flexDirection="column" width="full" alignItems="flex-end" pr={5}>
          <Button
            mb={5}
            backgroundColor="#16A34A"
            colorScheme="#16A34A"
            onClick={handleAddClick}
          >
            <Text color="#fff">Adicionar Cupom</Text>
          </Button>
          <Table<ICoupon> columns={columns} rows={filteredCoupons} />
        </Flex>
      </Container>
    </Flex>
  );
}
