import { useState, useEffect } from "react";
import {
  Flex,
  Text,
  Box,
  CircularProgress,
  Badge,
  Container,
  Heading,
  IconButton,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { http } from "../../services/http";
import { useAuth } from "../../contexts/authContext";
import { ArrowBackIcon } from "@chakra-ui/icons";

export interface IAward {
  awardId: number;
  numeroConcurso: number | null;
  categoria: string;
  valor: number;
  descricao: string;
}

export function ViewAwards() {
  const location = useLocation();
  const { salaId, numeroConcurso } = location.state as {
    salaId: number;
    numeroConcurso: number | null;
  };
  const [awards, setAwards] = useState<IAward[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { token } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (salaId) {
      http
        .get(`/premios/sala/${salaId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          const formattedAwards = response.data.conteudo.map((item: any) => ({
            awardId: item.premioId,
            numeroConcurso: numeroConcurso,
            categoria: item.tipoPremio.descricao,
            valor: item.valor,
            descricao: item.descricao,
          }));
          setAwards(formattedAwards);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching awards:", error);
          setIsLoading(false);
        });
    }
  }, [salaId, token, numeroConcurso]);

  const getStatusColor = (categoria: string): string => {
    switch (categoria) {
      case "Principal":
        return "green";
      case "Zero Acertos":
        return "yellow";
      case "Top compradores":
        return "blue";
      case "concurso":
        return "purple";
      default:
        return "gray";
    }
  };

  if (isLoading) {
    return (
      <Flex flex="1" alignItems="center" justifyContent="center">
        <CircularProgress color="#16A34A" isIndeterminate />
      </Flex>
    );
  }

  return (
    <Container
      bg="white"
      borderRadius="md"
      boxShadow="xl"
      mx="auto"
      mt={10}
      p={6}
      w="full"
      mb="5%"
      maxW={{ base: "full", md: "80%", lg: "50%" }}
    >
      <Flex align="center" mb={4}>
        <IconButton
          bg="#38A169"
          color="white"
          aria-label="Voltar"
          icon={<ArrowBackIcon />}
          variant="ghost"
          onClick={() => navigate(-1)}
        />
        <Heading as="h2" size="lg" ml={4}>
          Detalhes dos Prêmios
        </Heading>
      </Flex>

      <Box mt={6}>
        {awards.length > 0 ? (
          <Box overflowX="auto">
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th textAlign="center">Categoria do Prêmio</Th>
                  <Th textAlign="center">Valor do Prêmio</Th>
                </Tr>
              </Thead>
              <Tbody>
                {awards.map((award) => (
                  <Tr key={award.awardId}>
                    <Td textAlign="center">
                      <Badge colorScheme={getStatusColor(award.categoria)}>
                        {award.categoria}
                      </Badge>
                    </Td>
                    <Td textAlign="center">
                      R${" "}
                      {award.valor.toLocaleString("pt-BR", {
                        minimumFractionDigits: 2,
                      })}{" "}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        ) : (
          <Text>Nenhum prêmio disponível para esta sala.</Text>
        )}
      </Box>
    </Container>
  );
}
