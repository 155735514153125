import { useState, useEffect } from "react";
import {
  Box,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Stack,
  Heading,
  Input,
  Select,
  Center,
  SimpleGrid,
  IconButton,
  Flex,
} from "@chakra-ui/react";
import { ArrowLeftIcon, ArrowRightIcon } from "@chakra-ui/icons";
import { useAuth } from "../../contexts/authContext";
import { http } from "../../services/http";

type StatusSala = {
  id: number;
  descricao: string;
};

type Financeiro = {
  salaId: number;
  statusSala: StatusSala;
  dataInicio: string;
  dataFim: string | null;
  concursos: number[];
  valorTotalPremiacoes: number;
  quantidadePalpitesGerados: number;
  quantidadePalpitesGeradosPagos: number;
  valorTotalPalpitesGerados: number;
  valorTotalPalpitesGeradosPagos: number;
};

const Financeiro = () => {
  const [financialData, setFinancialData] = useState<Financeiro[]>([]);
  const [filteredData, setFilteredData] = useState<Financeiro[]>([]);
  const [filterType, setFilterType] = useState("salaId");
  const [search, setSearch] = useState("");
  const { token } = useAuth();

  useEffect(() => {
    const fetchFinancialData = async () => {
      try {
        const response = await http.get("/salas/financeiro", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const result = await response.data;
        setFinancialData(result.conteudo);
        setFilteredData(result.conteudo);
      } catch (error) {
        console.error("Erro na requisição:", error);
      }
    };
    fetchFinancialData();
  }, [token]);

  useEffect(() => {
    const filtered = financialData.filter((data) => {
      if (filterType === "salaId") {
        return data.salaId.toString().includes(search);
      } else if (filterType === "concursos") {
        return data.concursos.some((concurso) =>
          concurso.toString().includes(search)
        );
      }
      return false;
    });
    setFilteredData(filtered);
  }, [search, filterType, financialData]);

  const formatNumber = (number: number): string => {
    return number.toString().padStart(4, "0");
  };

  const getPremio = (premio: number): string => {
    return premio.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
  };

  const getStatusText = (status: string) => {
    if (status === "Aberta") {
      return "Vendas Abertas";
    }
    if (status === "Finalizada") {
      return "Finalizado";
    }
    return status;
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case "Aberta":
        return "#068C1C";
      case "Em Andamento":
        return "#FFDF01";
      case "Finalizada":
        return "#DB0101";
      default:
        return "gray";
    }
  };

  const getStatus = (status: string) => {
    switch (status) {
      case "Aberta":
        return "white";
      case "Em Andamento":
        return "#068D16";
      case "Finalizada":
        return "white";
      default:
        return "black";
    }
  };

  const formatDate = (dateString: string) => {
    const [year, month, day] = dateString.split("-");
    return `${day}-${month}-${year}`;
  };

  return (
    <Stack spacing={4} p="4" mx="auto" maxW="100%">
      <Heading textAlign="center" color="white">
        Relatório Financeiro
      </Heading>

      <Text color="white" fontSize="18px" textAlign="center" fontWeight="bold">
        Filtros de buscas por:
      </Text>

      <Center mb={5}>
        <SimpleGrid
          columns={{ base: 1, md: 2 }}
          spacing={4}
          w={{ base: "90%", sm: "60%", md: "70%", lg: "30%" }}
          alignItems="center"
        >
          <Select
            bg="white"
            value={filterType}
            onChange={(e) => {
              setFilterType(e.target.value);
              setSearch("");
            }}
            width="100%"
          >
            <option value="salaId">Número do Bolão</option>
            <option value="concursos">Concursos</option>
          </Select>
          <Input
            bg="white"
            placeholder={`Digite o valor`}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            width="100%"
          />
        </SimpleGrid>
      </Center>
      {filteredData.length === 0 ? (
        <Center>
          <Text color="white" fontSize="18px" fontWeight="bold">
            Nenhum resultado encontrado
          </Text>
        </Center>
      ) : (
        filteredData.map((item, index) => (
          <Box
            key={index}
            bg="white"
            borderRadius="5px"
            overflow="hidden"
            position="relative"
            w="100%"
            mx="auto"
            mb={6}
          >
            <Box>
              <Box bg="#006391" py={2}>
                <Text color="white" textAlign="center" fontWeight="bold">
                  Bolão - {formatNumber(item.salaId)}
                </Text>
              </Box>

              <Box textAlign="center" my={2}>
                <Box textAlign="center" my="3">
                  <Box
                    bg={getStatusColor(item.statusSala.descricao)}
                    color={getStatus(item.statusSala.descricao)}
                    fontWeight="bold"
                    mx="auto"
                    pr={1}
                    pl={1}
                    width="fit-content"
                  >
                    {getStatusText(item.statusSala.descricao)}{" "}
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box overflowX="auto">
              <Table variant="simple" maxW="100%" bg="#DBEFFF">
                <Thead>
                  <Tr bg="#006391">
                    <Th color="white" textAlign="center">
                      Data Início
                    </Th>
                    <Th color="white" textAlign="center">
                      Data Fim
                    </Th>
                    <Th color="white" textAlign="center">
                      Concursos
                    </Th>
                    <Th color="white" textAlign="center">
                      Valor do Prêmio (Valor total em premiações)
                    </Th>
                    <Th color="white" textAlign="center">
                      Quantidade de Palpites Gerados
                    </Th>
                    <Th color="white" textAlign="center">
                      Quantidade de Palpites Gerados e Pagos
                    </Th>
                    <Th color="white" textAlign="center">
                      Valor Total de Palpites Gerados
                    </Th>{" "}
                    <Th color="white" textAlign="center">
                      Valor Total de Palpites Gerados e Pagos
                    </Th>
                  </Tr>
                </Thead>
                <Tbody whiteSpace="nowrap">
                  <Tr borderBottom="8px solid #fff" borderTop="8px solid #fff">
                    <Td textAlign="center" color="#006391" fontWeight="bold">
                      {formatDate(item.dataInicio)}
                    </Td>
                    <Td textAlign="center" color="#006391" fontWeight="bold">
                      {item.dataFim ? formatDate(item.dataFim) : "-"}
                    </Td>

                    <Td textAlign="center" color="#006391" fontWeight="bold">
                      {item.concursos.map((concurso) => (
                        <Box key={concurso}>{concurso}</Box>
                      ))}
                    </Td>
                    <Td textAlign="center" color="#006391" fontWeight="bold">
                      {getPremio(item.valorTotalPremiacoes)}
                    </Td>
                    <Td textAlign="center" color="#006391" fontWeight="bold">
                      {item.quantidadePalpitesGerados}
                    </Td>
                    <Td textAlign="center" color="#006391" fontWeight="bold">
                      {item.quantidadePalpitesGeradosPagos}
                    </Td>
                    <Td textAlign="center" color="#006391" fontWeight="bold">
                      {getPremio(item.valorTotalPalpitesGerados)}
                    </Td>
                    <Td textAlign="center" color="#006391" fontWeight="bold">
                      {getPremio(item.valorTotalPalpitesGeradosPagos)}
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </Box>
          </Box>
        ))
      )}
    </Stack>
  );
};

export default Financeiro;
