import { Box, Text, Input, Button, useToast } from "@chakra-ui/react";
import { useState, ChangeEvent } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import FloatingWhatsAppButton from "../components/floatingWhatsAppButton";
import { http } from "../services/http";

export const CodeVerify = () => {
  const [code, setCode] = useState<string[]>(new Array(6).fill(""));
  const [errorMessage, setErrorMessage] = useState<string>("");
  const location = useLocation();
  const [successMessage, setSuccessMessage] = useState<string>("");
  const navigate = useNavigate();
  const toast = useToast();
  const userId = location.state?.userId;

  const handleCodeChange = (element: HTMLInputElement, index: number) => {
    if (isNaN(Number(element.value))) return;

    setCode([...code.map((d, idx) => (idx === index ? element.value : d))]);

    if (element.nextSibling && element.value) {
      (element.nextSibling as HTMLInputElement).focus();
    }
  };

  const handleSubmit = async () => {
    try {
      const response = await http.post("/codigo-verificacao/valida-codigo", {
        codigoVerificacao: code.join(""),
        userId,
      });
      if (response.data.conteudo) {
        setSuccessMessage("Código validado com sucesso!");
        toast({
          title: "Código validado com sucesso!",
          status: "success",
          duration: 7000,
          isClosable: true,
        });
        navigate("/changePassword", { state: { userId } });
      } else {
        setErrorMessage("Código incorreto.");
        toast({
          title: "Código incorreto",
          description: "O código informado está incorreto.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      setErrorMessage("Erro ao verificar código.");
    }
  };

  return (
    <Box
      bg="#E5E7EB"
      borderRadius="md"
      boxShadow="md"
      maxW={{ base: "90%", md: "md" }}
      mx="auto"
      mt={{ base: 10, md: 20 }}
      p={{ base: 3, md: 5 }}
      w="100%"
      marginTop="6%"
      marginBottom="6%"
    >
      <Box mb={4}>
        <Text mb={2} textAlign="center" fontWeight="bold">
          Verificação de Código
        </Text>
        <Box display="flex" justifyContent="space-between">
          {code.map((data, index) => (
            <Input
              mb={5}
              borderColor="#BFBFBF"
              key={index}
              type="text"
              maxLength={1}
              width="3rem"
              height="3rem"
              textAlign="center"
              fontSize="2xl"
              bg="white"
              borderRadius="md"
              value={data}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleCodeChange(e.target, index)
              }
              onFocus={(e) => e.target.select()}
            />
          ))}
        </Box>
        {errorMessage && (
          <Text color="red.500" mb={4} fontSize="md">
            {errorMessage}
          </Text>
        )}
      </Box>
      <Button
        colorScheme="green"
        mb={{ base: 5, md: 0 }}
        w="100%"
        bg="#16A34A"
        onClick={handleSubmit}
      >
        Confirmar
      </Button>{" "}
      <FloatingWhatsAppButton />
    </Box>
  );
};
