import { ArrowBackIcon } from "@chakra-ui/icons";
import {
  Box,
  Container,
  Flex,
  Heading,
  IconButton,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/authContext";
import { http } from "../../services/http";
import { useEffect, useState } from "react";

interface Rule {
  regraId: number;
  descricao: string;
  legenda: string;
}

export function ViewRuleScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const [rule, setRule] = useState<Rule | null>(null);
  const [loading, setLoading] = useState(true);
  const toast = useToast();
  const { token } = useAuth();

  useEffect(() => {
    const fetchRule = async () => {
      const state = location.state as { regraId: number } | undefined;
       if (state && state.regraId) {
        try {
          const response = await http.get(`/regras/${state.regraId}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
 
          // Ajuste para acessar o campo correto
          setRule(response.data.conteudo);
        } catch (error) {
          console.error("Erro ao buscar regra:", error);
          toast({
            title: "Erro ao buscar regra.",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        } finally {
          setLoading(false);
        }
      } else {
        toast({
          title: "ID da regra não fornecido.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        setLoading(false);
      }
    };

    fetchRule();
  }, [location.state, token, toast]);

  if (loading) {
    return (
      <Container
        bg="white"
        borderRadius="md"
        boxShadow="xl"
        mx="auto"
        mt={10}
        p={6}
        mb="5%"
      >
        <Text>Carregando...</Text>
      </Container>
    );
  }

  return (
    <Container
      bg="white"
      borderRadius="md"
      boxShadow="xl"
      mx="auto"
      mt={10}
      p={6}
      mb="5%"
    >
      <Flex align="center" mb={4}>
        <IconButton
          bg="#38A169"
          color="white"
          aria-label="Voltar"
          icon={<ArrowBackIcon />}
          variant="ghost"
          onClick={() => navigate(-1)}
        />
        <Heading as="h2" size="lg" ml={4}>
          Visualizar Regra
        </Heading>
      </Flex>
      {rule ? (
        <Box>
          <Box>
            <Text fontWeight="bold">Título:</Text>
            <Text mb={4}>{rule.legenda}</Text>
          </Box>
          <Box mb={4}>
            <Text fontWeight="bold">Descrição:</Text>
            <Text>{rule.descricao}</Text>
          </Box>
        </Box>
      ) : (
        <Text>Regra não encontrada.</Text>
      )}
    </Container>
  );
}
