import {
  CircularProgress,
  Container,
  Flex,
  HStack,
  IconButton,
  Text,
  Badge,
  Box,
  Button,
  Stack,
  Select,
  Input,
} from "@chakra-ui/react";
import { RiPencilLine } from "react-icons/ri";
import { useState, useEffect } from "react";
import { IColumn } from "../../types/componentes/table";
import { Table } from "../../core/components/table";
import { IRooms } from "../../types/users/interfaces";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/authContext";
import { http } from "../../services/http";
import { format } from "date-fns";
import { IoEyeOutline } from "react-icons/io5";

export function Rooms() {
  const [rooms, setRooms] = useState<IRooms[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { token } = useAuth();
  const [filterType, setFilterType] = useState<string>("");
  const [filterValue, setFilterValue] = useState<string>("");
  const navigate = useNavigate();

  useEffect(() => {
    http
      .get("/salas/", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const activeRooms = response.data.conteudo.filter(
          (room: IRooms) => room.ativo
        );
        setRooms(activeRooms);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching rooms:", error);
        setIsLoading(false);
      });
  }, [token]);

  function handleEditClick(roomsId: number) {
    const selectedRoom = rooms.find((room) => room.salaId === roomsId);
    if (selectedRoom) {
      navigate("/editScreen", { state: selectedRoom });
    }
  }

  function handleViewClick(salaId: number) {
    navigate("/viewRoom", { state: { salaId } });
  }

  const getStatusText = (status: string) => {
    if (status === "Aberta") {
      return "Vendas Abertas";
    }
    if (status === "Finalizada") {
      return "Finalizado";
    }
    return status;
  };

  const filteredRooms = rooms.filter((room) => {
    if (filterType === "idSala") {
      return room.salaId?.toString().includes(filterValue);
    } else if (filterType === "valorPalpite") {
      return room.valorPalpite?.toString().includes(filterValue);
    }
    return true;
  });

  const sortedRooms = filteredRooms.sort((a, b) => a.salaId - b.salaId);

  const columns: IColumn<IRooms>[] = [
    {
      label: <Text fontWeight="bold">Bolão</Text>,
      render: (rooms) => {
        const formattedSalaId = rooms.salaId.toString().padStart(4, "0");
        return <Text color="black">{formattedSalaId}</Text>;
      },
      width: 15,
    },
    {
      label: "Data de início ",
      render: (rooms) => (
        <Text>{format(new Date(rooms.dataInicio), "dd/MM/yyyy")}</Text>
      ),
      width: 20,
    },
    {
      label: "Data Fim das Vendas",
      render: (rooms) => (
        <Text>{format(new Date(rooms.dataFimVendas), "dd/MM/yyyy")}</Text>
      ),
      width: 20,
    },
    {
      label: "Valor do Palpite",
      render: (rooms) => (
        <Text>
          R${" "}
          {rooms.valorPalpite.toLocaleString("pt-BR", {
            minimumFractionDigits: 2,
          })}{" "}
        </Text>
      ),
      width: 15,
    },
    {
      label: "Status da Sala",
      render: (rooms) => (
        <Badge colorScheme={getStatusColor(rooms.statusSala?.descricao)}>
          {getStatusText(rooms.statusSala?.descricao)}
        </Badge>
      ),
      width: 20,
    },
    {
      label: <Text fontWeight="bold">Ações</Text>,
      render: (rooms) => (
        <HStack>
          <IconButton
            aria-label="update-ib"
            colorScheme="#16A34A"
            bgColor="#16A34A"
            color="white"
            onClick={() => handleEditClick(rooms.salaId)}
            isDisabled={rooms.statusSala?.descricao === "Finalizada"}
          >
            <RiPencilLine size={20} />
          </IconButton>
          <IconButton
            aria-label="view-ib"
            colorScheme="#16A34A"
            bgColor="#16A34A"
            color="white"
            onClick={() => handleViewClick(rooms.salaId)}
          >
            <IoEyeOutline size={20} />
          </IconButton>
        </HStack>
      ),
      width: 30,
    },
  ];

  function openModal(): Promise<void> {
    return new Promise<void>((resolve) => {
      navigate("/register-rooms");
      resolve();
    });
  }

  function getStatusColor(status: string): string {
    switch (status) {
      case "Aberta":
        return "green";
      case "Em Andamento":
        return "yellow";
      case "Finalizada":
        return "red";
      default:
        return "gray";
    }
  }

  return isLoading ? (
    <Flex flex="1" alignItems="center" justifyContent="center">
      <CircularProgress color="#16A34A" isIndeterminate />
    </Flex>
  ) : (
    <Flex flex="1" flexDirection="row" paddingY={5}>
      <Container
        borderRadius={10}
        bg={"gray.200"}
        maxWidth={["100vw", "60vw"]}
        display="flex"
        flexDirection="column"
        gap={10}
        mt={6}
      >
        <Box>
          <Text
            fontSize="20px"
            mt={5}
            fontWeight="bold"
            textAlign="center"
            mb={2}
          >
            Buscar por:
          </Text>
          <Stack direction="row" spacing={2} justify="center">
            <Select
              border="1px solid #979489b3"
              placeholder="Selecione o Filtro"
              bg="white"
              value={filterType}
              onChange={(e) => {
                setFilterType(e.target.value);
                setFilterValue("");
              }}
              maxW="200px"
            >
              <option value="idSala">Nº do Bolão</option>
              <option value="valorPalpite">Valor do Palpite</option>
            </Select>
            {filterType && (
              <Input
                border="1px solid #979489b3"
                placeholder="Digite o valor"
                bg="white"
                value={filterValue}
                onChange={(e) => setFilterValue(e.target.value)}
                maxW="200px"
              />
            )}
          </Stack>
        </Box>
        <Flex flexDirection="column" width="full" alignItems="flex-end">
          <Button
            mb={5}
            backgroundColor="#16A34A"
            colorScheme="#16A34A"
            width={["full", "32"]}
            onClick={openModal}
          >
            <Text color="#fff">Adicionar </Text>
          </Button>
          <Table<IRooms> columns={columns} rows={sortedRooms} />
        </Flex>
      </Container>
    </Flex>
  );
}
