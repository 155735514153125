import {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
} from "react";
import { RoomsRepository } from "../domain/repositories/rooms";
 import { IRooms } from "../types/users/interfaces";

interface IContextValue {
  rooms: IRooms[];
  isLoading: boolean;
  findAll: () => Promise<void>;
  filter: (search: string) => Promise<void>;
  create: (modalValues: Omit<IRooms, "salaId">) => Promise<void>;
  update: (payload: IRooms) => Promise<void>;
  remove: (salaId: number) => Promise<void>;
  //changeStatus: (salaId: number) => Promise<void>;
}

const roomsContext = createContext<IContextValue>({
  rooms: [],
  isLoading: false,
  findAll: async () => {},
  filter: async () => {},
  create: async () => {},
  update: async () => {},
  remove: async () => {},
  // changeStatus: async () => {},
});

interface IProps {
  children: ReactNode;
}

export function RoomsProvider({ children }: IProps) {
  const [rooms, setRooms] = useState<IRooms[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  async function findAll() {
    try {
      setIsLoading(true);
      const roomList = await RoomsRepository.findAll();
      setRooms(roomList);

      setIsLoading(false);
    } catch (error) {
      console.error("Erro ao buscar salas:", error);
      setIsLoading(false);
    }
  }

  async function create(modalValues: Omit<IRooms, "salaId">) {
    try {
      setIsLoading(true);
      //const newRoom = await RoomsRepository.create(modalValues);
    //  setRooms((prevRooms) => [...prevRooms, newRoom]);
      setIsLoading(false);

      findAll();
    } catch (error) {
      console.error("Erro ao criar sala:", error);
      setIsLoading(false);
    }
  }

  async function update(payload: IRooms) {
    try {
      setIsLoading(true);
     // await RoomsRepository.update(payload.salaId, payload);
      setRooms((prevState) =>
        prevState.map((room) =>
          room.salaId === payload.salaId ? { ...room, ...payload } : room
        )
      );
      setIsLoading(false);

      findAll();
    } catch (error) {
      console.error("Erro ao atualizar sala:", error);
      setIsLoading(false);
    }
  }

  // async function changeStatus(id: string) {
  //   setRooms((prevState) => {
  //     return prevState.map((room) => {
  //       if (room.salaId === id) {
  //         // Verifique o status atual e mude para o próximo estado
  //         switch (room.status) {
  //           case EStatusSala.ATIVA:
  //             return { ...room, status: EStatusSala.ANDAMENTO };
  //           case EStatusSala.ANDAMENTO:
  //             return { ...room, status: EStatusSala.FINALIZADA };
  //           case EStatusSala.FINALIZADA:
  //             return { ...room, status: EStatusSala.ATIVA };
  //           default:
  //             return room; // Mantenha o status atual se não for nenhum dos três estados principais
  //         }
  //       }
  //       return room; // Mantenha outras salas inalteradas
  //     });
  //   });
  // }

  async function remove(salaId: number) {
    try {
      setIsLoading(true);
     // await RoomsRepository.delete(salaId);
      setRooms((prevRooms) =>
        prevRooms.filter((room) => room.salaId !== salaId)
      );
      setIsLoading(false);
      findAll();
    } catch (error) {
      console.error("Erro ao excluir sala:", error);
      setIsLoading(false);
    }
  }

  async function filter(search: string) {
    try {
      setIsLoading(true);
      const searchNumber = parseInt(search, 10);
      if (isNaN(searchNumber)) {
        await findAll();
      } else {
        setRooms((prevRooms) =>
          prevRooms.filter((room) => room.salaId === searchNumber)
        );
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Erro ao filtrar salas:", error);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    findAll();
  }, []);

  return (
    <roomsContext.Provider
      value={{
        rooms,
        isLoading,
        findAll,
        filter,
        create,
        update,
        remove,
        //changeStatus,
      }}
    >
      {children}
    </roomsContext.Provider>
  );
}

export function useRooms() {
  return useContext(roomsContext);
}
