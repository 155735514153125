import {
  Box,
  Button,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  IconButton,
  Input,
  useToast,
} from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { http } from "../../services/http";
import { useAuth } from "../../contexts/authContext";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { PercentageInput } from "../../components/percentageInput";

export function EditCoupon() {
  const location = useLocation();
  const { token } = useAuth();
  const navigate = useNavigate();
  const toast = useToast();

  const [descricao, setDescricao] = useState<string>("");
  const [quantidadeLimitada, setQuantidadeLimitada] = useState<
    number | undefined
  >(undefined);
  const [valor, setValor] = useState<string | undefined>(undefined);
  const { cupomId } = location.state;
  const [quantidadeUtilizada, setQuantidadeUtilizada] = useState<
    number | undefined
  >(undefined);

  const handlePercentageChange = (newValue: string) => {
    setValor(newValue);
  };

  useEffect(() => {
    http
      .get(`/cupom/${cupomId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const { descricao, quantidadeLimitada, valor, quantidadeUtilizada } =
          response.data.conteudo;

        setDescricao(descricao);
        setQuantidadeLimitada(quantidadeLimitada);
        setQuantidadeUtilizada(quantidadeUtilizada);
        setValor(String(valor));
      })
      .catch((error) => {
        console.error("Erro ao buscar cupom:", error);
        toast({
          title: "Erro ao buscar cupom.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  }, [cupomId, token, toast]);

  const handleSaveCoupon = () => {
    const updatedCoupon = {
      descricao,
      cupomId,
      quantidadeLimitada,
      valor: Number(valor),
    };

    http
      .put(`/cupom/altera`, updatedCoupon, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        toast({
          title: "Cupom atualizado com sucesso.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        navigate("/cupom");
      })
      .catch((error) => {
        console.error("Erro ao atualizar cupom:", error.response);

        const errorMessage =
          error.response?.data?.erros?.[0] || "Erro desconhecido";

        toast({
          title: "Erro ao atualizar cupom.",
          description: errorMessage,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  };

  return (
    <Flex flex="1" justifyContent="center" alignItems="center" padding={5}>
      <Container
        bg="white"
        borderRadius="md"
        boxShadow="xl"
        mx="auto"
        mt={10}
        p={6}
        mb="5%"
      >
        <Flex alignItems="center" mb={5}>
          <IconButton
            bg="#38A169"
            color="white"
            aria-label="Voltar"
            icon={<ArrowBackIcon />}
            variant="ghost"
            onClick={() => navigate(-1)}
          />
          <Heading as="h2" size="lg" ml={4}>
            Editar Cupom
          </Heading>
        </Flex>
        {valor ? (
          <Box mb={4}>
          <FormControl mb={4}>
            <FormLabel>Descrição do Cupom</FormLabel>
            <Input value={descricao} isReadOnly />
          </FormControl>
          <FormControl mb={4}>
            <FormLabel>Quantidade Limitada</FormLabel>
            <Input
              type="number"
              value={quantidadeLimitada?.toString()}
              onChange={(e) => setQuantidadeLimitada(Number(e.target.value))}
            />
          </FormControl>
          <FormControl mb={4}>
            <FormLabel>Quantidade Utilizada</FormLabel>
            <Input value={quantidadeUtilizada?.toString()} isReadOnly />
          </FormControl>
          <FormControl mb={4}>
            <FormLabel>Valor do Cupom</FormLabel>
            <PercentageInput
              placeholder="Valor do Cupom"
              value={valor ?? ''}
              onChange={handlePercentageChange}
          />
          </FormControl>

          <Button
            colorScheme="green"
            onClick={handleSaveCoupon}
            width="full"
            mt={4}
          >
            Salvar Alterações
          </Button>
        </Box>
        ) : null}
      </Container>
    </Flex>
  );
}
