import { Box, Flex, useDisclosure } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";
import { Footer } from "./components/footer";
import { Header } from "./components/header";
import Sidebar from "./components/sidebar";
import backgroundImage from "../../assets/imgfundo.png";

export function MainLayout() {
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <Box
      backgroundColor="rgb(9, 171, 37)"
      minHeight="100vh"
      display="flex"
      flexDirection="column"
      backgroundImage={`url(${backgroundImage})`}
      backgroundSize="contain"
      bgRepeat="repeat"
      backgroundPosition="center"
    >
      <Box flex="1">
        <Sidebar isOpen={isOpen} onClose={onClose} />
        <Header onOpen={onOpen} />
        <Flex
          direction="column"
          minHeight="calc(100vh - 220px)"
          px={[4, 6]}
          pt={4}
        >
          <Outlet />
        </Flex>
      </Box>
      <Footer />
    </Box>
  );
}
