import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../contexts/authContext";

interface ProtectedRouteProps {
  element: React.ReactElement;
  requiredPermission: "Admin" | "Cliente";
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  element,
  requiredPermission,
}) => {
  const { isAuthenticated, isAdmin, isCliente } = useAuth();
  const location = useLocation();

  if (!isAuthenticated) {
    return <Navigate to="/sign-in" state={{ from: location }} />;
  }

  if (requiredPermission === "Admin" && !isAdmin()) {
    return <Navigate to="/" />;
  }

  if (requiredPermission === "Cliente" && !isCliente()) {
    return <Navigate to="/" />;
  }

  return element;
};

export default ProtectedRoute;
