import { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Text,
  Button,
  IconButton,
  useBreakpointValue,
  HStack,
  Heading,
  Input,
  useToast,
} from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { BiCheck } from "react-icons/bi";
import CustomModal from "../components/modalAlert";
import { useAuth } from "../contexts/authContext";
import { http } from "../services/http";
import { QuotasData } from "../types/quotas/interface";
import { MdAddShoppingCart, MdClear } from "react-icons/md";
import { IoCloseSharp } from "react-icons/io5";
import CustomModalSala from "../components/modalSala";
import { IoMdCheckboxOutline } from "react-icons/io";
import FloatingWhatsAppButton from "../components/floatingWhatsAppButton";
import { CheckIcon } from "@chakra-ui/icons";
import { FaArrowsRotate } from "react-icons/fa6";

interface Prize {
  premioId: number;
  descricao: string;
  valor: number;
}

const Buy = () => {
  const [selectedNumbers, setSelectedNumbers] = useState<string[]>([]);
  const [selectedQuotas, setSelectedQuotas] = useState<QuotasData[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpenSala, setModalOpenSala] = useState(false);
  const { token } = useAuth();
  const location = useLocation();
  const [prizes, setPrizes] = useState<Prize[]>([]);
  const [numPalpites, setNumPalpites] = useState<number>(0);
  const { salaId, valorPalpite } = location.state || {
    salaId: 0,
    valorPalpite: 0,
  };

  const [modalContent, setModalContent] = useState({
    message: "",
  });
  const [modalContentSala, setModalContentSala] = useState({
    messageSala: "",
  });

  const { user } = useAuth();
  const numbersPerRow = useBreakpointValue({ base: 6, md: 7, "2xl": 8 }) ?? 0;
  const [coupon, setCoupon] = useState("");
  const [discount, setDiscount] = useState(0);
  const [validCoupon, setValidCoupon] = useState(true);
  const [appliedCoupon, setAppliedCoupon] = useState(false);
  const statusPagamento = 1;
  const statusCota = 1;
  const maxNumbers = 10;
  const maxQuotas = 5;
  const navigate = useNavigate();
  const toast = useToast();
  const [totalPrizeValue, setTotalPrizeValue] = useState<number>(0);
  const [discountAmount, setDiscountAmount] = useState(0);

  const handleNumberClick = (number: string) => {
    if (selectedNumbers.includes(number)) {
      setSelectedNumbers(selectedNumbers.filter((n) => n !== number));
    } else {
      const updatedNumbers = [...selectedNumbers, number];

      if (updatedNumbers.length <= maxNumbers) {
        setSelectedNumbers(updatedNumbers);

        if (updatedNumbers.length === maxNumbers) {
          const newQuota: QuotasData = {
            resultadoId: 0,
            numeros: updatedNumbers.join(","),
            statusCota: statusCota,
            statusPagamento: statusPagamento,
          };

          setSelectedQuotas([...selectedQuotas, newQuota]);
          setSelectedNumbers([]);
          addToCart();
        }
      } else {
        setModalContent({
          message: "Você já selecionou o número máximo de 10.",
        });
        setModalOpen(true);
      }
    }
  };

  const handleClearSelection = () => {
    setSelectedNumbers([]);
  };

  const calculateDiscountedValue = (totalValue: number, discount: number) => {
    if (discount > 0) {
      return totalValue - discount;
    }
    return totalValue;
  };

  const removeFromCart = (index: number) => {
    const updatedQuotas = [...selectedQuotas];
    updatedQuotas.splice(index, 1);
    setSelectedQuotas(updatedQuotas);
  };
  // const calculateCotaValue = (numCotas: number) => {
  //   if (numCotas === 0) return 0;
  //   else if (numCotas === 1) return 34.9;
  //   else if (numCotas === 2) return 33.9;
  //   else if (numCotas === 3) return 32.9;
  //   else if (numCotas === 4) return 31.9;
  //   else return 29.9;
  // };
  const calculateCotaValue = (numCotas: number, valorPalpite: number) => {
    return numCotas * valorPalpite;
  };

  const totalValue = calculateCotaValue(selectedQuotas.length, valorPalpite);

  const discountedValue = calculateDiscountedValue(totalValue, discount);

  const sendQuotasToServer = async (quotas: QuotasData[]) => {
    try {
      const formattedQuotas = quotas.map((quota) => ({
        ...quota,
        resultadoId: 0,
        userId: user?.userId,
        salaId: salaId,
      }));

      const response = await http.post("/cotas", formattedQuotas, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        const cotasIds: { cotasId: number }[] = response.data.conteudo.map(
          (cota: { cotasId: number }) => ({
            cotasId: cota.cotasId,
          })
        );

        const salaEncerrada = response.data.conteudo[0].salaEncerrda;

        if (salaEncerrada) {
          setModalContentSala({
            messageSala:
              "Sala encerrada. Não é possível prosseguir com a compra.",
          });
          setModalOpenSala(true);
        } else {
          const totalValue = calculateTotalValue();

          navigate(`/payment/${selectedQuotas.length}`, {
            state: {
              cotasIds,
              discountedValue,
              totalValue,
              coupon,
              salaId,
              valorPalpite,
            },
          });
        }
      }
    } catch (error) {
      // console.error("Erro ao enviar cotas:", error);
      setModalContent({
        message: "Erro ao enviar as cotas. Tente novamente mais tarde.",
      });
      setModalOpen(true);
    }
  };

  //navigate(`/payment/${selectedQuotas.length}/${totalValue}`);

  const handlePayment = () => {
    if (selectedQuotas.length === 0) {
      setModalContent({
        message:
          "Adicione cotas ao carrinho antes de prosseguir para o pagamento.",
      });
      setModalOpen(true);
      return;
    }

    sendQuotasToServer(selectedQuotas);
  };

  const addToCart = () => {
    if (selectedNumbers.length === maxNumbers) {
      const newQuota: QuotasData = {
        resultadoId: 0,
        numeros: selectedNumbers.join(","),
        statusCota: statusCota,
        statusPagamento: statusPagamento,
      };

      setSelectedQuotas([...selectedQuotas, newQuota]);
      setSelectedNumbers([]);
    }
  };

  const isNumberSelected = (number: string) => {
    return selectedNumbers.includes(number);
  };

  const generateNumbers = () => {
    const numbers = [];
    for (let i = 1; i <= 60; i++) {
      numbers.push(i < 10 ? `0${i}` : `${i}`);
    }
    return numbers;
  };

  const chunkArray = (array: string[], chunkSize: number) => {
    const chunkedArray = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunkedArray.push(array.slice(i, i + chunkSize));
    }
    return chunkedArray;
  };
  const calculateTotalValue = () => {
    const totalValue = valorPalpite * selectedQuotas.length;
    const discountedValue = appliedCoupon ? totalValue - discount : totalValue;
    return discountedValue;
  };

  const validateCoupon = async () => {
    try {
      const response = await http.post(
        "/cupom/valida/",
        {
          descricao: coupon,
          quantidadeCotas: selectedQuotas.length,
          salaId: salaId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        const cupom = response.data.conteudo;
        setDiscount(cupom.valorDesconto);
        setValidCoupon(true);
        setAppliedCoupon(true);
        toast({
          title: "Cupom válido",
          description: "O cupom foi aplicado com sucesso.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        setValidCoupon(false);
        toast({
          title: "Cupom inválido",
          description: "O cupom inserido não é válido.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      setValidCoupon(false);
      toast({
        title: "Erro",
        description: "Erro ao validar o cupom. Tente novamente.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const applyCoupon = async () => {
    if (!coupon) {
      toast({
        title: "Cupom inválido",
        description: "Por favor, insira um cupom.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    await validateCoupon();
  };

  const removeCoupon = () => {
    setDiscount(0);
    setAppliedCoupon(false);
    setCoupon("");
    toast({
      title: "Cupom removido",
      description: "O cupom foi removido com sucesso.",
      status: "info",
      duration: 3000,
      isClosable: true,
    });
  };
  useEffect(() => {
    if (!salaId || !valorPalpite) {
      navigate("/");
      return;
    }

    const fetchPrizes = async () => {
      try {
        const response = await http.get(`/premios/sala/${salaId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const prizes = response.data.conteudo;
        setPrizes(prizes);

        const totalValue = prizes.reduce((sum: number, prize: Prize) => {
          return sum + prize.valor;
        }, 0);

        setTotalPrizeValue(totalValue);
      } catch (error) {
        console.error("Failed to fetch prizes", error);
        toast({
          title: "Erro ao buscar prêmios",
          description: "Não foi possível carregar os prêmios.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    };

    fetchPrizes();
  }, [salaId, token, toast]);

  const generateRandomNumbers = (numPalpites: number) => {
    const allNumbers = Array.from({ length: 60 }, (_, i) => i + 1).map((num) =>
      num < 10 ? `0${num}` : `${num}`
    );
    const generateSinglePalpite = () => {
      const shuffled = allNumbers.sort(() => 0.5 - Math.random());
      return shuffled.slice(0, 10).sort((a, b) => parseInt(a) - parseInt(b));
    };

    return Array.from({ length: numPalpites }, generateSinglePalpite);
  };

  const handleGeneratePalpites = () => {
    if (numPalpites <= 0) return;

    const generatedPalpites = generateRandomNumbers(numPalpites);
    const formattedPalpites = generatedPalpites.map((palpite) => ({
      resultadoId: 0,
      numeros: palpite.join(","),
      statusCota: statusCota,
      statusPagamento: statusPagamento,
    }));

    setSelectedQuotas([...selectedQuotas, ...formattedPalpites]);
  };

  const formatNumber = (number: number): string => {
    return number.toString().padStart(4, "0");
  };

  return (
    <Flex flexDirection="column" >
      <Heading color="white" textAlign="center" mb={2}>
        Participe
      </Heading>
      <Heading
        fontSize={{ base: "lg", md: "2xl" }}
        color="white"
        textAlign="center"
      >
        Apenas {valorPalpite.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })}
      </Heading>{" "}
      <Box
        textAlign="center"
        bg="white"
        color="#006391"
        fontWeight="bold"
        mx="auto"
        pr={1}
        pl={1}
        my={2}
        mt={5}
        mb={5}
        width="fit-content"
      >
        Bolão - {formatNumber(salaId)}
      </Box>
      <Box
        textAlign="center"
        bg="#006391"
        color="white"
        fontWeight="bold"
        mx="auto"
        pr={1}
        pl={1}
        my={2}
        mt={7}
        width={{ base: "90%", md: "50%", xl: "30%" }}
        p={1}
      >
        R${" "}
        {totalPrizeValue.toLocaleString("pt-BR", { minimumFractionDigits: 2 })}{" "}
        em Prêmios
      </Box>
      <Flex margin="auto" flexDirection="column" maxWidth="80%">
      {prizes.map((prize) => (
        <Flex key={prize.premioId} mb={2} p={4}>
            <IoMdCheckboxOutline size={30} color="white" />
            <Text width="full" ml={2} color="white" fontWeight="bold">
              {prize.descricao}{" "}
              <strong style={{ color: "#FFC94D" }}>
                {prize.valor.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
              </strong>
            </Text>
        </Flex>
      ))}
      </Flex>
      <Box textAlign="center" mx="auto" maxWidth="90%" />
      <Flex
        direction={{ base: "column", md: "row" }}
        align="center"
        flex="1"
        justifyContent="center"
        wrap="wrap"
      >
        <Flex
          bg="#F4F4F6"
          borderRadius={7}
          direction="column"
          align="center"
          p={4}
          mb="3%"
          mt="3%"
          width={{ base: "90%", md: "50%", lg: "40%", xl: "30%" }}
        >
          <Text fontSize={{ base: "lg", md: "xl" }} mb={4} fontWeight="bold">
            Escolha 10 números:
          </Text>

          {chunkArray(generateNumbers(), numbersPerRow).map((row, index) => (
            <Flex key={index} flexWrap="wrap" justifyContent="center">
              {row.map((number) => (
                <Box
                  key={index}
                  m={1}
                  p={2}
                  bg={isNumberSelected(number) ? "#058A1A" : " "}
                  color={isNumberSelected(number) ? "white" : "#058A1A"}
                  border=" 2px solid #058A1A"
                  borderRadius="50%"
                  cursor="pointer"
                  onClick={() => handleNumberClick(number)}
                >
                  <Text fontSize={{ base: "sm", md: "md" }} fontWeight="bold">
                    {number}
                  </Text>
                </Box>
              ))}
            </Flex>
          ))}
          {/* <Text mt={5} color="#091747" fontWeight="bold">
            Gerar Automaticamente
          </Text>
          <HStack mt={5} flex="1" justify="center" spacing={4}>
            <Text color="#091747" fontWeight="bold">
              Quantos palpites gerar?
            </Text>
            <Input
              type="number"
              value={numPalpites}
              onChange={(e) => setNumPalpites(Number(e.target.value))}
              placeholder="Quantos palpites gerar?"
              min={1}
              w="20%"
            />
            <Button
              bg="#006391"
              onClick={handleGeneratePalpites}
              colorScheme="blue"
              leftIcon={<FaArrowsRotate />}
            >
              Gerar
            </Button>
          </HStack> */}

          <Flex justify="center" w={{ base: "270px", md: "500px" }} mt={4}>
            <Button
              onClick={handleClearSelection}
              colorScheme="red"
              mr={4}
              leftIcon={<MdClear />}
              size="sm"
            >
              Limpar
            </Button>
          </Flex>

          <Box mt={8} maxW="100%" overflowX="auto">
            <Text textAlign="center" mb={4} fontFamily="Arial, sans-serif">
              Números selecionados:
            </Text>

            <Flex flexWrap="wrap" justifyContent="center">
              {selectedNumbers.map((number, index) => (
                <Box
                  fontWeight="bold"
                  key={index}
                  m={1}
                  p={2}
                  bg="#058A1A"
                  color="white"
                  border=" 2px solid #058A1A"
                  borderRadius="50%"
                  cursor="default"
                  fontSize={{ base: "sm", md: "md" }}
                >
                  {number}
                </Box>
              ))}
            </Flex>
          </Box>
        </Flex>
        <Flex
          direction="column"
          align="center"
          borderRadius={7}
          p="25px"
          ml={{ base: 0, md: "6%" }}
          mt={{ base: "6%", md: "3%" }}
          bg="#F4F4F6"
          width={{ base: "100%", sm: "80%", md: "40%", lg: "30%" }}
          mb="5%"
        >
          <Flex alignItems="center" mb={4}>
            <Text fontSize="xl" fontWeight="bold" mr={2}>
              <MdAddShoppingCart size={24} color="#16A34A" />
            </Text>

            <Text fontSize="xl" fontWeight="bold">
              Carrinho
            </Text>
          </Flex>

          <Text
            mb={4}
            textAlign="center"
            fontFamily="Arial, sans-serif"
            fontSize={{ base: "15px", md: "17px" }}
          >
            Aqui estão seus Palpites
          </Text>

          {selectedQuotas.map((quota, index) => (
            <Flex
              key={index}
              justifyContent="space-between"
              alignItems="center"
              flexWrap="wrap"
            >
              <HStack>
                <Text fontSize="md" fontWeight="bold">
                  {index + 1}.
                </Text>

                <Flex flexWrap="wrap" justifyContent="center">
                  {quota.numeros.split(",").map((number, i) => (
                    <Box
                      fontWeight="bold"
                      borderRadius="50%"
                      cursor="default"
                      width="30px"
                      height="30px"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      key={i}
                      m={1}
                      p={2}
                      bg="#058A1A"
                      color="white"
                      border=" 2px solid #058A1A"
                      fontSize={{ base: "sm", md: "md" }}
                      maxWidth="40px"
                      whiteSpace="nowrap"
                      overflow="hidden"
                      textOverflow="ellipsis"
                    >
                      {number}
                    </Box>
                  ))}
                </Flex>
                <IconButton
                  aria-label="Remover do Carrinho"
                  icon={<IoCloseSharp size={20} />}
                  onClick={() => removeFromCart(index)}
                  colorScheme="red"
                  color="red"
                  variant="ghost"
                />
              </HStack>
            </Flex>
          ))}
          <Box mt={4} mb={5}>
            <HStack spacing={4} mb={4}>
              <Input
                placeholder="Digite o código do cupom"
                value={coupon}
                onChange={(e) => setCoupon(e.target.value)}
              />
              <IconButton
                colorScheme="green"
                icon={<CheckIcon />}
                onClick={applyCoupon}
                aria-label="Aplicar cupom"
              />
              {discount > 0 && (
                <IconButton
                  colorScheme="red"
                  icon={<IoCloseSharp />}
                  onClick={() => {
                    removeCoupon();
                  }}
                  aria-label="Remover cupom"
                />
              )}
            </HStack>
            {discount > 0 && (
              <Text color="#ff0000dc" fontWeight="bold" textAlign="center">
                Desconto Aplicado: R${" "}
                {discount.toLocaleString("pt-BR", {
                  minimumFractionDigits: 2,
                })}
              </Text>
            )}
            <Text
              mt={2}
              textAlign="center"
              mb={2}
              fontFamily="Arial, sans-serif"
              fontSize={{ base: "15px", md: "17px" }}
            >
              Valor Total da Aposta: R${totalValue.toFixed(2)}
            </Text>
            <Text fontSize="lg" mb={2} textAlign="center">
              Total com desconto:{" "}
              <strong> R${discountedValue.toFixed(2)}</strong>
            </Text>
          </Box>

          <Button
            onClick={handlePayment}
            colorScheme="green"
            bg="#16A34A"
            leftIcon={<BiCheck />}
          >
            Ir para pagamento
          </Button>
        </Flex>

        <CustomModal
          isOpen={modalOpen}
          onClose={() => setModalOpen(false)}
          message={modalContent.message}
        />
        <CustomModalSala
          isOpen={modalOpenSala}
          onClose={() => setModalOpenSala(false)}
          messageSala={modalContentSala.messageSala}
        />
      </Flex>
      <FloatingWhatsAppButton />
    </Flex>
  );
};

export default Buy;
