import { useState } from "react";
import {
  Box,
  Button,
  Container,
  Flex,
  HStack,
  IconButton,
  Input,
  Stack,
  Text,
  VStack,
  Heading,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useToast,
  useBreakpointValue,
} from "@chakra-ui/react";
import { ArrowBackIcon, CloseIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { http } from "../../services/http";
import { useAuth } from "../../contexts/authContext";

function formatarValorMoeda(valor: string): string {
  const valorNumerico = parseFloat(valor.replace(/[^\d]/g, "")) / 100;
  if (isNaN(valorNumerico)) return "";
  return valorNumerico.toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL",
  });
}

function removerFormatacaoMoeda(valorFormatado: string): number {
  return parseFloat(valorFormatado.replace(/[^\d,.-]/g, "").replace(",", "."));
}

interface Concurso {
  diaConcurso: string;
  numeroConcurso: string;
}

export function RegisterScreen() {
  const [valorPalpite, setValorPalpite] = useState("");
  const [dataFimVendas, setDataFimVendas] = useState("");
  const [concursos, setConcursos] = useState<Concurso[]>([]);
  const [diaConcurso, setDiaConcurso] = useState("");
  const [numeroConcurso, setNumeroConcurso] = useState("");
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const navigate = useNavigate();
  const { token } = useAuth();
  const toast = useToast();

  const adicionarConcurso = () => {
    if (diaConcurso && numeroConcurso) {
      setConcursos([...concursos, { diaConcurso, numeroConcurso }]);
      setDiaConcurso("");
      setNumeroConcurso("");
    }
  };

  const formatarData = (data: string) => {
    const [ano, mes, dia] = data.split("-");
    return `${dia}/${mes}/${ano}`;
  };

  const handleValorPalpiteChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const valor = e.target.value;
    setValorPalpite(formatarValorMoeda(valor));
  };

  const salvarCadastro = async () => {
    if(concursos.length <= 0){
      toast({
        title: "Campo inválido",
        description: "Adicione pelo menos um concurso",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    const valorPalpiteNumerico = removerFormatacaoMoeda(valorPalpite);
    if (isNaN(valorPalpiteNumerico)) {
      toast({
        title: "Erro",
        description: "Valor do palpite inválido.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    const novoCadastro = {
      dataFimVendas,
      valorPalpite: valorPalpiteNumerico,
      statusSalas: 1,
      concursos: concursos.map((concurso) => ({
        diaConcurso: concurso.diaConcurso,
        numeroConcurso: parseInt(concurso.numeroConcurso),
      })),
    };

    try {
      const response = await http.post("/salas", novoCadastro, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setShowSuccessModal(true);
      setValorPalpite("");
      setDataFimVendas("");
      setConcursos([]);
    } catch (error) {
      console.error("Erro ao cadastrar sala:", error);
      toast({
        title: "Erro",
        description: "Erro ao tentar cadastrar a sala.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleCloseSuccessModal = () => {
    setShowSuccessModal(false);
    navigate("/roomsDashboard");
  };

  const isMobile = useBreakpointValue({ base: true, md: false });

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value.length <= 10) {
      setDiaConcurso(value);
    }
  };
  const handleDateTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;

    const dateTimeParts = value.split("T");
    const dateParts = dateTimeParts[0].split("-");

    if (dateParts[0].length > 4) {
      dateParts[0] = dateParts[0].substring(0, 4);
    }

    value = `${dateParts.join("-")}T${dateTimeParts[1]}`;

    setDataFimVendas(value);
  };

  return (
    <Container
      bg="white"
      borderRadius="md"
      boxShadow="xl"
      maxW={{ base: "sm", md: "lg" }}
      mx="auto"
      mt={{ base: 10, md: 20 }}
      mb={{ base: 10, md: 20 }}
      p={6}
    >
      <VStack spacing={6} align="stretch">
        <Flex align="center" mb={4}>
          <IconButton
            bg="#38A169"
            color="white"
            aria-label="Voltar"
            icon={<ArrowBackIcon />}
            variant="ghost"
            onClick={() => navigate(-1)}
          />
          <Heading as="h2" size="lg" ml={4}>
            Cadastrar Bolão
          </Heading>
        </Flex>

        <Box>
          <Text mb={2}>Valor Palpite</Text>
          <Input
            borderColor="gray.300"
            type="text"
            value={valorPalpite}
            onChange={handleValorPalpiteChange}
          />
        </Box>
        <Box>
          <Text mb={2}>Data e Hora Fim Vendas</Text>
          <Input
            borderColor="gray.300"
            type="datetime-local"
            value={dataFimVendas}
            onChange={handleDateTimeChange}
          />
        </Box>
        <Box>
          <Text mb={2} textAlign="center" fontWeight="bold">
            Adicionar Concurso
          </Text>
          <Flex direction={{ base: "column", md: "row" }} align="center">
            <Box flex="1" mr={{ base: 0, md: 1 }} mb={{ base: 2, md: 0 }}>
              <Text mb={2} textAlign="center">
                Dia do Concurso
              </Text>
              <Input
                borderColor="gray.300"
                type="date"
                value={diaConcurso}
                onChange={handleDateChange}
              />
            </Box>
            <Box flex="1" ml={{ base: 0, md: 1 }} mb={{ base: 2, md: 0 }}>
              <Text mb={2} textAlign="center">
                Número do Concurso
              </Text>
              <Input
                borderColor="gray.300"
                type="number"
                value={numeroConcurso}
                onChange={(e) => setNumeroConcurso(e.target.value)}
              />
            </Box>
            <Box mt={{ base: 2, md: 30 }} ml={{ base: 0, md: 2 }}>
              <Button
                onClick={adicionarConcurso}
                bg="#38A169"
                color="white"
                _hover={{ bg: "teal.500" }}
              >
                +
              </Button>
            </Box>
          </Flex>
        </Box>
        <Box>
          <HStack justify="space-around">
            <Text fontWeight="bold" textAlign="center">
              Dia do Concurso
            </Text>
            <Text fontWeight="bold" textAlign="center">
              Número do Concurso
            </Text>
          </HStack>
          <Stack spacing={2} mt={2}>
            {concursos.map((concurso, index) => (
              <Flex key={index} align="center">
                <HStack spacing={{ base: 2, md: 5 }} flex="1">
                  <Text
                    w={{ base: "50%", md: "45%" }}
                    p={1}
                    border="1px solid #CBD5E0"
                    borderRadius="5px"
                  >
                    {formatarData(concurso.diaConcurso)}
                  </Text>
                  <Text
                    w={{ base: "50%", md: "45%" }}
                    p={1}
                    border="1px solid #CBD5E0"
                    borderRadius="5px"
                  >
                    {concurso.numeroConcurso}
                  </Text>
                </HStack>
                <IconButton
                  ml={1}
                  aria-label="Remover concurso"
                  icon={<CloseIcon />}
                  size="sm"
                  colorScheme="red"
                  onClick={() => {
                    const novosConcursos = [...concursos];
                    novosConcursos.splice(index, 1);
                    setConcursos(novosConcursos);
                  }}
                />
              </Flex>
            ))}
          </Stack>
        </Box>
        <Button colorScheme="green" onClick={salvarCadastro} mt={4}>
          Salvar
        </Button>
      </VStack>

      <Modal isOpen={showSuccessModal} onClose={handleCloseSuccessModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Sucesso!</ModalHeader>
          <ModalBody>
            <Text>Cadastro realizado com sucesso!</Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="green" onClick={handleCloseSuccessModal}>
              OK
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Container>
  );
}
