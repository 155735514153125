import {
  Box,
  Text,
  Link,
  Stack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import FloatingWhatsAppButton from "../components/floatingWhatsAppButton";

export const Help = () => {
  return (
    <Stack
      bg="#E5E7EB"
      spacing={8}
      maxW={{ base: "90%", md: "80%", lg: "50%" }}
      mx="auto"
      p={4}
      borderRadius="md"
      boxShadow="md"
      marginBottom={{ base: "3%", md: "5%" }}
      marginTop={{ base: "3%", md: "3%" }}
    >
      <Text fontSize="xl" fontWeight="bold" textAlign="center">
        Como podemos te ajudar?
      </Text>
      <Stack spacing={4}>
        <Text fontSize="lg" fontWeight="bold" mb={2}>
          FAQs - Perguntas Frequentes
        </Text>
        <Accordion allowToggle>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  O que é o bolão dos 10?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              É uma ação de Marketing realizada baseada nos números sorteados
              nos concursos da Mega Sena, organizado em Bolões separados. Onde o
              participante escolhe 10 números entre 01 a 60 e concorre das
              premiações estipuladas para cada Bolão.
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  Como funciona ?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              Clique nos três risquinhos no canto superior esquerdo e entre na
              aba “ Regras “ , lá você encontrará todas as respostas referente
              ao funcionamento do Bolão dos 10.
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  Quando começa cada Bolão?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              Todo Bolão em status “ Vendas Abertas “ tem logo abaixo um
              contador indicando o tempo restante para o seu início.
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  Posso fazer quantos Palpites?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              É ilimitado, você pode fazer quantos palpites quiser.
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  Como acompanho meus Palpites?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              Você poderá acompanhar seus palpites na aba “ Meus Palpites” ,
              como também o Ranking e os Ganhadores. Nosso sistema é totalmente
              auditável, tem acesso para acompanhar todo o Bolão em “Andamento”
              e conferir os bolões “Finalizados”.
            </AccordionPanel>
          </AccordionItem>{" "}
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  Quais são as premiações?{" "}
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              As premiações dependem de cada Bolão. Todas as informações das
              premiações de cada Bolão estarão disponíveis em ações nas Redes
              Sociais, no nosso Grupo de Whatsapp e também na página de escolha
              de números de cada Bolão.
            </AccordionPanel>
          </AccordionItem>{" "}
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  É seguro participar?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              Sim, nosso sistema é totalmente seguro, auditável e 100%
              legalizado. Você consegue acompanhar todo o processo do bolão
              desde o início até o fim, que serão também divulgados em nossas
              redes sociais e grupos de whatsapp, conforme condições na aba
              “Regras”. Também poderá conferir os Bolões finalizados.
            </AccordionPanel>
          </AccordionItem>{" "}
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  Como faço para participar?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              Para iniciar, basta se cadastrar no site e escolher o bolão com “
              Vendas Abertas “. Em seguida, selecione seus números e faça seu
              palpite. Será gerado um QR code / código Pix. Basta fazer o
              pagamento e pronto, você já está concorrendo a vários prêmios em
              dinheiro.
            </AccordionPanel>
          </AccordionItem>{" "}
        </Accordion>
      </Stack>
      <Stack spacing={4}>
        <Text fontSize="lg" fontWeight="bold"  >
          Entre em Contato
        </Text>
        <Text fontSize="md">
          Se você não conseguiu encontrar a resposta que estava procurando,
          sinta-se à vontade para entrar em contato conosco:
        </Text>
        <Link href="mailto:suporte@bolaodos10.com.br" color="blue.500">
          suporte@bolaodos10.com.br
        </Link>
        <Link href="tel:+3499118-9354" color="blue.500">
          (34) 99118-9354
        </Link>
      </Stack>{" "}
      <FloatingWhatsAppButton />
    </Stack>
  );
};
