import React, { useState, useEffect } from "react";
import {
  Text,
  Flex,
  Image,
  Button,
  Box,
  useToast,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalContent,
  ModalBody,
  VStack,
  IconButton,
  Icon,
} from "@chakra-ui/react";
import { useAuth } from "../contexts/authContext";
import { http } from "../services/http";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { ArrowBackIcon, CheckCircleIcon } from "@chakra-ui/icons";
import FloatingWhatsAppButton from "../components/floatingWhatsAppButton";
import { QRCodeSVG } from "qrcode.react";
import { AiOutlineCloseCircle } from "react-icons/ai";

const PaymentScreen: React.FC = () => {
  const { user, token } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const [pixCodeBase64, setPixCodeBase64] = useState<string>("");
  const { selectedQuotas } = useParams();
  const cotasIds = location.state?.cotasIds || [];
  const discountedValue = location.state?.discountedValue;
  const toast = useToast();
  const [paymentStatus, setPaymentStatus] = useState<boolean | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [timeoutReached, setTimeoutReached] = useState(false);
  const [showModalFalse, setShowModalFalse] = useState(false);
  const [pixCopy, setPixCopy] = useState<string>("");
  const coupon = location.state?.coupon;
  const salaId = location.state?.salaId;
  const totalValue = location.state?.totalValue;
  const valorCota = location.state?.valorPalpite;
  const [pixCodeContent, setPixCodeContent] = useState<string>("");
  const [qrCodeContent, setQrCodeContent] = useState<string>("");

  const extractCotasId = (obj: { cotasId: number }): number => {
    const { cotasId } = obj;
    return cotasId;
  };

  const savePixToCookie = (
    pixCopy: string,
    pixCodeContent: string,
    qrCodeContent: string,
    pixCodeBase64: string
  ) => {
    clearCookies()
    const plusOneHour = new Date()
    plusOneHour.setUTCHours(new Date().getUTCHours() + 1)
    document.cookie = `pixCopy=${pixCopy.trim()};expires=${plusOneHour.toUTCString()}`
    document.cookie = `pixCodeContent=${pixCodeContent.trim()};expires=${plusOneHour.toUTCString()}`
    document.cookie = `qrCodeContent=${qrCodeContent.trim()};expires=${plusOneHour.toUTCString()}`
    document.cookie = `pixCodeBase64=${pixCodeBase64.trim()};expires=${plusOneHour.toUTCString()}`
  }

  const getPixFromCookie = () => {
    const cookies = document.cookie

    const fields = cookies.split(";")
    const qrCodeBase64 = fields.find((row) => row.trim().startsWith("pixCodeBase64="))
      ?.split("=")[1];
    const qrCodeContent = fields.find((row) => row.trim().startsWith("qrCodeContent="))
      ?.split("=")[1];
    const copyPix = fields.find((row) => row.trim().startsWith("pixCopy="))?.split("=")[1];
    const pixCodeContent = fields.find((row) => row.trim().startsWith("pixCodeContent="))
      ?.split("=")[1];

    if (!qrCodeBase64 || !qrCodeContent || !copyPix || !pixCodeContent) {
      return false
    }

    setPixCopy(copyPix)
    setPixCodeContent(pixCodeContent)
    setQrCodeContent(qrCodeContent)
    setPixCodeBase64(qrCodeBase64)
    return true
  }

  const clearCookies = (
  ) => {
    const minusOneHour = new Date()
    minusOneHour.setUTCHours(new Date().getUTCHours() - 1)
    document.cookie = `pixCopy=;expires=${minusOneHour.toUTCString()}`
    document.cookie = `pixCodeContent=;expires=${minusOneHour.toUTCString()}`
    document.cookie = `qrCodeContent=;expires=${minusOneHour.toUTCString()}`
    document.cookie = `pixCodeBase64=;expires=${minusOneHour.toUTCString()}`
  }



  const generatePixPayment = async () => {
    try {
      const payload: any = {
        userId: user?.userId,
        quantidadePalpite: selectedQuotas,
        salaId: salaId,
      };
      const cotasFormatadas =
        cotasIds.map((cota: { cotasId: number }) => {
          return extractCotasId(cota);
        })


      const { data } = await http.post("/pagamento/consulta-palpite/", {
        cotasId: cotasFormatadas,
        salaId
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const paymentAlreadyExists = data.conteudo

      if (paymentAlreadyExists) {
        const isPixValid = getPixFromCookie()
        if (!isPixValid) {
          toast({
            title: "PIX expirado!",
            status: "info",
            duration: 2000,
            isClosable: true,
          });
          setTimeout(() => {navigate("/")}, 2000)
          return
        }
        return
      }

      if (coupon.length) {
        payload.cupom = coupon;
      }

      const response = await http.post("/pagamento/gerar-pix", payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        const qrCodeBase64 = response.data.conteudo.qrCodebase64;
        const qrCodeContent = response.data.conteudo.transacaoId;
        const copyPix = response.data.conteudo.qrCodeCopiaCola;
        setPixCodeBase64(qrCodeBase64);
        setPixCodeContent(qrCodeContent);
        setPixCopy(copyPix);
        setQrCodeContent(copyPix);
        savePixToCookie(copyPix, qrCodeContent, copyPix, qrCodeBase64)

        const pagamentoData = cotasIds.map((cota: { cotasId: number }) => {
          const cotasId = extractCotasId(cota);
          return {
            transacaoId: qrCodeContent,
            cotasId,
            userId: user?.userId,
            salaId: salaId,
            status: 1,
            valor: valorCota,
            descricaoCupom: coupon.length > 0 ? coupon : null,
            dataTransacao: new Date().toISOString(),
          };
        });

        await http.post("/pagamento", pagamentoData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      } else {
        throw new Error("Erro ao gerar o PIX");
      }
    } catch (error) {
      console.error("Erro ao gerar o PIX ou enviar pagamento:", error);
      alert("Erro ao gerar o PIX ou enviar pagamento");
    }
  };

  const checkPaymentStatus = async (transactionId: string) => {
    try {
      const response = await http.get(
        `/pagamento/consulta-pagamento/${pixCodeContent}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data && response.data.conteudo !== null) {
        const { conteudo } = response.data;

        setPaymentStatus(conteudo);
      } else {
        console.error("Resposta da API inválida ou conteúdo não encontrado.");
      }
    } catch (error) {
      console.error("Erro ao verificar o status do pagamento:", error);
    }
  };

  useEffect(() => {
    generatePixPayment();
  }, [user]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (pixCodeContent) {
        checkPaymentStatus(pixCopy);
      }
    }, 7000);

    return () => clearInterval(interval);
  }, [pixCodeContent]);

  useEffect(() => {
    if (paymentStatus === true) {
      setShowModal(true);
      const timer = setTimeout(() => {
        setShowModal(false);
        navigate("/");
      }, 7000);
      return () => clearTimeout(timer);
    }
  }, [paymentStatus, navigate]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (paymentStatus === null) {
        setShowModalFalse(true);
        setTimeoutReached(true);
      }
    }, 300000); //5 minutos

    return () => clearTimeout(timeout);
  }, [paymentStatus]);

  useEffect(() => {
    if (timeoutReached) {
      const timer = setTimeout(() => {
        navigate("/");
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [timeoutReached, navigate]);
  const copyPixContent = () => {
    navigator.clipboard.writeText(pixCopy);
    showToast();
  };

  const showToast = () => {
    toast({
      title: "Código PIX copiado!",
      status: "success",
      duration: 2000,
      isClosable: true,
    });
  };

  return (
    <Flex
      direction="column"
      align="center"
      justify="center"
      p={4}
      bgGradient="linear(to-b, #068A1A 40%, #E5E7EB 40%)"
      minHeight="100vh"
    >
      <Flex
        direction="column"
        align="center"
        maxW={{ base: "90%", md: "80%", lg: "60%" }}
        bg="white"
        p={6}
        borderRadius="8px"
        boxShadow="md"
        position="relative"
      >
        <IconButton
          icon={<ArrowBackIcon />}
          aria-label="Voltar"
          position="absolute"
          top={2}
          left={2}
          colorScheme="green"
          onClick={() => navigate("/")}
          boxShadow="md"
        />
        <Box mb={4}>
          <Text fontSize="18px">
            <strong>Nome:</strong> {user?.nome}
          </Text>
          <Text fontSize="18px">
            <strong>CPF:</strong> {user?.cpf}
          </Text>
        </Box>

        <Text
          fontSize={{ base: "lg", md: "lg" }}
          textAlign="left"
          mb={4}
          lineHeight="1.6"
        >
          <Text fontWeight="bold" mb={2}>
            Cópie o código abaixo para pagar
          </Text>

          <Text as="div" ml={4}>
            1. Abra o aplicativo bancário ou de pagamentos online.
            <br />
            2. Acesse a seção PIX e selecione a opção "QR Code".
            <br />
            3. Escaneie o QR Code abaixo e siga as instruções fornecidas pelo
            banco.
            <br />
          </Text>
        </Text>

        <Flex
          justifyContent="center"
          flex="1"
          direction="row"
          align="center"
          mb={4}
          wrap="wrap"
          overflowX="auto"
        >
          <Box flex="0 0 auto" p={2}>
            {pixCodeBase64 && (
              <Box p={4} bg="white" borderRadius="md" boxShadow="md">
                <QRCodeSVG value={qrCodeContent} size={220} />
              </Box>
            )}
          </Box>

          <VStack spacing={4} align="stretch">
            <Box
              overflowX="auto"
              whiteSpace="nowrap"
              borderWidth="1px"
              borderRadius="md"
              p={2}
              bg="#e7fff3d2"
              width="300px"
              textAlign="center"
              fontSize={{ base: "sm", md: "md" }}
              wordBreak="break-word"
            >
              {pixCopy}
            </Box>

            <Button
              bg="#38A169"
              color="white"
              _hover={{ bg: "#15803D" }}
              onClick={copyPixContent}
            >
              Copiar o Código PIX
            </Button>
          </VStack>
        </Flex>

        <Text fontSize="18px" fontWeight="bold">
          Valor: R${" "}
          {discountedValue.toLocaleString("pt-BR", {
            minimumFractionDigits: 2,
          })}{" "}
        </Text>
      </Flex>
      <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
        <ModalOverlay />

        <ModalContent mt="30%">
          <ModalHeader textAlign="center" fontSize="xl" fontWeight="bold">
            Pagamento Concluído
          </ModalHeader>
          <ModalBody>
            <Text fontSize="lg" textAlign="center" mb={4}>
              Seu pagamento foi concluído com sucesso! Agradecemos por sua
              compra.
            </Text>
            <Flex direction="column" align="center" mb={4}>
              <CheckCircleIcon color="green.500" mr={2} boxSize={10} />
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal isOpen={showModalFalse} onClose={() => setShowModalFalse(false)}>
        <ModalOverlay />
        <ModalContent mt="30%">
          <ModalHeader textAlign="center" fontSize="xl" fontWeight="bold">
            Pagamento Cancelado
          </ModalHeader>
          <ModalBody>
            <Text fontSize="lg" textAlign="center" mb={4}>
              Sua compra foi cancelada.
            </Text>
            <Flex direction="column" align="center" mb={4}>
              <Icon as={AiOutlineCloseCircle} color="red.500" boxSize={10} />
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>{" "}
      <FloatingWhatsAppButton />
    </Flex>
  );
};

export default PaymentScreen;
