import { faker } from "@faker-js/faker";
import { EFullName, EPaymentMethods, ETripStatus } from "../../types/invoicing/enum";
import { IInvoicing } from "../../types/invoicing/interfaces";

export const CONTROL_PANEL: IInvoicing[] = Array.from(
  { length: 10 },
  (): IInvoicing => ({
    _id: faker.database.mongodbObjectId(),
    clientName: faker.helpers.enumValue(EFullName),
    driverName: faker.number.float({ min: 2, max: 10 }),
    price: faker.number.float({ min: 5, max: 50, precision: 0.01 }),
    city: faker.location.city(),
    destiny: faker.location.city(),
    paymentMethod: faker.helpers.enumValue(EPaymentMethods),
    ableDrivers: {
      day: faker.number.int({ min: 500, max: 2000 }),
      week: faker.number.int({ min: 5000, max: 12000 }),
      month: faker.number.int({ min: 20000, max: 50000 }),
    },
    disableDrivers: {
      day: faker.number.int({ min: 300, max: 1500 }),
      week: faker.number.int({ min: 4000, max: 8000 }),
      month: faker.number.int({ min: 10000, max: 32000 }),
    },
    passengers: {
      day: faker.number.int({ min: 1000, max: 7000 }),
      week: faker.number.int({ min: 7000, max: 70000 }),
      month: faker.number.int({ min: 28000, max: 280000 }),
    },
    races: {
      day: faker.number.int({ min: 1000, max: 7000 }),
      week: faker.number.int({ min: 7000, max: 70000 }),
      month: faker.number.int({ min: 28000, max: 280000 }),
    },
    Invoices: {
      day: faker.number.float({ min: 1000, max: 10000, precision: 0.01 }),
      week: faker.number.float({ min: 7000, max: 70000, precision: 0.01 }),
      month: faker.number.float({ min: 70000, max: 700000, precision: 0.01 }),

    },
    cost: faker.number.float({ min: 2500, max: 250000, precision: 0.01 }),
    paymentDate: faker.date.past(),
    createdAt: faker.date.past(),
    status: faker.helpers.enumValue(ETripStatus),
  })
);

