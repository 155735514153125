import {
  Button,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  IconButton,
  Input,
  useToast,
} from "@chakra-ui/react";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { http } from "../../services/http";
import { useAuth } from "../../contexts/authContext";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { PercentageInput } from "../../components/percentageInput";

export function AddCoupon() {
  const [descricao, setDescricao] = useState("");
  const [quantidadeLimitada, setQuantidadeLimitada] = useState("");
  const [valor, setValor] = useState("");
  const { token } = useAuth();
  const toast = useToast();
  const navigate = useNavigate();

  function handleSaveCoupon() {
    const newCoupon = {
      descricao,
      quantidadeLimitada: Number(quantidadeLimitada),
      valor: Number(valor),
    };

    http
      .post("/cupom", newCoupon, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        toast({
          title: "Cupom criado com sucesso.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        navigate("/cupom");
      })
      .catch((error) => {
        console.error("Erro ao salvar cupom:", error.response);

        const errorMessage =
          error.response?.data?.erros?.[0] || "Erro desconhecido";

        toast({
          title: `Erro ao salvar cupom`,
          description: errorMessage,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  }

  const handlePercentageChange = (newValue: string) => {
    setValor(newValue);
  };

  const valueRef = useRef<HTMLInputElement>(null)

  return (
    <Flex flex="1" justifyContent="center" alignItems="center" padding={5}>
      <Container
        bg="white"
        borderRadius="md"
        boxShadow="xl"
        mx="auto"
        mt={10}
        p={6}
        mb="5%"
      >
        <Flex alignItems="center" mb={5}>
          <IconButton
            bg="#38A169"
            color="white"
            aria-label="Voltar"
            icon={<ArrowBackIcon />}
            variant="ghost"
            onClick={() => navigate(-1)}
          />
          <Heading as="h2" size="lg" ml={4}>
            Adicionar Cupom
          </Heading>
        </Flex>
        <FormControl mb={4}>
          <FormLabel>Descrição</FormLabel>
          <Input
            placeholder="Descrição do Cupom"
            value={descricao}
            onChange={(e) => setDescricao(e.target.value)}
          />
        </FormControl>
        <FormControl mb={4}>
          <FormLabel>Quantidade Limitada</FormLabel>
          <Input
            type="number"
            placeholder="Quantidade Limitada"
            value={quantidadeLimitada}
            onChange={(e) => setQuantidadeLimitada(e.target.value)}
          />
        </FormControl>
        <FormControl mb={4}>
          <FormLabel>Percentual</FormLabel>
          <PercentageInput
            ref={valueRef}
            placeholder="Valor do Cupom"
            value={valor}
            onChange={handlePercentageChange}
          />
        </FormControl>
        <Button
          colorScheme="green"
          onClick={handleSaveCoupon}
          width="full"
          mt={4}
        >
          Salvar Cupom
        </Button>
      </Container>
    </Flex>
  );
}
