import {
    ReactNode,
    createContext,
    useContext,
    useEffect,
    useState,
  } from "react";
  import { ControlPanelRepository } from "../domain/repositories/invoicing";
import { IInvoicing } from "../types/invoicing/interfaces";
  
  interface IContextValue {
    panelControl: IInvoicing[];
    isLoading: boolean;
    findAll(): Promise<void>;
  }
  
  export const InvoicingContext = createContext<IContextValue>({
    panelControl: [],
    isLoading: false,
    findAll: async () => {},
  });
  
  interface IProps {
    children: ReactNode;
  }
  
  export function InvoicingProvider({ children }: IProps) {
    const [panelControl, setPanelControl] = useState<IInvoicing[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
  
    async function findAll(currentPage: number = 1, limit: number = 8) {
      setIsLoading(true);
  
      const statistics = (await ControlPanelRepository.findAll(
        // currentPage,
        // limit
      )) as unknown as IInvoicing[];
  
      setPanelControl(statistics);
  
      setIsLoading(false);
    }
  
    useEffect(() => {
      findAll();
    }, []);
  
    return (
      <InvoicingContext.Provider
        value={{
          panelControl,
          isLoading,
          findAll,
        }}
      >
        {children}
      </InvoicingContext.Provider>
    );
  }
  
  export function useInvoicing() {
    return useContext(InvoicingContext);
  }