import { Box, Link, Text } from "@chakra-ui/react";

export const TermosUso = () => {
  return (
    <Box>
      <Text mb={2}>
        Ao clicar em <strong>“Aceito todos os termos e condições”</strong>, você
        aceita as regras abaixo:
      </Text>
      <Text mb={2}>
        <strong>1.</strong> Ao adquirir um e-book no nosso site, você poderá
        escolher um bilhete no qual são anotados, à escolha do participante, 10
        (Dez) números (001-060), sendo válidos somente aqueles decorrentes de
        emissão pelo site da proponente, nos termos do presente.
      </Text>
      <Text mb={2}>
        <strong>Parágrafo único:</strong> Será concedido o direito a realizar a
        escolha pela participação da promoção, na proporção de 1 bilhete por
        produto adquirido, sendo de livre escolha do participante, adquirir mais
        unidades do produto.
      </Text>
      <Text mb={2}>
        <strong>2.</strong> O concurso é aberto a todos, mantendo o acesso e
        monitoramento mediante aplicativo próprio.{" "}
      </Text>
      <Text mb={2}>
        <strong>3. </strong>Um mesmo participante poderá adquirir um ou mais
        produtos, aumentando assim as suas chances de ganhar os prêmios.
      </Text>
      <Text mb={2}>
        <strong>4.</strong> O participante deverá fornecer os dados pessoais,
        telefone e e-mail, quando da realização da escolha dos números, sob pena
        de não ser reconhecido o direito ao recebimento de eventual premiação.
      </Text>
      <Text mb={2}>
        <strong>5.</strong> Tais informações não serão compartilhadas, conforme
        prevê a Lei 13.709/2018, sendo utilizadas para fins fiscais ou em casos
        exigidos em Lei.
      </Text>
      <Text mb={2}>
        <strong>6.</strong> A escolha dos números reservados somente será
        efetivada após o pagamento do e-book ou do produto que o substituir em
        até 30 minutos do momento do cadastro, após o referido período, será
        automaticamente cancelado, resultando na perda de direito ao mesmo.
      </Text>
      <Text mb={2}>
        <strong>7.</strong> Não existe restrição ou reserva de números, podendo
        serem repetidos em diversas escolhas, inclusive pelo mesmo participante;
      </Text>
      <Text mb={2}>
        <strong>8.</strong> Com base na extração do prêmio da Mega Sena, a
        premiação ocorrerá da seguinte forma:
      </Text>
      <Text>- Mais acertos por cada concurso</Text>
      <Text>- Mais acertos na soma dos acertos por concurso</Text>
      <Text>- 00 acertos</Text>
      <Text mb={2}>- Top Comprador</Text>
      <Text mb={2}>
        <strong> Parágrafo único:</strong> Poderá, conforme cada concurso
        realizado, ser estipulado premiações e promoções extras e com valores
        diferentes, as quais estarão identificadas no momento da adesão do
        participante.
      </Text>
      <Text mb={2}>
        <strong>9.</strong> O concurso terá vigência pelo período compreendido
        entre o início de sorteios, este compreendido o primeiro sorteio após o
        encerramento das compras na data estipulada.
      </Text>
      <Text mb={2}>
        <strong>10.</strong>O concurso será encerrado após o resultado do ultimo
        concurso estipulado no Bolão.
      </Text>
      <Text mb={2}>
        <strong>Paragrafo único:</strong> Logo que o Bolão se encerra, será
        divulgado pela promotora relatório com os nomes dos ganhadores,
        inclusive podendo ser divulgados em redes sociais, bem como com o valor
        a cada um entregue, sendo de pleno acesso a todos os participantes, tais
        dados.
      </Text>
      <Text mb={2}>
        <strong>11.</strong> Caso haja mais de um vencedor, em todos os casos
        descritos no item “7”, o prêmio será dividido em partes iguais pelo
        número de vencedores.
      </Text>
      <Text mb={2}>
        <strong>12.</strong> O resultado da Loteria Federal pode ser consultado
        nas Lotéricas ou pelo site: {" "}
        <Link
          color="blue.500"
          href="https://loterias.caixa.gov.br/Paginas/Mega-Sena.aspx"
          isExternal
        >
          https://loterias.caixa.gov.br/Paginas/Mega-Sena.aspx
        </Link>
      </Text>
      <Text mb={2}>
        <strong>13.</strong> Toda informação sobre o sorteio e ganhador/es
        poderá ser encontrada no link {" "}
        <Link color="blue.500" href="https://bolaodos10.com.br/" isExternal>
          https://bolaodos10.com.br/
        </Link>
      </Text>
      <Text mb={2}>
        <strong>14.</strong> Nosso site pode conter links para acesso a outros
        sites, sendo obrigatório a aceitação dos cookies para acesso em todos os
        termos e programas anexos.
      </Text>
      <Text mb={2}>
        11. Os participantes concordam e autorizam a utilização de seu nome,
        fotografia ou imagens e voz para divulgação do evento, sem qualquer ônus
        para a entidade promotora do concurso.
      </Text>{" "}
      <Text mb={2}>
        12. O prêmio será entregue mediante a apresentação de comprovação de
        identidade pagamento dos premiados realizados via transferência bancária
        02 dias úteis após os resultados.
      </Text>{" "}
      <Text mb={2}>
        13. Não será entregue prêmio sem a apresentação de comprovação de
        identidade.
      </Text>{" "}
      <Text mb={2}>
        14. Caso os ganhadores não entrem em contato e a entidade não consiga
        contatá-los no período máximo de 90 dias, a contar da data do
        encerramento do concurso, haverá a destinação do prêmio à uma entidade
        de Assistência Social escolhida pelos organizadores, a qual será
        prestado contas no mesmo site.
      </Text>{" "}
      <Text mb={2}>
        <strong>15.</strong> O ato de escolha do(s) número(s), no valor
        definido, caracteriza o participante no conhecimento e aceitação
        integral dos termos e condições deste Regulamento.
      </Text>{" "}
      <Text mb={2}>
        <strong>16.</strong> Estas regras e termos de uso podem ser alteradas a
        qualquer momento, sem aviso prévio. Acompanhe sempre a atualização desta
        página.
      </Text>{" "}
      <Text mb={4}>
        <strong>17.</strong> Caso tenha qualquer dúvida, entre em contato com a
        LL Tech Soluções Digitais LTDA através do e-mail
        suporte@bolãodos10.com.br.
      </Text>{" "}
      <a href="/privacidadeDados" style={{ textDecoration: "underline" }}>
        <Text fontWeight="bold" fontSize={{ base: "md", lg: "lg" }}>
          Política Privacidade de Dados Pessoais
        </Text>
      </a>
      <a href="/termoConsentimento" style={{ textDecoration: "underline" }}>
        <Text fontWeight="bold" fontSize={{ base: "md", lg: "lg" }} mt="3%">
          Termo de Consentimento
        </Text>
      </a>
    </Box>
  );
};
