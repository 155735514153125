 import { IRooms } from "../../types/users";
 
 
export const RoomsRepository = {
  findAll: async (): Promise<IRooms[]> => {
    return new Promise<IRooms[]>((resolve) => {
      setTimeout(() => {
         
      }, 2000);
    });
  },
};


// export const RoomsRepository = {
//   findAll: async (currentPage: number = 1, limit: number = 10) => {
//     const response = await http.get<{ conteudo: IRooms[] }>(`/salas/`);

//     return response.data.conteudo;
//   },

//   create: async (payload: Omit<IRooms, "salaId">) => {
//     try {
//       const response = await http.post<IRooms>(`/salas`, payload);
//       return response.data;
//     } catch (error) {
//       console.error("Erro ao criar :", error);
//       throw error;
//     }
//   },

//   update: async (salaId: number, payload: IRooms) => {
//     try {
//       const response = await http.put<IRooms>(`/salas/altera`, payload);
//       return response.data;
//     } catch (error) {
//       console.error("Erro ao atualizar uma sala:", error);
//       throw error;
//     }
//   },

//   delete: async (salaId: number) => {
//     try {
//       const response = await http.delete<IRooms>(`/salas/${salaId}`);
//       return response.data;
//     } catch (error) {
//       console.error("Erro ao excluir uma sala:", error);
//       throw error;
//     }
//   },
// };
