import {
  CircularProgress,
  Container,
  Flex,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Box,
  IconButton,
  Badge,
  HStack,
} from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { IColumn } from "../../types/componentes/table";
import { Table } from "../../core/components/table";
import { IoEyeOutline } from "react-icons/io5";
import { http } from "../../services/http";
import { useAuth } from "../../contexts/authContext";
import InputMask from "react-input-mask";
import { RiPencilLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import moment from "moment";

interface Sala {
  salaId: number;
  dataInicio: string;
  dataFimVendas: string;
  dataFim: string;
  valorPalpite: number;
  statusSala: {
    id: number;
    descricao: string;
  };
  concursos: {
    concursoId: number;
    salaId: number;
    diaConcurso: string;
    numeroConcurso: number;
    ativo: boolean;
  }[];
  ativo: boolean;
}

export function Sortie() {
  const [salas, setSalas] = useState<Sala[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);
  const [modalValues, setModalValues] = useState<Sala>({
    salaId: 0,
    dataInicio: "",
    dataFimVendas: "",
    dataFim: "",
    valorPalpite: 0,
    statusSala: { id: 0, descricao: "" },
    concursos: [],
    ativo: true,
  });
  const navigate = useNavigate();
  const { token } = useAuth();
  const [numeros, setNumeros] = useState<string>("");
  async function fetchSalas() {
    setIsLoading(true);
    try {
      const response = await http.get("/resultado-mega/salas-concurso", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const fetchedSalas: Sala[] = response.data.conteudo.map((item: any) => ({
        salaId: item.salaId,
        concursos: [
          {
            concursoId: item.concursoId,
            salaId: item.salaId,
            diaConcurso: item.diaConcurso,
            numeroConcurso: item.numeroConcurso,
            ativo: item.ativo,
          },
        ],
        ativo: item.ativo,
      }));

      setSalas(fetchedSalas.filter((sala) => sala.concursos.length > 0));
      setIsLoading(false);
    } catch (error) {
      console.error("Erro ao buscar salas:", error);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    async function fetchSalas() {
      try {
        const response = await http.get("/resultado-mega/salas-concurso", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const fetchedSalas: Sala[] = response.data.conteudo.map(
          (item: any) => ({
            salaId: item.salaId,
            concursos: [
              {
                concursoId: item.concursoId,
                salaId: item.salaId,
                diaConcurso: item.diaConcurso,
                numeroConcurso: item.numeroConcurso,
                ativo: item.ativo,
              },
            ],
            ativo: item.ativo,
          })
        );

        setSalas(fetchedSalas.filter((sala) => sala.concursos.length > 0));
        setIsLoading(false);
      } catch (error) {
        console.error("Erro ao buscar salas:", error);
        setIsLoading(false);
      }
    }
    fetchSalas();
  }, [token]);

  function closeModal() {
    setIsModalOpen(false);
    setIsConfirmModalOpen(false);
    setModalValues({
      salaId: 0,
      dataInicio: "",
      dataFimVendas: "",
      dataFim: "",
      valorPalpite: 0,
      statusSala: { id: 0, descricao: "" },
      concursos: [],
      ativo: true,
    });
    setNumeros("");
  }

  function handleViewClick(salaId: number) {
    const selectedSala = salas.find((sala) => sala.salaId === salaId);
    if (selectedSala && selectedSala.concursos.length > 0) {
      navigate("/viewSortie", {
        state: {
          concursoId: selectedSala.concursos[0].concursoId,
          numeroConcurso: selectedSala.concursos[0].numeroConcurso,
        },
      });
    }
  }

  const columns: IColumn<Sala>[] = [
    {
      label: <Text fontWeight="bold">Número Bolão</Text>,
      render: (sala) => <Text>{sala.salaId}</Text>,
      width: 25,
    },
    {
      label: <Text fontWeight="bold">Número do Concurso</Text>,
      render: (sala) => (
        <Text>
          {sala.concursos.length > 0
            ? sala.concursos[0].numeroConcurso
            : "Indisponível"}
        </Text>
      ),
      width: 25,
    },
    {
      label: <Text fontWeight="bold">Data do Concurso</Text>,
      render: (sala) => (
        <>
          <Text>
            {moment(sala.concursos[0].diaConcurso).format("DD-MM-YYYY")}
          </Text>
        </>
      ),
      width: 25,
    },
    {
      label: "Status da Sala",
      render: (sala) => <Badge colorScheme="yellow">Em Andamento</Badge>,
      width: 20,
    },
    {
      label: (
        <Text fontWeight="bold" textAlign="center">
          Ações
        </Text>
      ),
      render: (sala) => (
        <HStack align="center">
          <IconButton
            aria-label="add"
            colorScheme="#16A34A"
            bgColor="#16A34A"
            color="white"
            onClick={() => {
              setModalValues(sala);
              setIsModalOpen(true);
            }}
          >
            <RiPencilLine size={20} />
          </IconButton>
          <IconButton
            colorScheme="#16A34A"
            bgColor="#16A34A"
            color="white"
            aria-label="view-details"
            icon={<IoEyeOutline size={20} />}
            onClick={() => handleViewClick(sala.salaId)}
          />
        </HStack>
      ),
      width: 25,
    },
  ];

  function handleSave() {
    if (modalValues.concursos.length === 0) {
      console.error(
        "Erro: Não há concursos disponíveis para salvar os números."
      );
      return;
    }
    setIsConfirmModalOpen(true);
    setIsModalOpen(false);
  }

  async function confirmSave() {
    if (modalValues.concursos.length === 0) {
      console.error(
        "Erro: Não há concursos disponíveis para salvar os números."
      );
      return;
    }

    const numerosFormatados = numeros.split("-").join(",");
    const data = {
      concursoId: modalValues.concursos[0].concursoId,
      numeros: numerosFormatados,
    };
    try {
      const response = await http.post("/resultado-mega", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      closeModal();
      fetchSalas();
    } catch (error) {
      console.error("Erro ao salvar números:", error);
      closeModal();
    }
  }

  return isLoading ? (
    <Flex flex="1" alignItems="center" justifyContent="center">
      <CircularProgress color="#16A34A" isIndeterminate />
    </Flex>
  ) : (
    <Flex flex="1" flexDirection="row" paddingY={5}>
      <Container
        borderRadius={10}
        bg={"gray.200"}
        maxWidth={["100vw", "60vw"]}
        display="flex"
        flexDirection="column"
        gap={10}
        mt={6}
      >
        <Box>
          <Text
            marginTop={5}
            fontSize={["2xl", "3xl"]}
            textAlign="center"
            fontWeight="bold"
          >
            Sorteio
          </Text>
        </Box>

        <Flex width="full">
          <Table<Sala> columns={columns} rows={salas} />
        </Flex>
      </Container>

      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Digite os Números</ModalHeader>
          <ModalBody>
            <Text mb={4}>Número Bolão: {modalValues.salaId}</Text>
            <Text mb={4}>
              Número do Concurso: {modalValues.concursos[0]?.numeroConcurso}
            </Text>
            <Text mb={4}>
              Data do Concurso: {modalValues.concursos[0]?.diaConcurso}
            </Text>

            <InputMask
              style={{
                border: "1px solid #ccc",
                borderRadius: "4px",
                padding: "8px",
                width: "100%",
              }}
              mask="99-99-99-99-99-99"
              maskChar={null}
              name="numeros do sorteio"
              placeholder="Insira os números"
              value={numeros}
              onChange={(e) => setNumeros(e.target.value)}
            />
          </ModalBody>
          <ModalFooter>
            <Flex justifyContent="space-between" width="100%">
              <Button variant="ghost" onClick={closeModal} bg="#e7e1e1">
                Não
              </Button>
              <Button
                colorScheme="#16A34A"
                bgColor="#16A34A"
                color="white"
                mr={3}
                onClick={handleSave}
              >
                Confirmar
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isConfirmModalOpen} onClose={closeModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirmar Números</ModalHeader>
          <ModalBody>
            <Text mb={4}>
              Números Digitados: <strong>{numeros}</strong>
            </Text>
            <Text mb={4}>
              Data do Concurso: {modalValues.concursos[0]?.diaConcurso}
            </Text>
            <Text mb={4}>
              Número do Concurso: {modalValues.concursos[0]?.numeroConcurso}
            </Text>

            <Text fontWeight="bold" color="#EB3941">
              Confirme se os números digitados estão corretos.
            </Text>
          </ModalBody>
          <ModalFooter>
            <Flex justifyContent="space-between" width="100%">
              <Button variant="ghost" onClick={closeModal} bg="#e7e1e1">
                Não
              </Button>
              <Button
                colorScheme="#16A34A"
                bgColor="#16A34A"
                color="white"
                mr={3}
                onClick={confirmSave}
              >
                Confirmar
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
}
